// src/components/Table/RowSubComponent.tsx
import React from "react";
function SubRows({
  row,
  rowProps,
  visibleColumns,
  data,
  loading
}) {
  if (loading) {
    return /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("td", null), /* @__PURE__ */ React.createElement("td", { colSpan: visibleColumns.length - 1 }, "Loading..."));
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null, data && /* @__PURE__ */ React.createElement(React.Fragment, null, data.map((x, i) => {
    return /* @__PURE__ */ React.createElement(
      "tr",
      {
        ...rowProps,
        key: `${rowProps.key}-expanded-${i}`,
        className: "border-bottom row_sub_component"
      },
      row.cells.map((cell) => {
        return /* @__PURE__ */ React.createElement(
          "td",
          {
            ...cell.getCellProps(),
            className: `px-2 py-3 sub-cell-${cell.column.id} `
          },
          cell.render(cell.column.SubCell ? "SubCell" : "Cell", {
            value: cell.column.accessor && cell.column.accessor(x, i),
            row: { ...row, original: x }
          })
        );
      })
    );
  })));
}
var SubRowAsync = ({
  row,
  rowProps,
  visibleColumns,
  listViewModel,
  idKey
}) => {
  const [loading, setLoading] = React.useState(true);
  const data = React.useRef([]);
  React.useEffect(() => {
    (async function() {
      try {
        if (idKey) {
          data.current = await listViewModel?.getContentByIdExpanded(row.original[idKey]);
        }
        setLoading(false);
      } catch (e) {
      }
    })();
  }, [listViewModel, row, idKey]);
  return /* @__PURE__ */ React.createElement(
    SubRows,
    {
      row,
      rowProps,
      visibleColumns,
      data: data.current,
      loading
    }
  );
};
var RowSubComponent_default = SubRowAsync;

export {
  RowSubComponent_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
