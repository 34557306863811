import {
  RoleDetailViewModel_default
} from "./chunk-ZLZBEOSD.js";
import {
  RoleListViewModel_default
} from "./chunk-Z4RRIVJU.js";

// src/pages/Roles/RoleViewModel/RoleViewModel.ts
var RoleViewModel = class {
  constructor(roleStore) {
    this.roleDetailViewModel = {};
    this.roleListViewModel = {};
    this.getRoleDetailViewModel = () => this.roleDetailViewModel;
    this.getRoleListViewModel = () => this.roleListViewModel;
    if (roleStore) {
      this.roleDetailViewModel = new RoleDetailViewModel_default(roleStore);
      this.roleListViewModel = new RoleListViewModel_default(roleStore);
    }
  }
};
var RoleViewModel_default = RoleViewModel;

export {
  RoleViewModel_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
