// src/constant/PageStatus.ts
var PAGE_STATUS = {
  LOADING: 1,
  READY: 2,
  ERROR: 3
};

export {
  PAGE_STATUS
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
