// src/Abstract/BaseMasterDataModel.ts
var BaseMasterDataModel = class {
  constructor(entities) {
    this.items = [];
    this.unTransformedItems = [];
    this.pureEntities = null;
    this.getPureEntities = () => this.pureEntities;
    this.getItems = () => {
      return this.items;
    };
    this.getUntransformedItems = () => {
      return this.unTransformedItems;
    };
    this.totalItems = () => this.items.length;
    this.toJSON = () => {
      if (!this.items)
        return null;
      return this.items.map((element) => {
        return element ? element.toJSON() : null;
      });
    };
    this.pureEntities = entities;
  }
};
var BaseMasterDataModel_default = BaseMasterDataModel;

export {
  BaseMasterDataModel_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
