// src/Constant/ProjectChannelConstant.ts
var ESI_PROJECT_CHANNEL_FIELD_KEY = {
  ID: "id",
  TITLE: "title",
  HANDLE: "handle",
  PROJECT: "project",
  CHANNEL: "channel",
  FBCONTENT: "content",
  CHANNEL_NAME: "channel_name"
};
var ESI_PROJECT_CHANNEL_API_RESPONSE_FIELD_KEY = {
  ID: "id",
  TITLE: "title",
  HANDLE: "handle",
  PROJECT: "project",
  CHANNEL: "channel",
  FBCONTENT: "content",
  CHANNEL_NAME: "channel_name"
};

export {
  ESI_PROJECT_CHANNEL_FIELD_KEY,
  ESI_PROJECT_CHANNEL_API_RESPONSE_FIELD_KEY
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
