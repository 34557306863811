import {
  PIM_FIELD_DETAIL_FIELD_KEY
} from "./chunk-RS5ET3Q3.js";
import {
  BaseItemModel_default
} from "./chunk-UPAELC5I.js";
import {
  BaseModel_default
} from "./chunk-ITJMNR7M.js";

// src/Pim/PimField/PimFieldModel.ts
var FieldModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    if (entities) {
      this.items = entities._embedded.item.map((element) => {
        return new FieldItemModel(element);
      });
    }
  }
};
var FieldItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.id = null;
    this.name = null;
    this.published = 0;
    this.featured = 0;
    this.custom_fields = null;
    this.created_user_name = null;
    this.created_time = null;
    this.publish_up = null;
    this.field_group_id = null;
    this.field_group_name = null;
    this.type = null;
    this.fieldcode = null;
    this.params = null;
    this.options = null;
    this.relevance = null;
    this.content_types = null;
    this.product_types = null;
    this.unique = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [PIM_FIELD_DETAIL_FIELD_KEY.ID]: this.id,
        [PIM_FIELD_DETAIL_FIELD_KEY.NAME]: this.name,
        [PIM_FIELD_DETAIL_FIELD_KEY.PUBLISHED]: this.published,
        [PIM_FIELD_DETAIL_FIELD_KEY.FEATURED]: this.featured,
        [PIM_FIELD_DETAIL_FIELD_KEY.CUSTOM_FIELDS]: this.custom_fields,
        [PIM_FIELD_DETAIL_FIELD_KEY.CREATED_USER_NAME]: this.created_user_name,
        [PIM_FIELD_DETAIL_FIELD_KEY.PUBLISH_UP]: this.publish_up,
        [PIM_FIELD_DETAIL_FIELD_KEY.FIELD_GROUP_ID]: this.field_group_id,
        [PIM_FIELD_DETAIL_FIELD_KEY.FIELD_GROUP_NAME]: this.field_group_name,
        [PIM_FIELD_DETAIL_FIELD_KEY.TYPE]: this.type,
        [PIM_FIELD_DETAIL_FIELD_KEY.FIELD_CODE]: this.fieldcode,
        [PIM_FIELD_DETAIL_FIELD_KEY.PARAMS]: this.params,
        [PIM_FIELD_DETAIL_FIELD_KEY.OPTIONS]: this.options,
        [PIM_FIELD_DETAIL_FIELD_KEY.RELEVANCE]: this.relevance,
        [PIM_FIELD_DETAIL_FIELD_KEY.SECTION]: this.content_types,
        [PIM_FIELD_DETAIL_FIELD_KEY.PRODUCT_TYPES]: this.product_types,
        [PIM_FIELD_DETAIL_FIELD_KEY.UNIQUE]: this.unique
      };
    };
    if (entity) {
      this.id = entity[PIM_FIELD_DETAIL_FIELD_KEY.ID] ?? "";
      this.name = entity[PIM_FIELD_DETAIL_FIELD_KEY.NAME] ?? "";
      this.published = entity[PIM_FIELD_DETAIL_FIELD_KEY.PUBLISHED] ?? 0;
      this.featured = entity[PIM_FIELD_DETAIL_FIELD_KEY.FEATURED]?.toString() ?? "0";
      this.custom_fields = entity[PIM_FIELD_DETAIL_FIELD_KEY.CUSTOM_FIELDS] ?? "";
      this.created_user_name = entity[PIM_FIELD_DETAIL_FIELD_KEY.CREATED_USER_NAME] ?? "";
      this.created_time = entity[PIM_FIELD_DETAIL_FIELD_KEY.CREATED_TIME] ?? "";
      this.publish_up = entity[PIM_FIELD_DETAIL_FIELD_KEY.PUBLISH_UP] ?? "";
      this.field_group_id = entity[PIM_FIELD_DETAIL_FIELD_KEY.FIELD_GROUP_ID] ?? "";
      this.field_group_name = entity[PIM_FIELD_DETAIL_FIELD_KEY.FIELD_GROUP_NAME] ?? "";
      this.type = entity[PIM_FIELD_DETAIL_FIELD_KEY.TYPE] ?? "";
      this.fieldcode = entity[PIM_FIELD_DETAIL_FIELD_KEY.FIELD_CODE] ?? "";
      this.params = entity[PIM_FIELD_DETAIL_FIELD_KEY.PARAMS][0] ? JSON.parse(entity[PIM_FIELD_DETAIL_FIELD_KEY.PARAMS]) : {};
      this.options = entity[PIM_FIELD_DETAIL_FIELD_KEY.OPTIONS] ?? "";
      this.relevance = entity[PIM_FIELD_DETAIL_FIELD_KEY.RELEVANCE] ?? "";
      this.content_types = entity[PIM_FIELD_DETAIL_FIELD_KEY.SECTION] ?? "";
      this.product_types = entity[PIM_FIELD_DETAIL_FIELD_KEY.PRODUCT_TYPES] ?? "";
      this.unique = entity[PIM_FIELD_DETAIL_FIELD_KEY.UNIQUE] ?? "";
    }
  }
};
FieldItemModel.__transformItemToApiOfCreation = (data) => {
  let formData = new FormData();
  const excluded = [
    PIM_FIELD_DETAIL_FIELD_KEY.ID,
    PIM_FIELD_DETAIL_FIELD_KEY.PARAMS,
    PIM_FIELD_DETAIL_FIELD_KEY.PRODUCT_TYPES
  ];
  Object.keys(PIM_FIELD_DETAIL_FIELD_KEY).forEach((index) => {
    if (!excluded.includes(PIM_FIELD_DETAIL_FIELD_KEY[index]) && data[PIM_FIELD_DETAIL_FIELD_KEY[index]]) {
      if (Array.isArray(data[PIM_FIELD_DETAIL_FIELD_KEY[index]])) {
        data[PIM_FIELD_DETAIL_FIELD_KEY[index]].map((item, itemKey) => {
          if (typeof item === "object" && !Array.isArray(item) && item !== null) {
            Object.keys(item).map((key) => {
              formData.append(
                PIM_FIELD_DETAIL_FIELD_KEY[index] + "[" + itemKey + "][" + key + "]",
                item[key]
              );
            });
          } else {
            formData.append(PIM_FIELD_DETAIL_FIELD_KEY[index] + "[]", item);
          }
        });
      } else {
        formData.append(
          PIM_FIELD_DETAIL_FIELD_KEY[index],
          data[PIM_FIELD_DETAIL_FIELD_KEY[index]]
        );
      }
    }
  });
  if (data[PIM_FIELD_DETAIL_FIELD_KEY.PARAMS]) {
    Object.keys(data[PIM_FIELD_DETAIL_FIELD_KEY.PARAMS]).map((key) => {
      return formData.append(
        [PIM_FIELD_DETAIL_FIELD_KEY.PARAMS] + "[" + key + "]",
        data[PIM_FIELD_DETAIL_FIELD_KEY.PARAMS][key]
      );
    });
  }
  if (data[PIM_FIELD_DETAIL_FIELD_KEY.PRODUCT_TYPES] && data[PIM_FIELD_DETAIL_FIELD_KEY.PRODUCT_TYPES].length) {
    data[PIM_FIELD_DETAIL_FIELD_KEY.PRODUCT_TYPES].map((item) => {
      return formData.append(PIM_FIELD_DETAIL_FIELD_KEY.PRODUCT_TYPES + "[]", item?.id);
    });
  }
  return formData;
};
FieldItemModel.__transformItemToApiOfUpdation = (data) => {
  let formData = {};
  const excluded = [
    PIM_FIELD_DETAIL_FIELD_KEY.PRODUCT_TYPES,
    PIM_FIELD_DETAIL_FIELD_KEY.FIELD_GROUP_ID
  ];
  Object.keys(PIM_FIELD_DETAIL_FIELD_KEY).forEach((index) => {
    if (!excluded.includes(PIM_FIELD_DETAIL_FIELD_KEY[index]) && data[PIM_FIELD_DETAIL_FIELD_KEY[index]]) {
      formData[PIM_FIELD_DETAIL_FIELD_KEY[index]] = data[PIM_FIELD_DETAIL_FIELD_KEY[index]];
    }
  });
  if (data[PIM_FIELD_DETAIL_FIELD_KEY.PRODUCT_TYPES]) {
    formData[PIM_FIELD_DETAIL_FIELD_KEY.PRODUCT_TYPES] = data[PIM_FIELD_DETAIL_FIELD_KEY.PRODUCT_TYPES]?.length ? data[PIM_FIELD_DETAIL_FIELD_KEY.PRODUCT_TYPES]?.map((item) => {
      return item.id;
    }) : [""];
  }
  if (data[PIM_FIELD_DETAIL_FIELD_KEY.FIELD_GROUP_ID]) {
    formData[PIM_FIELD_DETAIL_FIELD_KEY.FIELD_GROUP_ID] = data[PIM_FIELD_DETAIL_FIELD_KEY.FIELD_GROUP_ID];
  } else {
    formData[PIM_FIELD_DETAIL_FIELD_KEY.FIELD_GROUP_ID] = "";
  }
  return formData;
};

export {
  FieldModel,
  FieldItemModel
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
