// src/components/Thumb/index.tsx
import { toJS } from "mobx";
import React from "react";

// src/components/Thumb/index.scss
var css = `.text-description {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}`;
document.head.appendChild(document.createElement("style")).appendChild(document.createTextNode(css));

// src/components/Thumb/index.tsx
import { withTranslation, useTranslation } from "react-i18next";
var Thumb = ({ data }) => {
  const { t } = useTranslation();
  return /* @__PURE__ */ React.createElement("section", { className: "px-3 pt-3" }, /* @__PURE__ */ React.createElement("div", { className: "row" }, toJS(data).map((item) => {
    let status = "";
    if (item.status === 1) {
      status = t("txt_running");
    } else if (item.status === 2) {
      status = t("txt_schedule");
    } else {
      status = t("txt_stop");
    }
    return /* @__PURE__ */ React.createElement("div", { className: "col-md-3 mb-4 ", key: item.id }, /* @__PURE__ */ React.createElement("div", { className: "bg-white rounded-3 p-3 overflow-y-hidden h-100" }, /* @__PURE__ */ React.createElement("p", null, item.logo), /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement(
      "span",
      {
        className: `px-2 fs-14 rounded-1 d-inline-block ${status === t("txt_running") ? "badge bg-posted mw-50 h-20 d-inline align-middle" : status === t("txt_schedule") ? "badge bg-processing mw-50 h-20 d-inline align-middle" : "badge bg-failed mw-50 h-20 d-inline align-middle"}`
      },
      status
    )), /* @__PURE__ */ React.createElement("h3", { className: " text-name fs-5 fw-bold" }, item.name.props.children), /* @__PURE__ */ React.createElement("p", { className: "text-description pt-19px" }, item.shortDescription)));
  })));
};
var ThumbWithTranslation = withTranslation()(Thumb);

export {
  ThumbWithTranslation
};
