// src/providers/I18nextProvider.tsx
import React, { createContext, useContext } from "react";
import { I18nextProvider, initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// src/translations/dk/common.json
var common_default = {
  txt_welcome_to: "Velkommen til ",
  txt_email: "E-mail",
  txt_sign_in: "Log ind",
  txt_sign_in_with_sso: "Log ind med SSO",
  txt_sign_up: "Log ud",
  txt_login_text_1: "Velkommen til",
  txt_login_text_2: "Log ind for at komme i gang.",
  txt_do_not_have_an_account: "Har du ikke en konto? Opret en gratis konto",
  txt_username: "Brugernavn",
  txt_search_for_something: "S\xF8g efter ...",
  txt_search_all_content: "S\xF8g i alt indhold",
  txt_help_center: "hj\xE6lp",
  txt_sign_out: "Log ud",
  txt_notifications: "Notifikationer",
  txt_client: "Klient",
  txt_more: "Mere",
  txt_menu_digital_assets: "Digital Assets",
  txt_today: "I dag",
  txt_general_information: "Generel Information",
  txt_projectpage_password: "Adgangskode",
  txt_profile_picture: "Profilbilled",
  txt_back_to_dashboard: "Tilbage til Dashboard",
  txt_menu_profile: "Profil",
  txt_Status: "Status",
  txt_Birthday: "F\xF8dselsdato",
  txt_phone: "Telefon nummer",
  txt_Address_1: "Adresse 1",
  txt_Address_2: "Adresse 2",
  txt_State: "Kommune",
  txt_Country: "Land",
  txt_Countries: "Lande",
  txt_Zipcode: "Postnummer",
  txt_fullname: "Fuldt navn",
  txt_City: "By",
  txt_current_password: "Nuv\xE6rende adgangskode",
  txt_confirm_password: "Bekr\xE6ft nuv\xE6rende adgangskode",
  txt_new_password: "Ny adgangskode",
  txt_update: "Opdater",
  txt_delete: "Slet",
  txt_choose_an_action: "V\xE6lg en handling",
  txt_list: "Lister",
  txt_thumb: "Miniature",
  txt_show_more: "Vis mere",
  txt_start_date: "Startsdato",
  txt_end_date: "Slutdato",
  txt_created_date: "Oprettelsesdato",
  txt_short_description: "Kort beskrivelse",
  txt_short_description_about_project: "Kort beskrivelse om projektet",
  txt_budget: "Budget",
  txt_status: "Status",
  txt_name_personas: "Navn",
  txt_updated_date: "Opdateret dato",
  txt_title: "Titel",
  txt_channels: "Kanaler",
  txt_date: "Dato",
  txt_edit: "Rediger",
  txt_general: "Generelt",
  txt_save: "Gem",
  txt_back: "Tilbage",
  txt_next: "N\xE6ste",
  txt_connecting: "Tilknytter",
  txt_connect: "Tilknyt",
  txt_enable: "Aktiver",
  txt_remove: "Fjern",
  txt_type: "Type",
  txt_action: "Handling",
  txt_profile: "Profil",
  txt_apply: "Anvend",
  txt_video: "Video",
  txt_menu_dashboard: "Dashboard",
  txt_warning: "Advarsel",
  txt_successful: "Vellykket",
  txt_select: "V\xE6lg...",
  txt_error: "Fejl",
  txt_title_profile_setting: "Profilindstillinger",
  txt_title_set_information_name: "Inds\xE6t dit navn og andre offentligt tilg\xE6ngelig information",
  txt_main_menu: "Hovedmenu",
  txt_member_name: "Medlemsnavn",
  txt_member_email: "Medlems e-mail",
  txt_member_role: "Medlemsrolle",
  txt_organisation: "Organisation",
  txt_member: "Medlem",
  txt_add_member_name: "Tilf\xF8j medlemsnavn",
  txt_password: "Adgangskode",
  txt_enter_password: "Indtast adgangskode",
  txt_enter_member_email: "Indtast medlems e-mail",
  txt_select_role: "V\xE6lg rolle",
  txt_enter_metamask_address: "Indtast metamaskadresse",
  txt_enter_concordium_address: "Indtast Concordium-adresse",
  txt_role: "Rolle",
  txt_metamask_wallet: "Metamask Wallet",
  txt_concordium_wallet: "Concordium tegnebog",
  txt_version: "Version:",
  txt_sign_in_to_getting_started: "Log ind for at komme i gang.",
  txt_first_name: "Fornavn",
  txt_last_name: "Efternavn",
  txt_organization: "Organisation",
  txt_running: "K\xF8rer",
  txt_schedule: "Planl\xE6g",
  txt_stop: "Stop"
};

// src/translations/en/common.json
var common_default2 = {
  txt_welcome_to: "Welcome to ",
  txt_email: "Email",
  txt_sign_in: "Sign In",
  txt_sign_in_with_sso: "Sign In with SSO",
  txt_sign_up: "Sign Up",
  txt_login_text_1: "Welcome to",
  txt_login_text_2: "Sign In to get started",
  txt_do_not_have_an_account: "Don't have an account? Create Free Account",
  txt_username: "Username",
  txt_search_for_something: "Search for something ...",
  txt_search_all_content: "Search all content",
  txt_help_center: "help center",
  txt_sign_out: "Sign Out",
  txt_notifications: "Notifications",
  txt_client: "Client",
  txt_more: "More",
  txt_menu_digital_assets: "Digital Assets",
  txt_today: "Today",
  txt_general_information: "General Information",
  txt_projectpage_password: "Password",
  txt_profile_picture: "Profile picture",
  txt_back_to_dashboard: "Back to Dashboard",
  txt_menu_profile: "Profile",
  txt_Status: "Status",
  txt_Birthday: "Birthday",
  txt_phone: "Phone",
  txt_Address_1: "Address 1",
  txt_Address_2: "Address 2",
  txt_State: "State",
  txt_Country: "Country",
  txt_Countries: "Countries",
  txt_Zipcode: "Zipcode",
  txt_fullname: "Fullname",
  txt_City: "City",
  txt_current_password: "Current Password",
  txt_confirm_password: "Confirm New Password",
  txt_new_password: "New Password",
  txt_update: "Update",
  txt_delete: "Delete",
  txt_choose_an_action: "Choose an action",
  txt_list: "List",
  txt_thumb: "Thumb",
  txt_show_more: "Show more",
  txt_start_date: "Start Date",
  txt_end_date: "End Date",
  txt_created_date: "Created Date",
  txt_short_description: "Short Description",
  txt_short_description_about_project: "Short description about project",
  txt_budget: "Budget",
  txt_status: "Status",
  txt_name_personas: "Name",
  txt_updated_date: "Updated Date",
  txt_title: "Title",
  txt_channels: "Channels",
  txt_date: "Date",
  txt_edit: "Edit",
  txt_general: "General",
  txt_save: "Save",
  txt_back: "Back",
  txt_next: "Next",
  txt_connecting: "Connecting",
  txt_connect: "Connect",
  txt_enable: "Enable",
  txt_remove: "Remove",
  txt_type: "Type",
  txt_action: "Action",
  txt_profile: "Profile",
  txt_apply: "Apply",
  txt_video: "Video",
  txt_menu_dashboard: "Dashboard",
  txt_warning: "Warning",
  txt_successful: "Successful",
  txt_select: "Select...",
  txt_error: "Error",
  txt_title_profile_setting: "Profile Setting",
  txt_title_set_information_name: "Set your name and other public-facing information",
  txt_main_menu: "Main Menu",
  txt_member_name: "Member Name",
  txt_member_email: "Member Email",
  txt_member_role: "Member Role",
  txt_organisation: "Organisation",
  txt_member: "Member",
  txt_add_member_name: "Add member name",
  txt_password: "Password",
  txt_enter_password: "Enter Password",
  txt_enter_member_email: "Enter Member Email",
  txt_select_role: "Select Role",
  txt_enter_metamask_address: "Enter Metamask Address",
  txt_enter_concordium_address: "Enter Concordium Address",
  txt_role: "Role",
  txt_metamask_wallet: "Metamask Wallet",
  txt_concordium_wallet: "Concordium Wallet",
  txt_version: "Version:",
  txt_sign_in_to_getting_started: "Sign In to get started",
  txt_first_name: "First Name",
  txt_last_name: "Last Name",
  txt_organization: "Organization",
  txt_menu_setting: "Setting",
  txt_running: "Running",
  txt_schedule: "Schedule",
  txt_stop: "Stop",
  txt_left_menu_members: "Members",
  txt_members: "Members",
  txt_roles: "Roles",
  txt_left_menu_roles: "Roles",
  txt_all_role: "All Roles",
  txt_role_name: "Role Name",
  txt_add_role_name: "Add Role Name",
  txt_all_member_role: "All Member Role",
  txt_member_role_name: "Member Role Name",
  txt_add_member_role_name: "Add Member Role Name",
  txt_left_menu_member_role: "Member Role",
  txt_left_menu_permissions: "Permissions",
  txt_all_permission: "All Permissions"
};

// src/translations/es/common.json
var common_default3 = {
  txt_welcome_to: "Bienvenido a ",
  txt_email: "correo electr\xF3nico",
  txt_sign_in: "Registrarse",
  txt_sign_in_with_sso: "Regitrarse con SSO",
  txt_sign_up: "Inscribirse",
  txt_login_text_1: "Bienvenido a",
  txt_login_text_2: "Inicie sesi\xF3n para comenzar.",
  txt_do_not_have_an_account: "\xBFNo tiene una cuenta? Crea una cuenta gratis",
  txt_username: "Nombbre de usuario",
  txt_search_for_something: "busca algo...",
  txt_search_all_content: "Buscar todo el contenido",
  txt_help_center: "centro de ayuda",
  txt_sign_out: "Desconectar",
  txt_notifications: "Notificaciones",
  txt_client: "Cliente",
  txt_more: "M\xE1s",
  txt_menu_digital_assets: "Recursos digitales",
  txt_today: "Hoy",
  txt_general_information: "Informaci\xF3n general",
  txt_projectpage_password: "contrase\xF1a",
  txt_profile_picture: "Foto de perfil",
  txt_back_to_dashboard: "Volver al panel",
  txt_menu_profile: "Perfil",
  txt_Status: "Estado",
  txt_Birthday: "Cumplea\xF1os",
  txt_phone: "Tel\xE9fono",
  txt_Address_1: "Direcci\xF3n 1",
  txt_Address_2: "Direcci\xF3n 2",
  txt_State: "Estado",
  txt_Country: "Pa\xEDs",
  txt_Countries: "Pa\xEDses",
  txt_Zipcode: "C\xF3digo postal",
  txt_fullname: "Nombre completo",
  txt_City: "Ciudad",
  txt_current_password: "contrase\xF1a actual",
  txt_confirm_password: "Confirmar nueva contrase\xF1a",
  txt_new_password: "nueva contrase\xF1a",
  txt_update: "Actualizar",
  txt_delete: "Borrar",
  txt_choose_an_action: "Elige una acci\xF3n",
  txt_list: "lista",
  txt_thumb: "Miniatura",
  txt_show_more: "Mostrar m\xE1s",
  start_date: "Fecha inicial",
  txt_end_date: "Fecha final",
  txt_created_date: "Fecha de creaci\xF3n",
  txt_short_description: "Descripci\xF3n Breve",
  txt_short_description_about_project: "Breve descripci\xF3n sobre el proyecto.",
  txt_budget: "Presupuesto",
  txt_status: "Estado",
  txt_name_personas: "Nombre",
  txt_updated_date: "Fecha actualizada",
  txt_title: "T\xEDtulo",
  txt_channels: "Canales",
  txt_date: "Fecha",
  txt_edit: "Editar",
  txt_general: "General",
  txt_save: "Gaurdar",
  txt_back: "Regresar",
  txt_next: "Siguiente",
  txt_connecting: "Conectando",
  txt_connect: "Conectar",
  txt_enable: "Habilitar",
  txt_remove: "Eliminar",
  txt_type: "Tipo",
  txt_action: "Acci\xF3n",
  txt_profile: "Perfil",
  txt_apply: "Aplicar",
  txt_video: "Video",
  txt_select: "Seleccionar...",
  txt_menu_dashboard: "Tablero",
  txt_title_profile_setting: "Ajustes de perfil",
  txt_title_set_information_name: "Establezca su nombre y otra informaci\xF3n p\xFAblica",
  txt_main_menu: "Men\xFA principal",
  txt_member_name: "Nombre del miembro",
  txt_member_email: "Correo electr\xF3nico del miembro",
  txt_member_role: "Rol del miembro",
  txt_organisation: "Organizaci\xF3n",
  txt_member: "Miembro",
  txt_add_member_name: "Agregar nombre de miembro",
  txt_password: "Contrase\xF1a",
  txt_enter_password: "Ingresar contrase\xF1a",
  txt_enter_member_email: "Ingrese el correo electr\xF3nico del miembro",
  txt_select_role: "Seleccionar Rol",
  txt_enter_metamask_address: "Ingrese la direcci\xF3n de la metam\xE1scara",
  txt_enter_concordium_address: "Ingrese la direcci\xF3n del concordio",
  txt_role: "Rol",
  txt_metamask_wallet: "Monedero Metamask",
  txt_concordium_wallet: "Cartera Concordio",
  txt_version: "Versi\xF3n:",
  txt_sign_in_to_getting_started: "Inicie sesi\xF3n para comenzar.",
  txt_first_name: "Nombre",
  txt_last_name: "Apellido",
  txt_organization: "Organizaci\xF3n",
  txt_running: "Corriendo",
  txt_schedule: "Programar",
  txt_stop: "Detener"
};

// src/translations/hr/common.json
var common_default4 = {
  txt_welcome_to: "Dobrodo\u0161li ",
  txt_email: "Email",
  txt_sign_in: "Prijavi se",
  txt_sign_in_with_sso: "Prijavi se sa SSO",
  txt_sign_up: "Registriraj se",
  txt_login_text_1: "Dobrodo\u0161li",
  txt_login_text_2: "Prijavite se da bi ste zapo\u010Deli.",
  txt_do_not_have_an_account: "Jo\u0161 nema\u0161 ra\u010Dun? Napravi besplatni ra\u010Dun",
  txt_username: "Korisni\u010Dlo ime",
  txt_search_for_something: "Pretra\u017Ei ...",
  txt_search_all_content: "Pretra\u017Ei sav sadr\u017Eaj",
  txt_help_center: "Centar za pomo\u0107",
  txt_sign_out: "Odjavi se",
  txt_notifications: "Obavijesti",
  txt_client: "Klijent",
  txt_more: "Vi\u0161e",
  txt_menu_digital_assets: "Digitalni materijali",
  txt_today: "Danas",
  txt_general_information: "Op\u0107e informacije",
  txt_projectpage_password: "Zaporka",
  txt_profile_picture: "Slika profila",
  txt_back_to_dashboard: "Natrag na nadzornu plo\u010Du",
  txt_menu_profile: "Profil",
  txt_Status: "Status",
  txt_Birthday: "Datum ro\u0111enja",
  txt_phone: "Telefon",
  txt_Address_1: "Addresa 1",
  txt_Address_2: "Addresa 2",
  txt_State: "Pokrajna",
  txt_Country: "Dr\u017Eava",
  txt_Countries: "Zemlje",
  txt_Zipcode: "Po\u0161tanski broj",
  txt_fullname: "Puno ime",
  txt_City: "Grad",
  txt_current_password: "Trenutna zaporka",
  txt_confirm_password: "Potvrdi novu zaporku",
  txt_new_password: "Nova zaporka",
  txt_update: "A\u017Euriraj",
  txt_delete: "Izbri\u0161i",
  txt_choose_an_action: "Izaberi akciju",
  txt_list: "Lista",
  txt_thumb: "Palac",
  txt_show_more: "Prika\u017Ei vi\u0161e",
  txt_start_date: "Po\u010Detni datum",
  txt_end_date: "Zavr\u0161ni datum",
  txt_created_date: "Datum kreiranja",
  txt_short_description: "Kratki opis",
  txt_short_description_about_project: "Kratki opis o projektu",
  txt_budget: "Prora\u010Dun",
  txt_status: "Status",
  txt_name_personas: "Ime",
  txt_updated_date: "Datum a\u017Euriraja",
  txt_title: "Naslov",
  txt_channels: "Kanali",
  txt_date: "Datum",
  txt_edit: "Uredi",
  txt_general: "Op\u0107e",
  txt_save: "Spremi",
  txt_back: "Natrag",
  txt_next: "Sljede\u0107e",
  txt_connecting: "Spajanje",
  txt_connect: "Spojen",
  txt_enable: "Omogu\u0107i",
  txt_remove: "Ukloni",
  txt_type: "Vrsta",
  txt_action: "Akcija",
  txt_profile: "Profil",
  txt_apply: "Primjeni",
  txt_video: "Video",
  txt_select: "Izaberi...",
  txt_menu_dashboard: "Nadzorna plo\u010Da",
  txt_title_profile_setting: "Postavke profila",
  txt_title_set_information_name: "Postavite svoje ime i druge podatke za javnost",
  txt_main_menu: "Glavni izbornik",
  txt_member_name: "Ime \u010Dlana",
  txt_member_email: "E-po\u0161ta \u010Dlana",
  txt_member_role: "Uloga \u010Dlana",
  txt_organisation: "Organizacija",
  txt_member: "\u010Clan",
  txt_add_member_name: "Dodaj ime \u010Dlana",
  txt_password: "Lozinka",
  txt_enter_password: "Unesite lozinku",
  txt_enter_member_email: "Unesite e-po\u0161tu \u010Dlana",
  txt_select_role: "Odaberite ulogu",
  txt_enter_metamask_address: "Unesite adresu metamaska",
  txt_enter_concordium_address: "Unesite adresu Concordiuma",
  txt_role: "Uloga",
  txt_metamask_wallet: "Metamask nov\u010Danik",
  txt_concordium_wallet: "Concordium nov\u010Danik",
  txt_version: "Verzija:",
  txt_sign_in_to_getting_started: "Prijavite se da bi ste zapo\u010Deli.",
  txt_first_name: "Ime",
  txt_last_name: "Prezime",
  txt_organization: "Organizacija",
  txt_running: "Pokretanje",
  txt_schedule: "Raspored",
  txt_stop: "Zaustavi"
};

// src/translations/th/common.json
var common_default5 = {
  txt_welcome_to: "\u0E22\u0E34\u0E19\u0E14\u0E35\u0E15\u0E49\u0E2D\u0E19\u0E23\u0E31\u0E1A\u0E2A\u0E39\u0E48 ",
  txt_email: "\u0E2D\u0E35\u0E40\u0E21\u0E25",
  txt_sign_in: "\u0E40\u0E02\u0E49\u0E32\u0E23\u0E30\u0E1A\u0E1A",
  txt_sign_in_with_sso: "\u0E40\u0E02\u0E49\u0E32\u0E23\u0E30\u0E1A\u0E1A\u0E14\u0E49\u0E27\u0E22 SSO",
  txt_sign_up: "\u0E25\u0E07\u0E17\u0E30\u0E40\u0E1A\u0E35\u0E22\u0E19",
  txt_login_text_1: "\u0E22\u0E34\u0E19\u0E14\u0E35\u0E15\u0E49\u0E2D\u0E07\u0E23\u0E31\u0E1A\u0E2A\u0E39\u0E48",
  txt_login_text_2: "\u0E25\u0E07\u0E0A\u0E37\u0E48\u0E2D\u0E40\u0E02\u0E49\u0E32\u0E43\u0E0A\u0E49\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19",
  txt_do_not_have_an_account: "\u0E22\u0E31\u0E07\u0E44\u0E21\u0E48\u0E21\u0E35\u0E1C\u0E39\u0E49\u0E43\u0E0A\u0E49? \u0E2A\u0E23\u0E49\u0E32\u0E07\u0E1C\u0E39\u0E49\u0E43\u0E0A\u0E49\u0E44\u0E14\u0E49\u0E1F\u0E23\u0E35",
  txt_username: "\u0E0A\u0E37\u0E48\u0E2D\u0E1C\u0E39\u0E49\u0E43\u0E0A\u0E49",
  txt_search_for_something: "\u0E04\u0E49\u0E19\u0E2B\u0E32\u0E1A\u0E32\u0E07\u0E2D\u0E22\u0E48\u0E32\u0E07 ...",
  txt_search_all_content: "\u0E04\u0E49\u0E19\u0E2B\u0E32\u0E43\u0E19\u0E40\u0E19\u0E37\u0E49\u0E2D\u0E2B\u0E32\u0E17\u0E31\u0E49\u0E07\u0E2B\u0E21\u0E14",
  txt_help_center: "\u0E28\u0E39\u0E19\u0E22\u0E4C\u0E43\u0E2B\u0E49\u0E04\u0E27\u0E32\u0E21\u0E0A\u0E48\u0E27\u0E22\u0E40\u0E2B\u0E25\u0E37\u0E2D",
  txt_sign_out: "\u0E2D\u0E2D\u0E01\u0E08\u0E32\u0E01\u0E23\u0E30\u0E1A\u0E1A",
  txt_notifications: "\u0E01\u0E32\u0E23\u0E41\u0E08\u0E49\u0E07\u0E40\u0E15\u0E37\u0E2D\u0E19",
  txt_client: "\u0E25\u0E39\u0E01\u0E04\u0E49\u0E32",
  txt_more: "\u0E40\u0E1E\u0E34\u0E48\u0E21\u0E40\u0E15\u0E34\u0E21",
  txt_menu_digital_assets: "\u0E2A\u0E34\u0E19\u0E17\u0E23\u0E31\u0E1E\u0E22\u0E4C\u0E14\u0E34\u0E08\u0E34\u0E17\u0E31\u0E25",
  txt_today: "\u0E27\u0E31\u0E19\u0E19\u0E35\u0E49",
  txt_general_information: "\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E17\u0E31\u0E48\u0E27\u0E44\u0E1B",
  txt_projectpage_password: "\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19",
  txt_profile_picture: "\u0E23\u0E39\u0E1B\u0E42\u0E1B\u0E23\u0E44\u0E1F\u0E25\u0E4C",
  txt_back_to_dashboard: "\u0E01\u0E25\u0E31\u0E1A\u0E44\u0E1B\u0E22\u0E31\u0E07\u0E41\u0E1C\u0E07\u0E04\u0E27\u0E1A\u0E04\u0E38\u0E21",
  txt_menu_profile: "\u0E42\u0E1B\u0E23\u0E44\u0E1F\u0E25\u0E4C",
  txt_Status: "\u0E2A\u0E16\u0E32\u0E19\u0E30",
  txt_Birthday: "\u0E27\u0E31\u0E19\u0E40\u0E01\u0E34\u0E14",
  txt_phone: "\u0E40\u0E1A\u0E2D\u0E23\u0E4C\u0E42\u0E17\u0E23",
  txt_Address_1: "\u0E17\u0E35\u0E48\u0E2D\u0E22\u0E39\u0E48 1",
  txt_Address_2: "\u0E17\u0E35\u0E48\u0E2D\u0E22\u0E39\u0E48 2",
  txt_State: "\u0E08\u0E31\u0E07\u0E2B\u0E27\u0E31\u0E14",
  txt_Country: "\u0E1B\u0E23\u0E30\u0E40\u0E17\u0E28",
  txt_Countries: "\u0E1B\u0E23\u0E30\u0E40\u0E17\u0E28",
  txt_Zipcode: "\u0E23\u0E2B\u0E31\u0E2A\u0E44\u0E1B\u0E23\u0E29\u0E13\u0E35\u0E22\u0E4C",
  txt_fullname: "\u0E0A\u0E37\u0E48\u0E2D\u0E40\u0E15\u0E47\u0E21",
  txt_City: "\u0E40\u0E02\u0E15/\u0E2D\u0E33\u0E40\u0E20\u0E2D",
  txt_current_password: "\u0E23\u0E2B\u0E31\u0E2A\u0E1B\u0E31\u0E08\u0E08\u0E38\u0E1A\u0E31\u0E19",
  txt_confirm_password: "\u0E22\u0E37\u0E19\u0E22\u0E31\u0E19 \u0E23\u0E2B\u0E31\u0E2A\u0E43\u0E2B\u0E21\u0E48",
  txt_new_password: "\u0E23\u0E2B\u0E31\u0E2A\u0E43\u0E2B\u0E21\u0E48",
  txt_update: "\u0E2D\u0E31\u0E1B\u0E40\u0E14\u0E15",
  txt_delete: "\u0E25\u0E07\u0E17\u0E30\u0E40\u0E1A\u0E35\u0E22\u0E19",
  txt_choose_an_action: "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E01\u0E32\u0E23\u0E14\u0E33\u0E40\u0E19\u0E34\u0E19\u0E01\u0E32\u0E23",
  txt_list: "\u0E23\u0E32\u0E22\u0E01\u0E32\u0E23",
  txt_thumb: "Thumb",
  txt_show_more: "\u0E41\u0E2A\u0E14\u0E07\u0E40\u0E1E\u0E34\u0E48\u0E21",
  txt_start_date: "\u0E27\u0E31\u0E19\u0E17\u0E35\u0E48\u0E40\u0E23\u0E34\u0E48\u0E21",
  txt_end_date: "\u0E27\u0E31\u0E19\u0E17\u0E35\u0E48\u0E2A\u0E34\u0E49\u0E19\u0E2A\u0E38\u0E14",
  txt_created_date: "\u0E27\u0E31\u0E19\u0E17\u0E35\u0E48\u0E2A\u0E23\u0E49\u0E32\u0E07",
  txt_short_description: "\u0E04\u0E33\u0E2D\u0E18\u0E34\u0E1A\u0E32\u0E22\u0E2D\u0E22\u0E48\u0E32\u0E07\u0E22\u0E48\u0E2D",
  txt_short_description_about_project: "\u0E04\u0E33\u0E2D\u0E18\u0E34\u0E1A\u0E32\u0E22\u0E2D\u0E22\u0E48\u0E32\u0E07\u0E22\u0E48\u0E2D\u0E40\u0E01\u0E35\u0E48\u0E22\u0E27\u0E01\u0E31\u0E1A\u0E42\u0E1B\u0E23\u0E40\u0E08\u0E04",
  txt_budget: "\u0E07\u0E1A\u0E1B\u0E23\u0E30\u0E21\u0E32\u0E13",
  txt_status: "\u0E2A\u0E16\u0E32\u0E19\u0E30",
  txt_name_personas: "\u0E0A\u0E37\u0E48\u0E2D",
  txt_updated_date: "\u0E27\u0E31\u0E19\u0E17\u0E35\u0E48\u0E1B\u0E23\u0E31\u0E1A\u0E1B\u0E23\u0E38\u0E07",
  txt_title: "\u0E0A\u0E37\u0E48\u0E2D\u0E40\u0E23\u0E37\u0E48\u0E2D\u0E07",
  txt_channels: "\u0E0A\u0E48\u0E2D\u0E07\u0E17\u0E32\u0E07",
  txt_date: "\u0E27\u0E31\u0E19\u0E17\u0E35\u0E48",
  txt_edit: "\u0E41\u0E01\u0E49\u0E44\u0E02",
  txt_general: "\u0E17\u0E31\u0E48\u0E27\u0E44\u0E1B",
  txt_save: "\u0E1A\u0E31\u0E19\u0E17\u0E36\u0E01",
  txt_back: "\u0E01\u0E25\u0E31\u0E1A",
  txt_next: "\u0E15\u0E48\u0E2D\u0E44\u0E1B",
  txt_connecting: "\u0E01\u0E33\u0E25\u0E31\u0E07\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21\u0E15\u0E48\u0E2D",
  txt_connect: "\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21",
  txt_enable: "\u0E40\u0E1B\u0E34\u0E14\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19",
  txt_remove: "\u0E40\u0E2D\u0E32\u0E2D\u0E2D\u0E01",
  txt_type: "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17",
  txt_action: "\u0E14\u0E33\u0E40\u0E19\u0E34\u0E19\u0E01\u0E32\u0E23",
  txt_profile: "\u0E42\u0E1B\u0E23\u0E44\u0E1F\u0E25\u0E4C",
  txt_apply: "\u0E19\u0E33\u0E44\u0E1B\u0E43\u0E0A\u0E49",
  txt_video: "\u0E27\u0E35\u0E14\u0E35\u0E42\u0E2D",
  txt_menu_dashboard: "\u0E41\u0E1C\u0E07\u0E04\u0E27\u0E1A\u0E04\u0E38\u0E21",
  txt_warning: "\u0E04\u0E33\u0E40\u0E15\u0E37\u0E2D\u0E19",
  txt_successful: "\u0E1B\u0E23\u0E30\u0E2A\u0E1A\u0E04\u0E27\u0E32\u0E21\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08",
  txt_select: "\u0E40\u0E25\u0E37\u0E2D\u0E01...",
  txt_error: "\u0E02\u0E49\u0E2D\u0E1C\u0E34\u0E14\u0E1E\u0E25\u0E32\u0E14",
  txt_title_profile_setting: "\u0E15\u0E31\u0E49\u0E07\u0E04\u0E48\u0E32\u0E42\u0E1B\u0E23\u0E44\u0E1F\u0E25\u0E4C",
  txt_title_set_information_name: "\u0E15\u0E31\u0E49\u0E07\u0E0A\u0E37\u0E48\u0E2D\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13\u0E41\u0E25\u0E30\u0E40\u0E1B\u0E34\u0E14\u0E40\u0E1C\u0E22\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E2D\u0E37\u0E48\u0E19\u0E46 \u0E15\u0E48\u0E2D\u0E2A\u0E32\u0E18\u0E32\u0E23\u0E13\u0E30",
  txt_main_menu: "\u0E40\u0E21\u0E19\u0E39\u0E2B\u0E25\u0E31\u0E01",
  txt_member_name: "\u0E0A\u0E37\u0E48\u0E2D\u0E2A\u0E21\u0E32\u0E0A\u0E34\u0E01",
  txt_member_email: "\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E4C\u0E2A\u0E21\u0E32\u0E0A\u0E34\u0E01",
  txt_member_role: "\u0E1A\u0E17\u0E1A\u0E32\u0E17\u0E02\u0E2D\u0E07\u0E2A\u0E21\u0E32\u0E0A\u0E34\u0E01",
  txt_organisation: "\u0E2D\u0E07\u0E04\u0E4C\u0E01\u0E23",
  txt_member: "\u0E2A\u0E21\u0E32\u0E0A\u0E34\u0E01",
  txt_add_member_name: "\u0E40\u0E1E\u0E34\u0E48\u0E21\u0E0A\u0E37\u0E48\u0E2D\u0E2A\u0E21\u0E32\u0E0A\u0E34\u0E01",
  txt_password: "\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19",
  txt_enter_password: "\u0E43\u0E2A\u0E48\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19",
  txt_enter_member_email: "\u0E43\u0E2A\u0E48\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E2A\u0E21\u0E32\u0E0A\u0E34\u0E01",
  txt_select_role: "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E1A\u0E17\u0E1A\u0E32\u0E17",
  txt_enter_metamask_address: "\u0E1B\u0E49\u0E2D\u0E19\u0E17\u0E35\u0E48\u0E2D\u0E22\u0E39\u0E48 Metamask",
  txt_enter_concordium_address: "\u0E1B\u0E49\u0E2D\u0E19\u0E17\u0E35\u0E48\u0E2D\u0E22\u0E39\u0E48\u0E04\u0E2D\u0E19\u0E04\u0E2D\u0E23\u0E4C\u0E40\u0E14\u0E35\u0E22\u0E21",
  txt_role: "\u0E1A\u0E17\u0E1A\u0E32\u0E17",
  txt_metamask_wallet: "\u0E01\u0E23\u0E30\u0E40\u0E1B\u0E4B\u0E32\u0E40\u0E07\u0E34\u0E19 Metamask",
  txt_concordium_wallet: "\u0E01\u0E23\u0E30\u0E40\u0E1B\u0E4B\u0E32\u0E40\u0E07\u0E34\u0E19 Concordium",
  txt_version: "\u0E23\u0E38\u0E48\u0E19:",
  txt_sign_in_to_getting_started: "\u0E25\u0E07\u0E0A\u0E37\u0E48\u0E2D\u0E40\u0E02\u0E49\u0E32\u0E43\u0E0A\u0E49\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19",
  txt_first_name: "\u0E0A\u0E37\u0E48\u0E2D",
  txt_last_name: "\u0E19\u0E32\u0E21\u0E2A\u0E01\u0E38\u0E25",
  txt_organization: "\u0E2D\u0E07\u0E04\u0E4C\u0E01\u0E23",
  txt_running: "\u0E01\u0E4D\u0E32\u0E25\u0E31\u0E07\u0E17\u0E33",
  txt_schedule: "\u0E15\u0E32\u0E23\u0E32\u0E07\u0E19\u0E31\u0E14\u0E2B\u0E21\u0E32\u0E22",
  txt_stop: "\u0E2B\u0E22\u0E38\u0E14"
};

// src/translations/ua/common.json
var common_default6 = {
  txt_welcome_to: "\u041B\u0430\u0441\u043A\u0430\u0432\u043E \u043F\u0440\u043E\u0441\u0438\u043C\u043E \u0434\u043E ",
  txt_email: "\u0415\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u0430 \u043F\u043E\u0448\u0442\u0430",
  txt_sign_in: "\u0423\u0432\u0456\u0439\u0442\u0438",
  txt_sign_in_with_sso: "\u0423\u0432\u0456\u0439\u0442\u0438 \u0437\u0430 \u0434\u043E\u043F\u043E\u043C\u043E\u0433\u043E\u044E SSO",
  txt_sign_up: "\u0417\u0430\u0440\u0435\u0454\u0441\u0442\u0440\u0443\u0432\u0430\u0442\u0438\u0441\u044F",
  txt_login_text_1: "\u041B\u0430\u0441\u043A\u0430\u0432\u043E \u043F\u0440\u043E\u0441\u0438\u043C\u043E \u0434\u043E",
  txt_login_text_2: "\u0423\u0432\u0456\u0439\u0434\u0456\u0442\u044C, \u0449\u043E\u0431 \u043F\u043E\u0447\u0430\u0442\u0438 \u0440\u043E\u0431\u043E\u0442\u0443.",
  txt_do_not_have_an_account: "\u041D\u0435 \u043C\u0430\u0454\u0442\u0435 \u043E\u0431\u043B\u0456\u043A\u043E\u0432\u043E\u0433\u043E \u0437\u0430\u043F\u0438\u0441\u0443? \u0421\u0442\u0432\u043E\u0440\u0456\u0442\u044C \u0431\u0435\u0437\u043A\u043E\u0448\u0442\u043E\u0432\u043D\u0438\u0439 \u043E\u0431\u043B\u0456\u043A\u043E\u0432\u0438\u0439 \u0437\u0430\u043F\u0438\u0441",
  txt_username: "\u0406\u043C'\u044F \u043A\u043E\u0440\u0438\u0441\u0442\u0443\u0432\u0430\u0447\u0430",
  txt_search_for_something: "\u0428\u0443\u043A\u0430\u0442\u0438 \u0449\u043E\u0441\u044C ...",
  txt_search_all_content: "\u0428\u0443\u043A\u0430\u0442\u0438 \u0432\u0435\u0441\u044C \u0432\u043C\u0456\u0441\u0442",
  txt_help_center: "\u0434\u043E\u0432\u0456\u0434\u043A\u043E\u0432\u0438\u0439 \u0446\u0435\u043D\u0442\u0440",
  txt_sign_out: "\u0412\u0438\u0439\u0442\u0438",
  txt_notifications: "\u0421\u043F\u043E\u0432\u0456\u0449\u0435\u043D\u043D\u044F",
  txt_client: "\u041A\u043B\u0456\u0454\u043D\u0442",
  txt_more: "\u0411\u0456\u043B\u044C\u0448\u0435",
  txt_menu_digital_assets: "\u0426\u0438\u0444\u0440\u043E\u0432\u0456 \u0430\u043A\u0442\u0438\u0432\u0438",
  txt_today: "\u0421\u044C\u043E\u0433\u043E\u0434\u043D\u0456",
  txt_general_information: "\u0417\u0430\u0433\u0430\u043B\u044C\u043D\u0430 \u0456\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0456\u044F",
  txt_projectpage_password: "\u041F\u0430\u0440\u043E\u043B\u044C",
  txt_profile_picture: "\u0417\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u043D\u044F \u043F\u0440\u043E\u0444\u0456\u043B\u044E",
  txt_back_to_dashboard: "\u041D\u0430\u0437\u0430\u0434 \u0434\u043E \u0456\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0456\u0439\u043D\u043E\u0457 \u043F\u0430\u043D\u0435\u043B\u0456",
  txt_menu_profile: "\u041F\u0440\u043E\u0444\u0456\u043B\u044C",
  txt_Status: "\u0421\u0442\u0430\u043D",
  txt_Birthday: "\u0414\u0435\u043D\u044C \u043D\u0430\u0440\u043E\u0434\u0436\u0435\u043D\u043D\u044F",
  txt_phone: "\u0422\u0435\u043B\u0435\u0444\u043E\u043D",
  txt_Address_1: "\u0410\u0434\u0440\u0435\u0441\u0430 1",
  txt_Address_2: "\u0410\u0434\u0440\u0435\u0441\u0430 2",
  txt_State: "\u0421\u0442\u0430\u043D",
  txt_Country: "\u041A\u0440\u0430\u0457\u043D\u0430",
  txt_Countries: "\u041A\u0440\u0430\u0457\u043D\u0438",
  txt_Zipcode: "\u041F\u043E\u0448\u0442\u043E\u0432\u0438\u0439 \u0456\u043D\u0434\u0435\u043A\u0441",
  txt_fullname: "\u041F\u043E\u0432\u043D\u0435 \u0456\u043C'\u044F",
  txt_City: "\u041C\u0456\u0441\u0442\u043E",
  txt_current_password: "\u041F\u043E\u0442\u043E\u0447\u043D\u0438\u0439 \u043F\u0430\u0440\u043E\u043B\u044C",
  txt_confirm_password: "\u041F\u0456\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u0438 \u043D\u043E\u0432\u0438\u0439 \u043F\u0430\u0440\u043E\u043B\u044C",
  txt_new_password: "\u041D\u043E\u0432\u0438\u0439 \u043F\u0430\u0440\u043E\u043B\u044C",
  txt_update: "\u041E\u043D\u043E\u0432\u0438\u0442\u0438",
  txt_delete: "\u0412\u0438\u0434\u0430\u043B\u0438\u0442\u0438",
  txt_choose_an_action: "\u0412\u0438\u0431\u0435\u0440\u0456\u0442\u044C \u0434\u0456\u044E",
  txt_list: "\u0421\u043F\u0438\u0441\u043E\u043A",
  txt_thumb: "\u041A\u0430\u0440\u0442\u0438\u043D\u043A\u0430",
  txt_show_more: "\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u0438 \u0431\u0456\u043B\u044C\u0448\u0435",
  txt_start_date: "\u0414\u0430\u0442\u0430 \u043F\u043E\u0447\u0430\u0442\u043A\u0443",
  txt_end_date: "\u0414\u0430\u0442\u0430 \u0437\u0430\u0432\u0435\u0440\u0448\u0435\u043D\u043D\u044F",
  txt_created_date: "\u0414\u0430\u0442\u0430 \u0441\u0442\u0432\u043E\u0440\u0435\u043D\u043D\u044F",
  txt_short_description: "\u041A\u043E\u0440\u043E\u0442\u043A\u0438\u0439 \u043E\u043F\u0438\u0441",
  txt_short_description_about_project: "\u041A\u043E\u0440\u043E\u0442\u043A\u0438\u0439 \u043E\u043F\u0438\u0441 \u043F\u0440\u043E\u0435\u043A\u0442\u0443",
  txt_budget: "\u0411\u044E\u0434\u0436\u0435\u0442",
  txt_status: "\u0421\u0442\u0430\u043D",
  txt_name_personas: "\u0406\u043C'\u044F",
  txt_updated_date: "\u041E\u043D\u043E\u0432\u043B\u0435\u043D\u0430 \u0434\u0430\u0442\u0430",
  txt_title: "\u041D\u0430\u0437\u0432\u0430",
  txt_channels: "\u041A\u0430\u043D\u0430\u043B\u0438",
  txt_date: "\u0414\u0430\u0442\u0430",
  txt_edit: "\u0420\u0435\u0434\u0430\u0433\u0443\u0432\u0430\u0442\u0438",
  txt_general: "\u0417\u0430\u0433\u0430\u043B\u044C\u043D\u0456",
  txt_save: "\u0417\u0431\u0435\u0440\u0435\u0433\u0442\u0438",
  txt_back: "\u041D\u0430\u0437\u0430\u0434",
  txt_next: "\u0414\u0430\u043B\u0456",
  txt_connecting: "\u041F\u0456\u0434\u043A\u043B\u044E\u0447\u0435\u043D\u043D\u044F",
  txt_connect: "\u041F\u0456\u0434\u043A\u043B\u044E\u0447\u0438\u0442\u0438\u0441\u044F",
  txt_enable: "\u0423\u0432\u0456\u043C\u043A\u043D\u0443\u0442\u0438",
  txt_remove: "\u0412\u0438\u0434\u0430\u043B\u0438\u0442\u0438",
  txt_type: "\u0422\u0438\u043F",
  txt_action: "\u0414\u0456\u044F",
  txt_profile: "\u041F\u0440\u043E\u0444\u0456\u043B\u044C",
  txt_apply: "\u0417\u0430\u0441\u0442\u043E\u0441\u0443\u0432\u0430\u0442\u0438",
  txt_video: "\u0412\u0456\u0434\u0435\u043E",
  txt_select: "\u0412\u0438\u0431\u0435\u0440\u0456\u0442\u044C...",
  txt_menu_dashboard: "\u0406\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0456\u0439\u043D\u0430 \u043F\u0430\u043D\u0435\u043B\u044C",
  txt_title_profile_setting: "\u041D\u0430\u043B\u0430\u0448\u0442\u0443\u0432\u0430\u043D\u043D\u044F \u043F\u0440\u043E\u0444\u0456\u043B\u044E",
  txt_title_set_information_name: "\u0412\u0441\u0442\u0430\u043D\u043E\u0432\u0456\u0442\u044C \u0441\u0432\u043E\u0454 \u0456\u043C'\u044F \u0442\u0430 \u0456\u043D\u0448\u0443 \u0437\u0430\u0433\u0430\u043B\u044C\u043D\u043E\u0434\u043E\u0441\u0442\u0443\u043F\u043D\u0443 \u0456\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0456\u044E",
  txt_main_menu: "\u0413\u043E\u043B\u043E\u0432\u043D\u0435 \u043C\u0435\u043D\u044E",
  txt_member_name: "\u0406\u043C'\u044F \u0447\u043B\u0435\u043D\u0430",
  txt_member_email: "\u0415\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u0430 \u043F\u043E\u0448\u0442\u0430 \u0443\u0447\u0430\u0441\u043D\u0438\u043A\u0430",
  txt_member_role: "\u0420\u043E\u043B\u044C \u0447\u043B\u0435\u043D\u0430",
  txt_organisation: "\u043E\u0440\u0433\u0430\u043D\u0456\u0437\u0430\u0446\u0456\u044F",
  txt_member: "\u0427\u043B\u0435\u043D",
  txt_add_member_name: "\u0414\u043E\u0434\u0430\u0442\u0438 \u0456\u043C'\u044F \u0443\u0447\u0430\u0441\u043D\u0438\u043A\u0430",
  txt_password: "\u041F\u0430\u0440\u043E\u043B\u044C",
  txt_enter_password: "\u0412\u0432\u0435\u0434\u0456\u0442\u044C \u043F\u0430\u0440\u043E\u043B\u044C",
  txt_enter_member_email: "\u0412\u0432\u0435\u0434\u0456\u0442\u044C \u0435\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u0443 \u0430\u0434\u0440\u0435\u0441\u0443 \u0443\u0447\u0430\u0441\u043D\u0438\u043A\u0430",
  txt_select_role: "\u0412\u0438\u0431\u0435\u0440\u0456\u0442\u044C \u0420\u043E\u043B\u044C",
  txt_enter_metamask_address: "\u0412\u0432\u0435\u0434\u0456\u0442\u044C \u0430\u0434\u0440\u0435\u0441\u0443 \u043C\u0435\u0442\u0430\u043C\u0430\u0441\u043A\u0438",
  txt_enter_concordium_address: "\u0412\u0432\u0435\u0434\u0456\u0442\u044C \u0430\u0434\u0440\u0435\u0441\u0443 Concordium",
  txt_role: "\u0440\u043E\u043B\u044C",
  txt_metamask_wallet: "\u0413\u0430\u043C\u0430\u043D\u0435\u0446\u044C Metamask",
  txt_concordium_wallet: "\u0413\u0430\u043C\u0430\u043D\u0435\u0446\u044C Concordium",
  txt_version: "\u0412\u0435\u0440\u0441\u0456\u044F:",
  txt_sign_in_to_getting_started: "\u0423\u0432\u0456\u0439\u0434\u0456\u0442\u044C, \u0449\u043E\u0431 \u043F\u043E\u0447\u0430\u0442\u0438 \u0440\u043E\u0431\u043E\u0442\u0443.",
  txt_first_name: "\u0406\u043C'\u044F",
  txt_last_name: "\u041F\u0440\u0456\u0437\u0432\u0438\u0449\u0435",
  txt_organization: "\u041E\u0440\u0433\u0430\u043D\u0456\u0437\u0430\u0446\u0456\u044F",
  txt_running: "\u0417\u0430\u043F\u0443\u0449\u0435\u043D\u043E",
  txt_schedule: "\u0420\u043E\u0437\u043A\u043B\u0430\u0434",
  txt_stop: "\u0417\u0443\u043F\u0438\u043D\u0438\u0442\u0438"
};

// src/translations/vi/common.json
var common_default7 = {
  txt_welcome_to: "Ch\xE0o m\u1EEBng \u0111\u1EBFn",
  txt_email: "Email",
  txt_sign_in: "\u0110\u0103ng nh\xE2\u0323p",
  txt_sign_in_with_sso: "\u0110\u0103ng nh\u1EADp v\u1EDBi SSO",
  txt_sign_up: "\u0110\u0103ng k\xFD",
  txt_login_text_1: "Ch\xE0o m\u1EEBng \u0111\u1EBFn",
  txt_login_text_2: "\u0110\u0103ng nh\xE2\u0323p \u0111\xEA\u0309 b\u0103\u0301t \u0111\xE2\u0300u",
  txt_do_not_have_an_account: "Ba\u0323n ch\u01B0a c\xF3 t\xE0i kho\u1EA3n? T\u1EA1o t\xE0i kho\u1EA3n mi\u1EC5n ph\xED",
  txt_username: "T\xEAn ng\u01B0\u01A1\u0300i du\u0300ng",
  txt_search_for_something: "T\xECm ki\u1EBFm ...",
  txt_search_all_content: "Ti\u0300m ki\xEA\u0301m t\xE2\u0301t ca\u0309 n\xF4\u0323i dung",
  txt_help_center: "Trung t\xE2m h\xF4\u0303 tr\u01A1\u0323",
  txt_sign_out: "\u0110\u0103ng xu\u1EA5t",
  txt_notifications: "Th\xF4ng b\xE1o",
  txt_client: "Kha\u0301ch ha\u0300ng",
  txt_more: "Th\xEAm",
  txt_menu_digital_assets: "Danh sa\u0301ch Ta\u0300i sa\u0309n Ky\u0303 thu\xE2\u0323t s\xF4\u0301",
  txt_today: "H\xF4m nay",
  txt_general_information: "Th\xF4ng tin t\xF4\u0309ng h\u01A1\u0323p",
  txt_projectpage_password: "M\xE2\u0323t kh\xE2\u0309u",
  txt_profile_picture: "A\u0309nh \u0111a\u0323i di\xEA\u0323n",
  txt_back_to_dashboard: "Quay v\xEA\u0300 trang chu\u0309",
  txt_menu_profile: "H\xF4\u0300 s\u01A1",
  txt_Status: "Tra\u0323ng tha\u0301i",
  txt_Birthday: "Nga\u0300y sinh",
  txt_phone: "S\xF4\u0301 \u0111i\xEA\u0323n thoa\u0323i",
  txt_Address_1: "\u0110i\u0323a chi\u0309 1",
  txt_Address_2: "\u0110i\u0323a chi\u0309 2",
  txt_State: "Ti\xEA\u0309u bang",
  txt_Country: "Qu\xF4\u0301c gia",
  txt_Countries: "Qu\xF4\u0301c gia",
  txt_Zipcode: "Zipcode",
  txt_fullname: "T\xEAn \u0111\xE2\u0300y \u0111u\u0309",
  txt_City: "Tha\u0300nh ph\xF4\u0301",
  txt_current_password: "M\u1EADt kh\u1EA9u hi\u1EC7n t\u1EA1i",
  txt_confirm_password: "X\xE1c nh\u1EADn m\u1EADt kh\u1EA9u m\u1EDBi",
  txt_new_password: "M\u1EADt kh\u1EA9u m\u1EDBi",
  txt_update: "C\u1EADp nh\u1EADt",
  txt_delete: "Xo\xE1",
  txt_choose_an_action: "Ch\u1ECDn h\xE0nh \u0111\u1ED9ng",
  txt_list: "Danh sa\u0301ch",
  txt_thumb: "Thu nh\u1ECF",
  txt_show_more: "Hi\xEA\u0309n thi\u0323 th\xEAm",
  txt_start_date: "Ng\xE0y b\u1EAFt \u0111\u1EA7u",
  txt_end_date: "Ng\xE0y k\u1EBFt th\xFAc",
  txt_created_date: "Ng\xE0y \u0111\u01B0\u1EE3c t\u1EA1o",
  txt_short_description: "M\xF4 t\u1EA3 ng\u1EAFn",
  txt_short_description_about_project: "M\xF4 t\u1EA3 ng\u1EAFn v\u1EC1 d\u1EF1 \xE1n",
  txt_budget: "Ng\xE2n s\xE1ch",
  txt_status: "Tr\u1EA1ng th\xE1i",
  txt_name_personas: "T\xEAn ch\xE2n dung kha\u0301ch ha\u0300ng",
  txt_updated_date: "Ng\xE0y c\u1EADp nh\u1EADt",
  txt_title: "Ti\xEAu \u0111\u1EC1",
  txt_channels: "K\xEAnh",
  txt_date: "Nga\u0300y",
  txt_edit: "Ch\u1EC9nh s\u1EEDa",
  txt_general: "T\u1ED5ng h\u1EE3p",
  txt_save: "L\u01B0u",
  txt_back: "Tr\u01A1\u0309 v\xEA\u0300",
  txt_next: "Ti\xEA\u0301p theo",
  txt_connecting: "\u0110ang k\xEA\u0301t n\xF4\u0301i",
  txt_connect: "K\xEA\u0301t n\xF4\u0301i",
  txt_enable: "Cho phe\u0301p",
  txt_remove: "Xo\u0301a",
  txt_type: "Ki\xEA\u0309u",
  txt_action: "Hoa\u0323t \u0111\xF4\u0323ng",
  txt_profile: "H\xF4\u0300 s\u01A1",
  txt_apply: "Ga\u0301n",
  txt_video: "Video",
  txt_menu_dashboard: "Trang chu\u0309",
  txt_warning: "C\u1EA3nh b\xE1o",
  txt_successful: "Th\xE0nh c\xF4ng",
  txt_error: "L\u1ED7i",
  txt_select: "L\u1EF1a ch\u1ECDn...",
  txt_title_profile_setting: "Ca\u0300i \u0111\u0103\u0323t h\xF4\u0300 s\u01A1",
  txt_title_set_information_name: "Ca\u0300i \u0111\u0103\u0323t t\xEAn v\xE0 c\xE1c th\xF4ng tin kh\xE1c",
  txt_main_menu: "Menu chi\u0301nh",
  txt_member_name: "T\xEAn th\xE0nh vi\xEAn",
  txt_member_email: "Email th\xE0nh vi\xEAn",
  txt_member_role: "Vai tr\xF2 th\xE0nh vi\xEAn",
  txt_organisation: "T\u1ED5 ch\u1EE9c",
  txt_member: "Th\xE0nh vi\xEAn",
  txt_add_member_name: "Th\xEAm t\xEAn th\xE0nh vi\xEAn",
  txt_password: "M\u1EADt kh\u1EA9u",
  txt_enter_password: "Nh\u1EADp m\u1EADt kh\u1EA9u",
  txt_enter_member_email: "Nh\u1EADp Email th\xE0nh vi\xEAn",
  txt_select_role: "Ch\u1ECDn vai tr\xF2",
  txt_enter_metamask_address: "Nh\u1EADp \u0111\u1ECBa ch\u1EC9 v\xED Metamask",
  txt_enter_concordium_address: "Nh\u1EADp \u0111\u1ECBa ch\u1EC9 v\xED Concordium",
  txt_role: "Vai tr\xF2",
  txt_metamask_wallet: "V\xED Metamask",
  txt_concordium_wallet: "V\xED Concordium",
  txt_version: "Phi\xEAn b\u1EA3n:",
  txt_first_name: "T\xEAn",
  txt_last_name: "H\u1ECD",
  "txt_ Organisation": "T\u1ED5 ch\u1EE9c",
  txt_running: "\u0110ang ch\u1EA1y",
  txt_schedule: "\u0110ang ch\u1EDD",
  txt_stop: "D\u1EEBng"
};

// src/translations/fr/common.json
var common_default8 = {
  txt_welcome_to: "Bienvenue sur ",
  txt_email: "Courriel",
  txt_sign_in: "S'identifier",
  txt_sign_in_with_sso: "S'identifier avec AesirX SSO",
  txt_sign_up: "S'inscrire",
  txt_do_not_have_an_account: "Pas encore de compte ? Cr\xE9er un compte gratuitement",
  txt_username: "Utilisateur",
  txt_search_for_something: "Chercher quelque chose...",
  txt_help_center: "Centre d'aide",
  txt_sign_out: "Se d\xE9connecter",
  txt_notifications: "Notifications",
  txt_client: "Client",
  txt_more: "Plus",
  txt_menu_digital_assets: "Digital Assets",
  txt_today: "Aujourd'hui",
  txt_general_information: "Information g\xE9n\xE9rale",
  txt_projectpage_password: "Mot de passe",
  txt_profile_picture: "Photo de profil",
  txt_back_to_dashboard: "Retour au tableau de bord",
  txt_menu_profile: "Profil",
  txt_Status: "Status",
  txt_Birthday: "Anniversaire",
  txt_phone: "T\xE9l\xE9phone",
  txt_Address_1: "Adresse 1",
  txt_Address_2: "Adresse 2",
  txt_State: "D\xE9partement",
  txt_Country: "pays",
  txt_Zipcode: "Code postal",
  txt_fullname: "Nom",
  txt_City: "Ville",
  txt_current_password: "Mot de passe actuel",
  txt_confirm_password: "Confirmer le nouveau mot de passe",
  txt_new_password: "Nouveau mot de passe",
  txt_update: "Mise \xE0 jour",
  txt_delete: "Supprimer",
  txt_choose_an_action: "Choisir une action",
  txt_list: "Liste",
  txt_thumb: "Vignette",
  txt_show_more: "Afficher plus",
  txt_start_date: "Date de d\xE9but",
  txt_end_date: "Date de fin",
  txt_created_date: "Date de cr\xE9ation",
  txt_short_description: "Description",
  txt_short_description_about_project: "Courte description du projet",
  txt_budget: "Budget",
  txt_status: "Etat",
  txt_name_personas: "Nom",
  txt_updated_date: "Date de mise \xE0 jour",
  txt_title: "Titre",
  txt_channels: "Canaux",
  txt_date: "Date",
  txt_edit: "Edition",
  txt_general: "G\xE9n\xE9ral",
  txt_save: "Enregistrer",
  txt_back: "Retour",
  txt_next: "Suivant",
  txt_connecting: "Connecter",
  txt_connect: "Connect\xE9",
  txt_enable: "Disponible",
  txt_remove: "Supprimer",
  txt_type: "Type",
  txt_action: "Action",
  txt_profile: "Profil",
  txt_apply: "Appliquer",
  txt_select: "S\xE9lectionner...",
  txt_video: "Vid\xE9o",
  txt_main_menu: "Menu principal",
  txt_member_name: "Nom du membre",
  txt_member_email: "Courriel du membre",
  txt_member_role: "R\xF4le de membre",
  txt_organisation: "Organisation",
  txt_member: "Membre",
  txt_add_member_name: "Ajouter un nom de membre",
  txt_password: "Mot de passe",
  txt_enter_password: "Entrez le mot de passe",
  txt_enter_member_email: "Entrez l'adresse e-mail du membre",
  txt_select_role: "S\xE9lectionner un r\xF4le",
  txt_enter_metamask_address: "Entrez l'adresse Metamask",
  txt_enter_concordium_address: "Entrez l'adresse du Concordium",
  txt_role: "R\xF4le",
  txt_metamask_wallet: "Portefeuille Metamask",
  txt_concordium_wallet: "Portefeuille Concordium",
  txt_version: "Version :",
  txt_sign_in_to_getting_started: "Prijavite se da bi ste zapo\u010Deli.",
  txt_first_name: "Pr\xE9nom",
  txt_last_name: "Nom",
  txt_organization: "Organisation",
  txt_running: "R\xE0unning",
  txt_schedule: "S\xE9ance",
  txt_stop: "Stop"
};

// src/providers/I18nextProvider.tsx
var defaultLanguages = {
  en: {
    title: "English",
    translation: common_default2
  },
  da: {
    title: "Dansk",
    translation: common_default
  },
  vi: {
    title: "Ti\u1EBFng Vi\u1EC7t",
    translation: common_default7
  },
  th: {
    title: "\u0E20\u0E32\u0E29\u0E32\u0E44\u0E17\u0E22",
    translation: common_default5
  },
  hr: {
    title: "Hrvatski",
    translation: common_default4
  },
  uk: {
    title: "Y\u043A\u0440\u0430\u0457\u043D\u0441\u044C\u043A\u0430",
    translation: common_default6
  },
  es: {
    title: "Espa\xF1ol",
    translation: common_default3
  },
  fr: {
    title: "Fran\xE7ais",
    translation: common_default8
  }
};
var I18NextContext = createContext({
  listLanguages: []
});
var AesirXI18nextProvider = ({
  children,
  appLanguages
}) => {
  const listLanguages = [];
  if (!i18n.isInitialized) {
    i18n.use(LanguageDetector).use(initReactI18next).init({
      resources: defaultLanguages,
      lng: localStorage.getItem("i18nextLng") || "en",
      fallbackLng: "en",
      debug: true
    });
  }
  Object.entries(appLanguages).forEach(([key, resource]) => {
    i18n.addResourceBundle(key, "translation", resource);
    listLanguages.push({ label: defaultLanguages[key].title, value: key });
  });
  return /* @__PURE__ */ React.createElement(I18NextContext.Provider, { value: { listLanguages } }, /* @__PURE__ */ React.createElement(I18nextProvider, { i18n }, children));
};
var useI18nextContext = () => useContext(I18NextContext);
var withI18nextContext = (Component) => (props) => {
  return /* @__PURE__ */ React.createElement(Component, { ...props, ...useI18nextContext() });
};

export {
  AesirXI18nextProvider,
  useI18nextContext,
  withI18nextContext
};
