// src/components/Form/Input/index.tsx
import React from "react";
import { Form } from "react-bootstrap";

// src/components/Form/Input/index.scss
var css = `/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */`;
document.head.appendChild(document.createElement("style")).appendChild(document.createTextNode(css));

// src/components/Form/Input/index.tsx
import { withTranslation } from "react-i18next";
var Input = ({ field, ...props }) => {
  const { t } = props;
  const handleChange = (e) => {
    if (Object.prototype.hasOwnProperty.call(field, "handleChange")) {
      e.target.value = field.maxLength && field.maxLength !== "0" ? e.target.value.normalize("NFKC").slice(0, field.maxLength) : e.target.value.normalize("NFKC");
      field.handleChange(e);
    }
  };
  return /* @__PURE__ */ React.createElement("div", { className: "position-relative" }, /* @__PURE__ */ React.createElement(
    Form.Control,
    {
      as: "input",
      defaultValue: field.getValueSelected ?? "",
      type: field.typeFormat ? field.typeFormat == 11 ? "password" : "text" : "text",
      required: field.required ?? false,
      id: field.key,
      onChange: (e) => handleChange(e),
      onSelect: (e) => handleChange(e),
      onPaste: field.pasted ?? void 0,
      className: `${field.classNameInput}`,
      onBlur: field.blurred ?? void 0,
      placeholder: field.placeholder ?? t("txt_type"),
      readOnly: field.readOnly,
      disabled: field.disabled,
      maxLength: field.maxLength
    }
  ), field.format && /* @__PURE__ */ React.createElement(
    "div",
    {
      className: `form-control input-format border-0 border-start border-end ${field.classNameInput}`
    },
    field.format
  ));
};
var A = withTranslation()(Input);

export {
  A
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
