import {
  RoleViewModel_default
} from "./chunk-CFJLG5DA.js";
import {
  RoleStore
} from "./chunk-IVGRCTQI.js";

// src/pages/Roles/RoleViewModel/RoleViewModelContextProvider.tsx
import React from "react";
var roleStore = new RoleStore();
var roleViewModel = new RoleViewModel_default(roleStore);
var RoleViewModelContext = React.createContext({
  model: roleViewModel
});
var RoleViewModelContextProvider = ({ children }) => {
  return /* @__PURE__ */ React.createElement(RoleViewModelContext.Provider, { value: { model: roleViewModel } }, children);
};
var useRoleViewModel = () => React.useContext(RoleViewModelContext);
var withRoleViewModel = (Component) => (props) => {
  return /* @__PURE__ */ React.createElement(Component, { ...props, ...useRoleViewModel() });
};

export {
  RoleViewModelContext,
  RoleViewModelContextProvider,
  useRoleViewModel,
  withRoleViewModel
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
