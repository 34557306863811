import {
  ESI_CONTENT_THEME_API_RESPONSE_FIELD_KEY,
  ESI_CONTENT_THEME_FIELD_KEY
} from "./chunk-XNHPX3FL.js";
import {
  BaseItemModel_default
} from "./chunk-UPAELC5I.js";
import {
  BaseModel_default
} from "./chunk-ITJMNR7M.js";

// src/ContentTheme/ContentThemeModel.ts
var ContentThemeModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    if (entities) {
      this.unTransformedItems = entities._embedded.item;
      this.items = entities._embedded.item.map((element) => {
        return new ContentThemeItemModel(element);
      });
      this.items.pagination = this.getPagination();
    }
  }
};
var ContentThemeItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.designId = 0;
    this.image = "";
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [ESI_CONTENT_THEME_FIELD_KEY.ID]: this.id,
        [ESI_CONTENT_THEME_FIELD_KEY.DESIGN_ID]: this.designId,
        [ESI_CONTENT_THEME_FIELD_KEY.IMAGE]: this.image
      };
    };
    if (entity) {
      this.designId = entity[ESI_CONTENT_THEME_API_RESPONSE_FIELD_KEY.DESIGN_ID] ?? "";
      this.image = entity[ESI_CONTENT_THEME_API_RESPONSE_FIELD_KEY.IMAGE] ?? "";
    }
  }
};
ContentThemeItemModel.__transformItemToApiOfCreation = (data) => {
  return {
    [ESI_CONTENT_THEME_API_RESPONSE_FIELD_KEY.DESIGN_ID]: data[ESI_CONTENT_THEME_FIELD_KEY.DESIGN_ID] ?? "",
    [ESI_CONTENT_THEME_API_RESPONSE_FIELD_KEY.IMAGE]: data[ESI_CONTENT_THEME_FIELD_KEY.IMAGE] ?? ""
  };
};
ContentThemeItemModel.__transformItemToApiOfUpdation = (data) => {
  return {
    [ESI_CONTENT_THEME_API_RESPONSE_FIELD_KEY.ID]: data[ESI_CONTENT_THEME_FIELD_KEY.ID],
    [ESI_CONTENT_THEME_API_RESPONSE_FIELD_KEY.DESIGN_ID]: data[ESI_CONTENT_THEME_FIELD_KEY.DESIGN_ID],
    [ESI_CONTENT_THEME_API_RESPONSE_FIELD_KEY.IMAGE]: data[ESI_CONTENT_THEME_FIELD_KEY.IMAGE]
  };
};

export {
  ContentThemeModel,
  ContentThemeItemModel
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
