// src/store/UtilsStore/UtilsStore.ts
import { AesirxPimUtilApiService } from "aesirx-lib";
var UtilsStore = class {
  async getListPublishStatus() {
    try {
      const getAesirxPimUtilApiService = new AesirxPimUtilApiService();
      const respondedData = await getAesirxPimUtilApiService.getListPublishStatus();
      return { error: false, response: respondedData };
    } catch (error) {
      return { error: true, response: error?.response?.data };
    }
  }
  async getListContentType() {
    try {
      const getAesirxPimUtilApiService = new AesirxPimUtilApiService();
      const respondedData = await getAesirxPimUtilApiService.getListContentType();
      return { error: false, response: respondedData };
    } catch (error) {
      return { error: true, response: error?.response?.data };
    }
  }
  async getListFieldType() {
    try {
      const getAesirxPimUtilApiService = new AesirxPimUtilApiService();
      const respondedData = await getAesirxPimUtilApiService.getListFieldType();
      return { error: false, response: respondedData };
      return respondedData;
    } catch (error) {
      return { error: true, response: error?.response?.data };
    }
  }
};

export {
  UtilsStore
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
