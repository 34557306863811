// src/store/UtilsStore/config.ts
import {
  ephemeralConnectorType,
  WalletConnectConnector,
  BrowserWalletConnector,
  CONCORDIUM_WALLET_CONNECT_PROJECT_ID
} from "@concordium/react-components";
var WALLET_CONNECT_OPTS = {
  projectId: CONCORDIUM_WALLET_CONNECT_PROJECT_ID,
  metadata: {
    name: "AesirX Shield of Privacy",
    description: "AesirX Shield of Privacy is our privacy-first security Solution.",
    url: "https://aesirx.io/",
    icons: ["https://walletconnect.com/walletconnect-logo.png"]
  }
};
var BROWSER_WALLET = ephemeralConnectorType(BrowserWalletConnector.create);
var WALLET_CONNECT = ephemeralConnectorType(
  WalletConnectConnector.create.bind(void 0, WALLET_CONNECT_OPTS)
);

export {
  BROWSER_WALLET,
  WALLET_CONNECT
};
