import {
  NEWS_FIELD_KEY
} from "./chunk-BN5HVT2T.js";
import {
  BaseItemModel_default
} from "./chunk-UPAELC5I.js";
import {
  BaseModel_default
} from "./chunk-ITJMNR7M.js";

// src/News/NewsModel.ts
var NewsModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    if (entities) {
      this.items = entities._embedded.item.map((element) => {
        return new NewsItemModel(element);
      });
    }
  }
};
var NewsItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.id = "";
    this.title = "";
    this.intro_image = "";
    this.link = "";
    this.publish_date = "";
    this.category = "";
    this.category_link = "";
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [NEWS_FIELD_KEY.ID]: this.id,
        [NEWS_FIELD_KEY.TITLE]: this.title,
        [NEWS_FIELD_KEY.INTRO_IMAGE]: this.intro_image,
        [NEWS_FIELD_KEY.LINK]: this.link,
        [NEWS_FIELD_KEY.PUBLISH_DATE]: this.publish_date,
        [NEWS_FIELD_KEY.CATEGORY]: this.category,
        [NEWS_FIELD_KEY.CATEGORY_LINK]: this.category_link
      };
    };
    if (entity) {
      this.id = entity[NEWS_FIELD_KEY.ID] ?? "";
      this.title = entity[NEWS_FIELD_KEY.TITLE] ?? "";
      this.intro_image = entity[NEWS_FIELD_KEY.INTRO_IMAGE];
      this.link = entity[NEWS_FIELD_KEY.LINK] ?? "";
      this.publish_date = entity[NEWS_FIELD_KEY.PUBLISH_DATE] ?? "0000-00-00 00:00:00";
      this.category = entity[NEWS_FIELD_KEY.CATEGORY] ?? "";
      this.category_link = entity[NEWS_FIELD_KEY.CATEGORY_LINK] ?? "";
    }
  }
};
NewsItemModel.__transformItemToApiOfCreation = () => {
  return {};
};
NewsItemModel.__transformItemToApiOfUpdation = () => {
  return {};
};

export {
  NewsModel,
  NewsItemModel
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
