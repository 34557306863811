// src/components/Modal/index.tsx
import React from "react";
import { withTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
var ModalComponent = ({
  header,
  footer,
  body,
  show,
  onHide,
  dialogClassName = "",
  onShow,
  bodyClassName = "",
  contentClassName = "",
  modalClassname = "",
  closeButton
}) => {
  return /* @__PURE__ */ React.createElement(
    Modal,
    {
      show,
      onShow,
      onHide,
      centered: true,
      dialogClassName,
      contentClassName,
      className: `aesirxui-modal ${modalClassname}`
    },
    /* @__PURE__ */ React.createElement(Modal.Header, { className: "px-4 border-bottom-0 text-body" }, header && /* @__PURE__ */ React.createElement(Modal.Title, null, header), closeButton && /* @__PURE__ */ React.createElement("button", { onClick: onHide, type: "button", className: "btn-close", "aria-label": "Close" })),
    /* @__PURE__ */ React.createElement(Modal.Body, { className: `aesirxui px-4 pt-2 pb-0 ${bodyClassName}` }, body),
    footer && /* @__PURE__ */ React.createElement(Modal.Footer, { className: "px-4" }, footer)
  );
};
var ModalComponentExtended = withTranslation()(ModalComponent);

export {
  ModalComponentExtended
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
