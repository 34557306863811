// src/Constant/NewsConstant.ts
var NEWS_FIELD_KEY = {
  ID: "id",
  TITLE: "title",
  INTRO_IMAGE: "image",
  LINK: "link",
  PUBLISH_DATE: "publish_up",
  CATEGORY: "category",
  CATEGORY_LINK: "category_link"
};
var NEWS_API_RESPONSE_FIELD_KEY = {
  ID: "id",
  TITLE: "title",
  INTRO_IMAGE: "image",
  LINK: "link",
  PUBLISH_DATE: "publish_up",
  CATEGORY: "category",
  CATEGORY_LINK: "category_link"
};

export {
  NEWS_FIELD_KEY,
  NEWS_API_RESPONSE_FIELD_KEY
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
