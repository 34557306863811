import {
  BaseItemModel_default
} from "./chunk-UPAELC5I.js";

// src/Pim/PimUtils/PimUtilsModel.ts
var PublishStatusModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.label = null;
    this.value = null;
    this.level = null;
    this.specifications = null;
    this.toJSON = () => {
      return {
        ["label"]: this.label,
        ["value"]: this.value,
        ["level"]: this.level,
        ["specifications"]: this.specifications
      };
    };
    if (entity) {
      this.label = entity["label"] ?? "";
      this.value = entity["value"] ?? "";
      this.level = entity["level"] ?? "";
      this.specifications = entity["specifications"] ?? "";
    }
  }
};

export {
  PublishStatusModel
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
