import {
  PIM_CATEGORY_DETAIL_FIELD_KEY
} from "./chunk-RS5ET3Q3.js";
import {
  BaseItemModel_default
} from "./chunk-UPAELC5I.js";
import {
  BaseModel_default
} from "./chunk-ITJMNR7M.js";

// src/Pim/PimCategory/PimCategoryModel.ts
var CategoryModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    if (entities) {
      this.items = entities._embedded.item.map((element) => {
        return new CategoryItemModel(element);
      });
    }
  }
};
var CategoryItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.id = null;
    this.title = null;
    this.alias = null;
    this.published = 0;
    this.featured = 0;
    this.parent_id = null;
    this.organisation_id = null;
    this.custom_fields = null;
    this.created_user_name = null;
    this.modified_user_name = null;
    this.created_time = null;
    this.publish_up = null;
    this.children = null;
    this.related_categories = null;
    this.level = null;
    this.modified_time = null;
    this.product_quantity = null;
    this.parent_name = null;
    this.product_type_id = null;
    this.product_type_name = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [PIM_CATEGORY_DETAIL_FIELD_KEY.ID]: this.id,
        [PIM_CATEGORY_DETAIL_FIELD_KEY.TITLE]: this.title,
        [PIM_CATEGORY_DETAIL_FIELD_KEY.ALIAS]: this.alias,
        [PIM_CATEGORY_DETAIL_FIELD_KEY.PUBLISHED]: this.published,
        [PIM_CATEGORY_DETAIL_FIELD_KEY.FEATURED]: this.featured,
        [PIM_CATEGORY_DETAIL_FIELD_KEY.PARENT_ID]: this.parent_id,
        [PIM_CATEGORY_DETAIL_FIELD_KEY.CUSTOM_FIELDS]: this.custom_fields,
        [PIM_CATEGORY_DETAIL_FIELD_KEY.CREATED_USER_NAME]: this.created_user_name,
        [PIM_CATEGORY_DETAIL_FIELD_KEY.MODIFIED_USER_NAME]: this.modified_user_name,
        [PIM_CATEGORY_DETAIL_FIELD_KEY.PUBLISH_UP]: this.publish_up,
        [PIM_CATEGORY_DETAIL_FIELD_KEY.CHILDREN]: this.children,
        [PIM_CATEGORY_DETAIL_FIELD_KEY.RELATED_CATEGORIES]: this.related_categories,
        [PIM_CATEGORY_DETAIL_FIELD_KEY.LEVEL]: this.level,
        [PIM_CATEGORY_DETAIL_FIELD_KEY.MODIFIED_TIME]: this.modified_time,
        [PIM_CATEGORY_DETAIL_FIELD_KEY.PRODUCT_QUANTITY]: this.product_quantity,
        [PIM_CATEGORY_DETAIL_FIELD_KEY.PARENT_NAME]: this.parent_name,
        [PIM_CATEGORY_DETAIL_FIELD_KEY.PRODUCT_TYPE_ID]: this.product_type_id,
        [PIM_CATEGORY_DETAIL_FIELD_KEY.PRODUCT_TYPE_NAME]: this.product_type_name
      };
    };
    if (entity) {
      this.id = entity[PIM_CATEGORY_DETAIL_FIELD_KEY.ID] ?? "";
      this.title = entity[PIM_CATEGORY_DETAIL_FIELD_KEY.TITLE] ?? "";
      this.alias = entity[PIM_CATEGORY_DETAIL_FIELD_KEY.ALIAS] ?? "";
      this.published = entity[PIM_CATEGORY_DETAIL_FIELD_KEY.PUBLISHED] ?? 0;
      this.featured = entity[PIM_CATEGORY_DETAIL_FIELD_KEY.FEATURED]?.toString() ?? "0";
      this.parent_id = entity[PIM_CATEGORY_DETAIL_FIELD_KEY.PARENT_ID] ?? 0;
      this.organisation_id = entity[PIM_CATEGORY_DETAIL_FIELD_KEY.ORGANISATION_ID] ?? 0;
      this.custom_fields = entity[PIM_CATEGORY_DETAIL_FIELD_KEY.CUSTOM_FIELDS] ?? "";
      this.created_user_name = entity[PIM_CATEGORY_DETAIL_FIELD_KEY.CREATED_USER_NAME] ?? "";
      this.modified_user_name = entity[PIM_CATEGORY_DETAIL_FIELD_KEY.MODIFIED_USER_NAME] ?? "";
      this.created_time = entity[PIM_CATEGORY_DETAIL_FIELD_KEY.CREATED_TIME] ?? "";
      this.publish_up = entity[PIM_CATEGORY_DETAIL_FIELD_KEY.PUBLISH_UP] ?? "";
      this.children = entity[PIM_CATEGORY_DETAIL_FIELD_KEY.CHILDREN] ?? [];
      this.related_categories = entity[PIM_CATEGORY_DETAIL_FIELD_KEY.RELATED_CATEGORIES] ?? "";
      this.level = entity[PIM_CATEGORY_DETAIL_FIELD_KEY.LEVEL] ?? "";
      this.modified_time = entity[PIM_CATEGORY_DETAIL_FIELD_KEY.MODIFIED_TIME] ?? "";
      this.product_quantity = entity[PIM_CATEGORY_DETAIL_FIELD_KEY.PRODUCT_QUANTITY] ?? "";
      this.parent_name = entity[PIM_CATEGORY_DETAIL_FIELD_KEY.PARENT_NAME] ?? "";
      this.product_type_id = entity[PIM_CATEGORY_DETAIL_FIELD_KEY.PRODUCT_TYPE_ID] ?? "";
      this.product_type_name = entity[PIM_CATEGORY_DETAIL_FIELD_KEY.PRODUCT_TYPE_NAME] ?? "";
    }
  }
};
CategoryItemModel.__transformItemToApiOfCreation = (data) => {
  let formData = new FormData();
  const excluded = [
    PIM_CATEGORY_DETAIL_FIELD_KEY.ID,
    PIM_CATEGORY_DETAIL_FIELD_KEY.CUSTOM_FIELDS,
    PIM_CATEGORY_DETAIL_FIELD_KEY.RELATED_CATEGORIES
  ];
  Object.keys(PIM_CATEGORY_DETAIL_FIELD_KEY).forEach((index) => {
    if (!excluded.includes(PIM_CATEGORY_DETAIL_FIELD_KEY[index]) && data[PIM_CATEGORY_DETAIL_FIELD_KEY[index]]) {
      formData.append(
        PIM_CATEGORY_DETAIL_FIELD_KEY[index],
        data[PIM_CATEGORY_DETAIL_FIELD_KEY[index]]
      );
    }
  });
  if (data[PIM_CATEGORY_DETAIL_FIELD_KEY.CUSTOM_FIELDS] && Object.keys(data[PIM_CATEGORY_DETAIL_FIELD_KEY.CUSTOM_FIELDS]).length) {
    Object.keys(data[PIM_CATEGORY_DETAIL_FIELD_KEY.CUSTOM_FIELDS]).forEach(function(key) {
      if (Array.isArray(data[PIM_CATEGORY_DETAIL_FIELD_KEY.CUSTOM_FIELDS][key])) {
        data[PIM_CATEGORY_DETAIL_FIELD_KEY.CUSTOM_FIELDS][key].map((field, index) => {
          if (typeof field === "object" && field !== null && !Array.isArray(field)) {
            Object.keys(field).forEach(function(fieldKey) {
              return formData.append(
                [PIM_CATEGORY_DETAIL_FIELD_KEY.CUSTOM_FIELDS] + `[${key}][${index}][${fieldKey}]`,
                field[fieldKey]
              );
            });
          } else {
            return formData.append(
              [PIM_CATEGORY_DETAIL_FIELD_KEY.CUSTOM_FIELDS] + "[" + key + "][" + index + "]",
              field
            );
          }
        });
      } else {
        formData.append(
          [PIM_CATEGORY_DETAIL_FIELD_KEY.CUSTOM_FIELDS] + "[" + key + "]",
          data[PIM_CATEGORY_DETAIL_FIELD_KEY.CUSTOM_FIELDS][key]
        );
      }
    });
  }
  if (data[PIM_CATEGORY_DETAIL_FIELD_KEY.RELATED_CATEGORIES] && data[PIM_CATEGORY_DETAIL_FIELD_KEY.RELATED_CATEGORIES].length) {
    data[PIM_CATEGORY_DETAIL_FIELD_KEY.RELATED_CATEGORIES].map((category) => {
      return formData.append(
        PIM_CATEGORY_DETAIL_FIELD_KEY.RELATED_CATEGORIES + "[]",
        category.id
      );
    });
  }
  return formData;
};
CategoryItemModel.__transformItemToApiOfUpdation = (data) => {
  let formData = {};
  const excluded = [
    PIM_CATEGORY_DETAIL_FIELD_KEY.CUSTOM_FIELDS,
    PIM_CATEGORY_DETAIL_FIELD_KEY.RELATED_CATEGORIES
  ];
  Object.keys(PIM_CATEGORY_DETAIL_FIELD_KEY).forEach((index) => {
    if (!excluded.includes(PIM_CATEGORY_DETAIL_FIELD_KEY[index]) && data[PIM_CATEGORY_DETAIL_FIELD_KEY[index]]) {
      formData[PIM_CATEGORY_DETAIL_FIELD_KEY[index]] = data[PIM_CATEGORY_DETAIL_FIELD_KEY[index]];
    }
  });
  if (data[PIM_CATEGORY_DETAIL_FIELD_KEY.CUSTOM_FIELDS] && Object.keys(data[PIM_CATEGORY_DETAIL_FIELD_KEY.CUSTOM_FIELDS]).length) {
    formData[PIM_CATEGORY_DETAIL_FIELD_KEY.CUSTOM_FIELDS] = {};
    Object.keys(data[PIM_CATEGORY_DETAIL_FIELD_KEY.CUSTOM_FIELDS]).forEach(function(key) {
      formData[PIM_CATEGORY_DETAIL_FIELD_KEY.CUSTOM_FIELDS][key] = data[PIM_CATEGORY_DETAIL_FIELD_KEY.CUSTOM_FIELDS][key];
    });
  }
  if (data[PIM_CATEGORY_DETAIL_FIELD_KEY.RELATED_CATEGORIES]) {
    if (data[PIM_CATEGORY_DETAIL_FIELD_KEY.RELATED_CATEGORIES].length) {
      formData[PIM_CATEGORY_DETAIL_FIELD_KEY.RELATED_CATEGORIES] = data[PIM_CATEGORY_DETAIL_FIELD_KEY.RELATED_CATEGORIES].map((category) => {
        return category.id;
      });
    } else {
      formData[PIM_CATEGORY_DETAIL_FIELD_KEY.RELATED_CATEGORIES + "[]"] = "";
    }
  }
  return formData;
};

export {
  CategoryModel,
  CategoryItemModel
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
