// src/components/ButtonNormal/index.tsx
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation } from "react-i18next";
var ButtonNormal = class extends React.Component {
  render() {
    const { t, iconStart, iconEnd, text, onClick, className, disabled } = this.props;
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      "button",
      {
        type: "button",
        className: `btn ${className ?? ""}`,
        onClick,
        disabled
      },
      iconStart && /* @__PURE__ */ React.createElement("i", { className: "me-2" }, /* @__PURE__ */ React.createElement(FontAwesomeIcon, { icon: iconStart })),
      /* @__PURE__ */ React.createElement("span", null, t(text)),
      iconEnd && /* @__PURE__ */ React.createElement("i", { className: "ms-2" }, /* @__PURE__ */ React.createElement(FontAwesomeIcon, { icon: iconEnd }))
    ));
  }
};
var B = withTranslation()(ButtonNormal);

export {
  B
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
