// src/Constant/ProjectConstant.ts
var ESI_PROJECT_FIELD_KEY = {
  ID: "id",
  TITLE: "title",
  START_DATE: "start_date",
  END_DATE: "end_date",
  SHORT_DESCRIPTION: "short_description",
  LOGO: "logo",
  PROJECT_LEAD: "project_lead",
  STATUS: "status"
};
var ESI_PROJECT_API_RESPONSE_FIELD_KEY = {
  ID: "id",
  TITLE: "title",
  START_DATE: "start_date",
  END_DATE: "end_date",
  SHORT_DESCRIPTION: "short_description",
  LOGO: "logo",
  PROJECT_LEAD: "project_lead",
  STATUS: "status"
};

export {
  ESI_PROJECT_FIELD_KEY,
  ESI_PROJECT_API_RESPONSE_FIELD_KEY
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
