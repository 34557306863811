import {
  PIM_TAG_DETAIL_FIELD_KEY
} from "./chunk-RS5ET3Q3.js";
import {
  BaseItemModel_default
} from "./chunk-UPAELC5I.js";
import {
  BaseModel_default
} from "./chunk-ITJMNR7M.js";

// src/Pim/PimTag/PimTagModel.ts
var TagModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    if (entities) {
      this.items = entities._embedded.item.map((element) => {
        return new TagItemModel(element);
      });
    }
  }
};
var TagItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.id = null;
    this.title = null;
    this.alias = null;
    this.published = 0;
    this.featured = 0;
    this.parent_id = null;
    this.organisation_id = null;
    this.custom_fields = null;
    this.created_user_name = null;
    this.created_time = null;
    this.publish_up = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [PIM_TAG_DETAIL_FIELD_KEY.ID]: this.id,
        [PIM_TAG_DETAIL_FIELD_KEY.TITLE]: this.title,
        [PIM_TAG_DETAIL_FIELD_KEY.ALIAS]: this.alias,
        [PIM_TAG_DETAIL_FIELD_KEY.PUBLISHED]: this.published,
        [PIM_TAG_DETAIL_FIELD_KEY.FEATURED]: this.featured,
        [PIM_TAG_DETAIL_FIELD_KEY.PARENT_ID]: this.parent_id,
        [PIM_TAG_DETAIL_FIELD_KEY.CUSTOM_FIELDS]: this.custom_fields,
        [PIM_TAG_DETAIL_FIELD_KEY.CREATED_USER_NAME]: this.created_user_name,
        [PIM_TAG_DETAIL_FIELD_KEY.PUBLISH_UP]: this.publish_up
      };
    };
    if (entity) {
      this.id = entity[PIM_TAG_DETAIL_FIELD_KEY.ID] ?? "";
      this.title = entity[PIM_TAG_DETAIL_FIELD_KEY.TITLE] ?? "";
      this.alias = entity[PIM_TAG_DETAIL_FIELD_KEY.ALIAS] ?? "";
      this.published = entity[PIM_TAG_DETAIL_FIELD_KEY.PUBLISHED] ?? 0;
      this.featured = entity[PIM_TAG_DETAIL_FIELD_KEY.FEATURED]?.toString() ?? "0";
      this.parent_id = entity[PIM_TAG_DETAIL_FIELD_KEY.PARENT_ID] ?? 0;
      this.organisation_id = entity[PIM_TAG_DETAIL_FIELD_KEY.ORGANISATION_ID] ?? 0;
      this.custom_fields = entity[PIM_TAG_DETAIL_FIELD_KEY.CUSTOM_FIELDS] ?? "";
      this.created_user_name = entity[PIM_TAG_DETAIL_FIELD_KEY.CREATED_USER_NAME] ?? "";
      this.created_time = entity[PIM_TAG_DETAIL_FIELD_KEY.CREATED_TIME] ?? "";
      this.publish_up = entity[PIM_TAG_DETAIL_FIELD_KEY.PUBLISH_UP] ?? "";
    }
  }
};
TagItemModel.__transformItemToApiOfCreation = (data) => {
  let formData = new FormData();
  const excluded = [PIM_TAG_DETAIL_FIELD_KEY.ID];
  Object.keys(PIM_TAG_DETAIL_FIELD_KEY).forEach((index) => {
    if (!excluded.includes(index) && data[PIM_TAG_DETAIL_FIELD_KEY[index]]) {
      formData.append(PIM_TAG_DETAIL_FIELD_KEY[index], data[PIM_TAG_DETAIL_FIELD_KEY[index]]);
    }
  });
  formData.append(PIM_TAG_DETAIL_FIELD_KEY.ID, data[PIM_TAG_DETAIL_FIELD_KEY.ID] ?? 0);
  return formData;
};
TagItemModel.__transformItemToApiOfUpdation = (data) => {
  let formData = {};
  const excluded = [];
  Object.keys(PIM_TAG_DETAIL_FIELD_KEY).forEach((index) => {
    if (!excluded.includes(index) && data[PIM_TAG_DETAIL_FIELD_KEY[index]]) {
      formData[PIM_TAG_DETAIL_FIELD_KEY[index]] = data[PIM_TAG_DETAIL_FIELD_KEY[index]];
    }
  });
  return formData;
};

export {
  TagModel,
  TagItemModel
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
