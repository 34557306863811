// src/Constant/PersonaTemplateConstant.ts
var PERSONA_TEMPLATE_FIELD_KEY = {
  ID: "id",
  NAME: "title",
  CHANNEL: "channels",
  DG_NAME: "name",
  AGE: "age",
  GENDER: "gender",
  LOCATION: "location",
  TOOLS: "tools",
  JOB_TITLE: "job_title",
  WEBSITE: "website",
  SECTOR: "sector",
  VENDOR_RESEARCH: "vendor_research",
  INTEREST: "interests",
  GOALS: "goals",
  MARITAL_STATUS: "marital_status",
  CHALLENGES: "challenges",
  PAINT_POINT: "paint_point",
  AVATAR: "avatar",
  BIO: "bio",
  THUMBNAIL_URL: "thumbnail_url"
};
var PERSONA_TEMPLATE_RESPONSE_FIELD_KEY = {
  ID: "id",
  NAME: "title",
  CHANNEL: "channels",
  DG_NAME: "name",
  AGE: "age",
  GENDER: "gender",
  LOCATION: "location",
  TOOLS: "tools",
  JOB_TITLE: "job_title",
  WEBSITE: "website",
  SECTOR: "sector",
  VENDOR_RESEARCH: "vendor_research",
  INTEREST: "interests",
  GOALS: "goals",
  MARITAL_STATUS: "marital_status",
  CHALLENGES: "challenges",
  PAINT_POINT: "paint_point",
  AVATAR: "avatar",
  BIO: "bio",
  THUMBNAIL_URL: "thumbnail_url"
};

export {
  PERSONA_TEMPLATE_FIELD_KEY,
  PERSONA_TEMPLATE_RESPONSE_FIELD_KEY
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
