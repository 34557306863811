import {
  Utils_default
} from "./chunk-TLJMJLS5.js";

// src/Abstract/BaseItemModel.ts
var BaseItemModel = class {
  constructor(entity) {
    this.id = 0;
    this.title = "";
    this.createdBy = null;
    this.modifiedBy = null;
    this.createdDate = null;
    this.modifiedDate = null;
    this.alias = "";
    this.featured = 0;
    this.customfieldValues = null;
    this.getId = () => {
      return this.id;
    };
    this.getTitle = () => {
      return this.title;
    };
    this.getCreatedBy = () => {
      return this.createdBy;
    };
    this.getModifiedBy = () => {
      return this.modifiedBy;
    };
    this.getCreatedDate = () => {
      return Utils_default.formatDatetimeByLocale(this.createdDate);
    };
    this.getModifiedDate = () => {
      return Utils_default.formatDatetimeByLocale(this.modifiedDate);
    };
    this.getAlias = () => {
      return this.alias;
    };
    this.getFeatured = () => {
      return this.featured;
    };
    this.getCustomfieldValues = () => {
      return this.customfieldValues;
    };
    this.setTitle = (title) => this.title = title;
    this.baseToJSON = () => {
      return {
        created_date: this.createdDate,
        modified_date: Utils_default.formatDatetimeByLocale(this.modifiedDate),
        modified_date_org: this.modifiedDate,
        created_by: this.createdBy,
        modified_by: this.modifiedBy
      };
    };
    if (entity) {
      this.id = entity.id ?? 0;
      this.title = entity.title ?? "";
      this.createdBy = entity.created_by ?? null;
      this.modifiedBy = entity.modified_by ?? null;
      this.createdDate = entity.created_date ?? null;
      this.modifiedDate = entity.modified_date ?? null;
      this.alias = entity.alias ?? "";
      this.featured = entity.featured ?? 0;
      this.customfieldValues = entity.customfieldValues ?? null;
    }
  }
};
var BaseItemModel_default = BaseItemModel;

export {
  BaseItemModel_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
