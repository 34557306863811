import {
  customStyles_default
} from "./chunk-5IAIJTV5.js";

// src/components/Select/index.tsx
import React from "react";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import { withTranslation } from "react-i18next";
var AesirXSelect = class extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      isBorder,
      plColor,
      async,
      placeholder,
      arrowColor,
      isDisabled,
      size,
      minWidth,
      t,
      isClearable = false,
      isLanguageSelect = false
    } = this.props;
    const styles = customStyles_default(
      isBorder,
      plColor,
      arrowColor,
      isDisabled,
      size,
      minWidth,
      isLanguageSelect
    );
    if (async) {
      return /* @__PURE__ */ React.createElement(AsyncSelect, { ...this.props, placeholder: placeholder ?? t("txt_select"), styles });
    }
    const { ValueContainer, Placeholder, IndicatorSeparator } = components;
    const CustomValueContainer = ({ children, ...props }) => {
      return /* @__PURE__ */ React.createElement(
        ValueContainer,
        {
          ...props,
          className: `valueContainerCustom ps-16 py-2 ${isLanguageSelect ? "" : "pe-14"}`
        },
        !props.hasValue && /* @__PURE__ */ React.createElement(Placeholder, { ...props, isFocused: props.isFocused }, props.selectProps.placeholder),
        React.Children.map(
          children,
          (child) => child && child.type !== Placeholder ? child : null
        )
      );
    };
    const groupStyles = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    };
    const groupBadgeStyles = {
      backgroundColor: "#EBECF0",
      borderRadius: "2em",
      color: "#172B4D",
      display: "inline-block",
      fontSize: 12,
      fontWeight: "normal",
      lineHeight: "1",
      minWidth: 1,
      padding: "0.16666666666667em 0.5em",
      textAlign: "center"
    };
    const formatGroupLabel = (data) => /* @__PURE__ */ React.createElement("div", { style: groupStyles }, /* @__PURE__ */ React.createElement("span", null, data.label), /* @__PURE__ */ React.createElement("span", { style: groupBadgeStyles }, data.options.length));
    return /* @__PURE__ */ React.createElement(
      Select,
      {
        ...this.props,
        components: {
          ValueContainer: CustomValueContainer,
          IndicatorSeparator: isClearable && this.props?.defaultValue ? IndicatorSeparator : () => null
        },
        isClearable,
        placeholder: placeholder ?? t("txt_select"),
        styles,
        formatGroupLabel
      }
    );
  }
};
var AesirXSelectExtended = withTranslation()(AesirXSelect);

export {
  AesirXSelectExtended
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
