import {
  PermissionDetailViewModel_default
} from "./chunk-EG5CYQTF.js";
import {
  PermissionListViewModel_default
} from "./chunk-O46MNHMO.js";

// src/pages/Permission/PermissionViewModel/PermissionViewModel.ts
var PermissionViewModel = class {
  constructor(permissionStore) {
    this.permissionDetailViewModel = {};
    this.permissionListViewModel = {};
    this.getPermissionDetailViewModel = () => this.permissionDetailViewModel;
    this.getPermissionListViewModel = () => this.permissionListViewModel;
    if (permissionStore) {
      this.permissionDetailViewModel = new PermissionDetailViewModel_default(permissionStore);
      this.permissionListViewModel = new PermissionListViewModel_default(permissionStore);
    }
  }
};
var PermissionViewModel_default = PermissionViewModel;

export {
  PermissionViewModel_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
