// src/components/GlobalFilter/index.tsx
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { useAsyncDebounce } from "react-table";
var GlobalFilter = ({
  setGlobalFilter,
  searchText,
  filter,
  setFilter
}) => {
  const [, setValue] = React.useState("");
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter({ keyword: value });
  }, 200);
  return /* @__PURE__ */ React.createElement("span", { className: " d-flex align-items-center position-relative pe-3 w-400" }, /* @__PURE__ */ React.createElement(
    "input",
    {
      onChange: (e) => {
        if (setFilter) {
          setFilter(e.target.value);
        }
        setValue(e.target.value);
        onChange(e.target.value);
        filter.searchText = e.target.value;
      },
      value: filter.searchText,
      placeholder: searchText,
      className: "form-control border-end-0 pe-2 border-0 pe-4"
    }
  ), /* @__PURE__ */ React.createElement("i", { className: "text-green position-absolute top-0 bottom-0 end-0 pe-4 d-flex align-items-center" }, /* @__PURE__ */ React.createElement(FontAwesomeIcon, { icon: faSearch })));
};

export {
  GlobalFilter
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
