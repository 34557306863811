// src/components/Form/FormRadio/index.tsx
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
var FormRadio = ({ field }) => {
  const [selectedValue, setSelectedValue] = useState(field.getValueSelected?.value);
  useEffect(() => {
    if (field.getValueSelected?.value) {
      setSelectedValue(field.getValueSelected?.value);
    }
  }, [field.getValueSelected?.value]);
  const handleChange = (data) => {
    setSelectedValue(data.target?.value);
    field.handleChange(data);
  };
  return /* @__PURE__ */ React.createElement("div", { className: "d-flex align-items-center w-100 flex-wrap" }, field.getDataSelectOptions?.map(
    (option, key) => option.label && /* @__PURE__ */ React.createElement(
      Form.Check,
      {
        key: field.key + key,
        className: `mb-0 ${option.className}`,
        inline: true,
        label: option.label,
        value: option.value,
        name: field.key,
        type: "radio",
        id: `inline-radio-${field.key}-${option.value}`,
        onChange: handleChange,
        onBlur: field?.blurred,
        checked: selectedValue === option.value
      }
    )
  ), field.isClearable && /* @__PURE__ */ React.createElement("div", { className: "d-flex align-items-center w-100 mt-2" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      variant: "danger",
      className: "mx-1 py-1",
      onClick: () => {
        handleChange("");
      }
    },
    "Clear All"
  )));
};

export {
  FormRadio
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
