// src/components/Spinner/Spinner.tsx
import React from "react";
import MoonLoader from "react-spinners/MoonLoader";
function Spinner({ className = "", size }) {
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: `${className} d-flex justify-content-center align-items-center position-absolute top-50 start-50 translate-middle zindex-1 w-100 h-100`
    },
    /* @__PURE__ */ React.createElement(MoonLoader, { color: `#1ab394`, size: `${size ?? "60px"}` })
  );
}

export {
  Spinner
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
