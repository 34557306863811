import {
  A
} from "./chunk-TSTKR7BL.js";
import {
  SVGComponent
} from "./chunk-C5UZGLWY.js";

// src/components/Form/FormYoutube/index.tsx
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";

// src/components/Form/FormYoutube/index.scss
var css = `/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */`;
document.head.appendChild(document.createElement("style")).appendChild(document.createTextNode(css));

// src/components/Form/FormYoutube/index.tsx
import { withTranslation } from "react-i18next";
var FormYoutube = ({ field, ...props }) => {
  const { t } = props;
  const [listOptions, setListOptions] = useState(
    field?.getValueSelected?.length ? field?.getValueSelected : [""]
  );
  useEffect(() => {
    field?.getValueSelected && setListOptions(field?.getValueSelected);
  }, []);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, listOptions?.map((option, index) => {
    return /* @__PURE__ */ React.createElement("div", { key: `${index}`, className: "position-relative" }, /* @__PURE__ */ React.createElement(Row, { className: "mb-16 gx-24" }, /* @__PURE__ */ React.createElement(Col, { xs: 12 }, /* @__PURE__ */ React.createElement("div", { className: "d-flex" }, /* @__PURE__ */ React.createElement("div", { className: "w-100" }, /* @__PURE__ */ React.createElement(
      A,
      {
        key: index + option?.value,
        field: {
          key: index + option?.value,
          getValueSelected: option?.value,
          classNameInput: "fs-14",
          placeholder: "Value",
          handleChange: (data) => {
            listOptions[index] = { value: data.target.value };
            setListOptions(listOptions);
            field.handleChange(listOptions);
          }
        }
      }
    )), /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "border-1 rounded-1 d-flex align-items-center justify-content-center ms-24 px-8px cursor-pointer",
        onClick: () => {
          const array = [...listOptions];
          array.splice(index, 1);
          setListOptions(array);
          field.handleChange(array);
        }
      },
      /* @__PURE__ */ React.createElement(SVGComponent, { url: "/assets/images/cancel.svg", className: "bg-danger" })
    )))));
  }), (field?.isMulti || !field?.isMulti && listOptions?.length < 1) && /* @__PURE__ */ React.createElement(
    Button,
    {
      variant: `light`,
      className: `px-24 py-10 fw-semibold d-flex align-items-center rounded-1 border border-success border-da-1 mt-16`,
      onClick: () => {
        setListOptions([...listOptions, ""]);
      }
    },
    /* @__PURE__ */ React.createElement(SVGComponent, { url: "/assets/images/plus.svg", className: `me-15` }),
    t("txt_add")
  ));
};
var A2 = withTranslation()(FormYoutube);

export {
  A2 as A
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
