// src/Constant/OrganisationChannelConstant.ts
var ESI_ORGANISATION_CHANNEL_FIELD_KEY = {
  ID: "id",
  TITLE: "title",
  HANDLE: "handle",
  ORGANISATION: "organisation",
  CHANNEL: "channel",
  CHANNEL_NAME: "channel_name"
};
var ESI_ORGANISATION_CHANNEL_API_RESPONSE_FIELD_KEY = {
  ID: "id",
  TITLE: "title",
  HANDLE: "handle",
  ORGANISATION: "organisation",
  CHANNEL: "channel",
  CHANNEL_NAME: "channel_name"
};

export {
  ESI_ORGANISATION_CHANNEL_FIELD_KEY,
  ESI_ORGANISATION_CHANNEL_API_RESPONSE_FIELD_KEY
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
