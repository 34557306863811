import {
  ESI_CONTENT_API_RESPONSE_FIELD_KEY,
  ESI_CONTENT_FIELD_KEY
} from "./chunk-DT6JAPJX.js";
import {
  BaseItemModel_default
} from "./chunk-UPAELC5I.js";
import {
  BaseModel_default
} from "./chunk-ITJMNR7M.js";

// src/Content/ContentModel.ts
var ContentModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    if (entities) {
      this.unTransformedItems = entities._embedded.item;
      this.items = entities._embedded.item.map((element) => {
        return new ContentItemModel(element);
      });
      this.items.pagination = this.getPagination();
    }
  }
};
var ContentFilterModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.getPagination = () => {
      const pagination = this.pureEntities.result.pagination;
      return {
        page: pagination.page ? parseInt(pagination.page) : 1,
        pageLimit: pagination.pageLimit ? parseInt(pagination.pageLimit) : 0,
        limitstart: pagination.limitStart ? parseInt(pagination.limitStart) : 0,
        totalItems: pagination.totalItems ? parseInt(pagination.totalItems) : 0,
        totalPages: pagination.totalPage ? parseInt(pagination.totalPage) : 0
      };
    };
    if (entities) {
      this.unTransformedItems = entities.result.data;
      this.items = entities.result.data.map((element) => {
        return new ContentItemModel(element);
      });
    }
  }
};
var ContentItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.headline = "";
    this.description = "";
    this.theme = 0;
    this.customizeScheduleForEachChannel = 0;
    this.publishDate = "0000-00-00";
    this.publishRegularly = 0;
    this.scheduleChannel = "";
    this.dateFrom = "0000-00-00 00:00:00";
    this.dateUntil = "0000-00-00 00:00:00";
    this.time = "0000-00-00 00:00:00";
    this.persona = 0;
    this.campaign = 0;
    this.status = 1;
    this.attachments = "";
    this.contentToContentThemes = "";
    this.publishingType = "";
    this.getHeadLine = () => {
      return this.headline;
    };
    this.getDescription = () => {
      return this.description;
    };
    this.getTheme = () => {
      return this.theme;
    };
    this.getCustomizeScheduleForEachChannel = () => {
      return this.customizeScheduleForEachChannel;
    };
    this.getPublishDate = () => {
      return this.publishDate;
    };
    this.getPublishRegularly = () => {
      return this.publishRegularly;
    };
    this.getScheduleChannel = () => {
      return this.scheduleChannel;
    };
    this.getDateFrom = () => {
      return this.dateFrom;
    };
    this.getDateUntil = () => {
      return this.dateUntil;
    };
    this.getTime = () => {
      return this.time;
    };
    this.getPersona = () => {
      return this.persona;
    };
    this.getCampaign = () => {
      return this.campaign;
    };
    this.getStatus = () => {
      return this.status;
    };
    this.getAttachments = () => {
      return this.attachments;
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [ESI_CONTENT_FIELD_KEY.ID]: this.id,
        [ESI_CONTENT_FIELD_KEY.HEADLINE]: this.headline,
        [ESI_CONTENT_FIELD_KEY.CONTENT_DATA]: this.description,
        [ESI_CONTENT_FIELD_KEY.PERSONA]: this.persona,
        [ESI_CONTENT_FIELD_KEY.CAMPAIGN]: this.campaign,
        [ESI_CONTENT_FIELD_KEY.STATUS]: this.status,
        [ESI_CONTENT_FIELD_KEY.PUBLISH_TYPE]: this.publishingType
      };
    };
    if (entity) {
      this.headline = entity[ESI_CONTENT_API_RESPONSE_FIELD_KEY.HEADLINE] ?? "";
      this.description = entity[ESI_CONTENT_API_RESPONSE_FIELD_KEY.CONTENT_DATA] ?? "";
      this.persona = entity[ESI_CONTENT_API_RESPONSE_FIELD_KEY.PERSONA] ?? 0;
      this.campaign = entity[ESI_CONTENT_API_RESPONSE_FIELD_KEY.CAMPAIGN] ?? 0;
      this.status = entity[ESI_CONTENT_API_RESPONSE_FIELD_KEY.STATUS] ?? "";
      this.publishingType = entity[ESI_CONTENT_API_RESPONSE_FIELD_KEY.PUBLISH_TYPE] ?? "";
    }
  }
};
ContentItemModel.__transformItemToApiOfCreation = (data) => {
  return {
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.HEADLINE]: data[ESI_CONTENT_FIELD_KEY.CONTENT_TO_POST][ESI_CONTENT_FIELD_KEY.HEADLINE] ?? "",
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.CHANNEL_DESCRIPTIONS]: JSON.stringify(data[ESI_CONTENT_FIELD_KEY.CHANNEL_DESCRIPTIONS]) ?? "",
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.THEME]: data[ESI_CONTENT_FIELD_KEY.THEME] ?? "",
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.CUSTOMIZE_SCHEDULE_FOR_EACH_CHANNEL]: data[ESI_CONTENT_FIELD_KEY.CUSTOMIZE_SCHEDULE_FOR_EACH_CHANNEL] ?? "",
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.PUBLISH_DATE]: data[ESI_CONTENT_FIELD_KEY.PUBLISH_DATE] ?? "",
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.PUBLISH_REGULARLY]: data[ESI_CONTENT_FIELD_KEY.PUBLISH_REGULARLY] ?? "",
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.SCHEDULE_CHANNEL]: data[ESI_CONTENT_FIELD_KEY.SCHEDULE_CHANNEL] ?? "",
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.DATE_FROM]: data[ESI_CONTENT_FIELD_KEY.DATE_FROM] ?? "",
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.DATE_UNTIL]: data[ESI_CONTENT_FIELD_KEY.DATE_UNTIL] ?? "",
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.TIME]: data[ESI_CONTENT_FIELD_KEY.TIME] ?? "",
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.PERSONA]: data[ESI_CONTENT_FIELD_KEY.PERSONA] ?? "",
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.CAMPAIGN]: data[ESI_CONTENT_FIELD_KEY.CAMPAIGN] ?? "",
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.CHANNEL_ATTACHMENTS]: data[ESI_CONTENT_FIELD_KEY.CHANNEL_ATTACHMENTS] ?? "",
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.STATUS]: data[ESI_CONTENT_FIELD_KEY.STATUS] ?? "",
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.CONTENT_THEMES]: JSON.stringify(data[ESI_CONTENT_FIELD_KEY.CONTENT_THEMES]) ?? "",
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.CANVA_DATA]: JSON.stringify({
      [ESI_CONTENT_API_RESPONSE_FIELD_KEY.CANVA_DESIGN_ID]: data[ESI_CONTENT_FIELD_KEY.CONTENT_TO_POST][ESI_CONTENT_FIELD_KEY.CANVA_DESIGN_ID] ?? "",
      [ESI_CONTENT_API_RESPONSE_FIELD_KEY.CANVA_EXPORT_URL]: data[ESI_CONTENT_FIELD_KEY.CONTENT_TO_POST][ESI_CONTENT_FIELD_KEY.CANVA_EXPORTED_URL] ?? ""
    }),
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.PROJECT]: data[ESI_CONTENT_FIELD_KEY.PROJECT] ?? ""
  };
};
ContentItemModel.__transformItemToApiOfUpdation = (data) => {
  return {
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.ID]: data[ESI_CONTENT_FIELD_KEY.ID],
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.HEADLINE]: data[ESI_CONTENT_FIELD_KEY.HEADLINE],
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.CHANNEL_DESCRIPTIONS]: JSON.stringify(data[ESI_CONTENT_FIELD_KEY.CHANNEL_DESCRIPTIONS]) ?? "",
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.THEME]: data[ESI_CONTENT_FIELD_KEY.THEME],
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.CUSTOMIZE_SCHEDULE_FOR_EACH_CHANNEL]: data[ESI_CONTENT_FIELD_KEY.CUSTOMIZE_SCHEDULE_FOR_EACH_CHANNEL],
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.PUBLISH_DATE]: data[ESI_CONTENT_FIELD_KEY.PUBLISH_DATE],
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.PUBLISH_REGULARLY]: data[ESI_CONTENT_FIELD_KEY.PUBLISH_REGULARLY],
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.SCHEDULE_CHANNEL]: data[ESI_CONTENT_FIELD_KEY.SCHEDULE_CHANNEL],
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.DATE_FROM]: data[ESI_CONTENT_FIELD_KEY.DATE_FROM],
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.DATE_UNTIL]: data[ESI_CONTENT_FIELD_KEY.DATE_UNTIL],
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.TIME]: data[ESI_CONTENT_FIELD_KEY.TIME],
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.PERSONA]: data[ESI_CONTENT_FIELD_KEY.PERSONA],
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.CAMPAIGN]: data[ESI_CONTENT_FIELD_KEY.CAMPAIGN],
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.CHANNEL_ATTACHMENTS]: data[ESI_CONTENT_FIELD_KEY.CHANNEL_ATTACHMENTS],
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.STATUS]: data[ESI_CONTENT_FIELD_KEY.STATUS],
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.CONTENT_THEMES]: JSON.stringify(data[ESI_CONTENT_FIELD_KEY.CONTENT_THEMES]) ?? "",
    [ESI_CONTENT_API_RESPONSE_FIELD_KEY.CANVA_DATA]: JSON.stringify({
      [ESI_CONTENT_API_RESPONSE_FIELD_KEY.CANVA_DESIGN_ID]: data[ESI_CONTENT_FIELD_KEY.CONTENT_TO_POST][ESI_CONTENT_FIELD_KEY.CANVA_DESIGN_ID] ?? "",
      [ESI_CONTENT_API_RESPONSE_FIELD_KEY.CANVA_EXPORT_URL]: data[ESI_CONTENT_FIELD_KEY.CONTENT_TO_POST][ESI_CONTENT_FIELD_KEY.CANVA_EXPORTED_URL] ?? ""
    })
  };
};
var ContentsByCampaignModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    if (entities) {
      this.unTransformedItems = entities.result;
      this.items = entities.result.map((element) => {
        return new ContentItemModel(element);
      });
    }
  }
};

export {
  ContentModel,
  ContentFilterModel,
  ContentItemModel,
  ContentsByCampaignModel
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
