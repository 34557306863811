// src/components/Form/FormLabel/index.tsx
import React from "react";
import { Form } from "react-bootstrap";
var Label = ({ text, required }) => {
  return /* @__PURE__ */ React.createElement(Form.Label, { className: "mb-3 w-100 text-body" }, text, required && /* @__PURE__ */ React.createElement("span", { className: "text-red-1" }, "*"));
};

export {
  Label
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
