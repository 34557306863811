// src/components/ButtonCopy.tsx
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons/faCopy";
import { useState } from "react";
function ButtonCopy({ content, className, text, isReplaceClass }) {
  const [copy, setCopy] = useState(false);
  const handleCopy = (content2) => {
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1500);
    navigator.clipboard.writeText(content2);
  };
  const customClass = isReplaceClass ? className : `rounded-1 fs-8 lh-base font-opensans fw-bold text-body cursor-copy ${className}`;
  return /* @__PURE__ */ React.createElement("button", { onClick: () => handleCopy(content), disabled: copy, className: customClass }, !copy ? /* @__PURE__ */ React.createElement(React.Fragment, null, text, " ", /* @__PURE__ */ React.createElement(FontAwesomeIcon, { icon: faCopy, width: 16, height: 16 })) : "COPIED!");
}
var ButtonCopy_default = ButtonCopy;

export {
  ButtonCopy_default
};
