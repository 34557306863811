import {
  Instance_default
} from "./chunk-GZ3JSDB6.js";
import {
  BaseRoute_default
} from "./chunk-Q7RR6V45.js";

// src/Project/ProjectRoute.ts
var ProjectRoute = class extends BaseRoute_default {
  constructor() {
    super(...arguments);
    this.getProjetItemRequest = (projectId) => Instance_default.get(
      this.createRequestURL({
        option: "project",
        id: projectId
      })
    );
    this.getProjectsRequest = (page = 1, limit = 20) => Instance_default.get(
      this.createRequestURL({
        option: "project",
        "list[limitstart]": (page - 1) * limit,
        "list[limit]": limit
      })
    );
    this.searchProjectsRequest = (dataFilter, page = 1, limit = 20, sort) => {
      if (sort.ordering) {
        return Instance_default.get(
          this.createRequestURL({
            option: "project",
            limitStart: (page - 1) * limit,
            limit,
            "list[ordering]": sort.ordering,
            "list[direction]": sort.direction
          })
        );
      }
      return Instance_default.get(
        this.createRequestURL({
          option: "project",
          task: "filterProject",
          limitStart: (page - 1) * limit,
          limit,
          ...dataFilter
        })
      );
    };
    /**
     *
     * @param data
     */
    this.createProjectRequest = (data) => Instance_default.post(
      this.createRequestURL({
        option: "project"
      }),
      data
    );
    /**
     *
     * @param data
     */
    this.updateProjectRequest = (data) => Instance_default.put(
      this.createRequestURL({
        option: "project"
      }),
      data
    );
    /**
     *
     * @param projectId
     */
    this.deleteProjectRequest = (projectId) => {
      const ids = projectId.split(",");
      return Instance_default.delete(
        this.createRequestURL({
          option: "project",
          "ids[]": ids
        })
      );
    };
    this.getProjectMasterDataRequest = () => {
      return Instance_default.get(
        this.createRequestURL({
          option: "project",
          task: "getMasterData"
        })
      );
    };
  }
};
var ProjectRoute_default = ProjectRoute;

export {
  ProjectRoute_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
