import {
  Instance_default
} from "./chunk-GZ3JSDB6.js";
import {
  BaseRoute_default
} from "./chunk-Q7RR6V45.js";

// src/Planning/PlanningRoute.ts
var PlanningRoute = class extends BaseRoute_default {
  constructor() {
    super(...arguments);
    this.searchPlanningRequest = (dataFilter, page = 1, limit = 20) => {
      return Instance_default.get(
        this.createRequestURL({
          option: "planning",
          task: "filterPlanning",
          limitStart: (page - 1) * limit,
          limit,
          ...dataFilter
        })
      );
    };
    /**
     *
     * @param page
     * @param limit
     */
    this.getPlanningListRequest = (page = 1, limit = 20) => Instance_default.get(
      this.createRequestURL({
        option: "planning",
        "list[limitstart]": (page - 1) * limit,
        "list[limit]": limit
      })
    );
  }
};
var PlanningRoute_default = PlanningRoute;

export {
  PlanningRoute_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
