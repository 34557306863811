// src/Constant/PlanningConstant.ts
var PLANNING_FIELD_KEY = {
  ID: "id",
  NAME: "title",
  STARTDATE: "start_date",
  ENDDATE: "end_date",
  BACKGROUND: "background",
  DESC: "description"
};

export {
  PLANNING_FIELD_KEY
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
