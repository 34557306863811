import {
  notify
} from "./chunk-DL5QNKBI.js";
import {
  PAGE_STATUS
} from "./chunk-OSVSI4BW.js";

// src/pages/Roles/RoleViewModel/RoleDetailViewModel.ts
import { ORGANISATION_ROLE_FIELD } from "aesirx-lib";
import { makeAutoObservable, runInAction } from "mobx";
var RoleDetailViewModel = class {
  constructor(roleStore) {
    this.formStatus = PAGE_STATUS.READY;
    this.roleDetailViewModel = { formPropsData: [{}] };
    this.aliasChange = "";
    this.roleList = [];
    this.successResponse = {
      state: true,
      content_id: "",
      filters: {}
    };
    this.setForm = (roleDetailViewModel) => {
      this.roleDetailViewModel = roleDetailViewModel;
    };
    this.initializeData = async () => {
      this.formStatus = PAGE_STATUS.LOADING;
      const data = await this.roleStore.getDetail(
        this.roleDetailViewModel.formPropsData[ORGANISATION_ROLE_FIELD.ID]
      );
      runInAction(() => {
        if (!data?.error) {
          this.onGetRoleSuccessHandler(data?.response);
        } else {
          this.onErrorHandler(data?.response);
        }
      });
    };
    this.create = async () => {
      this.formStatus = PAGE_STATUS.LOADING;
      const data = await this.roleStore.create(this.roleDetailViewModel.formPropsData);
      runInAction(() => {
        if (!data?.error) {
          this.onSuccessHandler(data?.response, "Created successfully");
        } else {
          this.onErrorHandler(data?.response);
        }
      });
      return data;
    };
    this.update = async () => {
      this.formStatus = PAGE_STATUS.LOADING;
      const data = await this.roleStore.update(this.roleDetailViewModel.formPropsData);
      runInAction(() => {
        if (!data?.error) {
          this.onSuccessHandler(data?.response, "Updated successfully");
        } else {
          this.onErrorHandler(data?.response);
        }
      });
      return data;
    };
    this.onErrorHandler = (error) => {
      error._messages[0]?.message ? notify(error._messages[0]?.message, "error") : error.message && notify(error.message, "error");
      this.successResponse.state = false;
      this.successResponse.content_id = error.result;
      this.formStatus = PAGE_STATUS.READY;
    };
    this.onSuccessHandler = (result, message) => {
      if (result && message) {
        notify(message, "success");
      }
      this.formStatus = PAGE_STATUS.READY;
    };
    this.onGetRoleSuccessHandler = (result) => {
      if (result && result[ORGANISATION_ROLE_FIELD.ID]) {
        this.roleDetailViewModel.formPropsData = {
          ...this.roleDetailViewModel.formPropsData,
          ...Object.keys(ORGANISATION_ROLE_FIELD).map((index) => {
            return {
              [ORGANISATION_ROLE_FIELD[index]]: result[ORGANISATION_ROLE_FIELD[index]]
            };
          }).reduce((prev, cur) => ({ ...prev, ...cur }))
        };
        this.formStatus = PAGE_STATUS.READY;
      }
    };
    this.onGetRoleListSuccessHandler = (result) => {
      if (result) {
        this.roleList = result;
      }
      this.formStatus = PAGE_STATUS.READY;
    };
    this.handleFormPropsData = (key, value) => {
      if (key && value !== null) {
        if (typeof value === "object" && value !== null && !Array.isArray(value)) {
          Object.assign(this.roleDetailViewModel.formPropsData[key], value);
        } else {
          this.roleDetailViewModel.formPropsData[key] = value;
        }
      }
    };
    this.handleAliasChange = (value) => {
      this.aliasChange = value;
    };
    makeAutoObservable(this);
    this.roleStore = roleStore;
  }
};
var RoleDetailViewModel_default = RoleDetailViewModel;

export {
  RoleDetailViewModel_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
