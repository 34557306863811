import {
  MemberRoleDetailViewModel_default
} from "./chunk-YSB4OY4Y.js";
import {
  MemberRoleListViewModel_default
} from "./chunk-CQEEWLBT.js";

// src/pages/MemberRole/MemberRoleViewModel/MemberRoleViewModel.ts
var MemberRoleViewModel = class {
  constructor(memberRoleStore) {
    this.memberRoleDetailViewModel = {};
    this.memberRoleListViewModel = {};
    this.getMemberRoleDetailViewModel = () => this.memberRoleDetailViewModel;
    this.getMemberRoleListViewModel = () => this.memberRoleListViewModel;
    if (memberRoleStore) {
      this.memberRoleDetailViewModel = new MemberRoleDetailViewModel_default(memberRoleStore);
      this.memberRoleListViewModel = new MemberRoleListViewModel_default(memberRoleStore);
    }
  }
};
var MemberRoleViewModel_default = MemberRoleViewModel;

export {
  MemberRoleViewModel_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
