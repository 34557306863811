// src/theme/darkmode.ts
var getStoredTheme = () => {
  if (typeof window !== "undefined" && localStorage.getItem("theme")) {
    return localStorage.getItem("theme");
  } else {
    return window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
  }
};
var setStoredTheme = (theme) => typeof window !== "undefined" && localStorage.setItem("theme", theme);
var setTheme = (theme) => {
  if (theme === "dark" && typeof window !== "undefined" && window.matchMedia("(prefers-color-scheme: dark)").matches) {
    document.documentElement.setAttribute("data-bs-theme", "dark");
  } else {
    document.documentElement.setAttribute("data-bs-theme", theme);
  }
};
if (typeof window !== "undefined") {
  (() => {
    "use strict";
    const getPreferredTheme = () => {
      const storedTheme = getStoredTheme();
      if (storedTheme) {
        return storedTheme;
      }
      return window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
    };
    setTheme(getPreferredTheme());
    const showActiveTheme = (theme, focus = false) => {
      const themeSwitcher = document.querySelector("#bd-theme");
      if (!themeSwitcher) {
        return;
      }
      const themeSwitcherText = document.querySelector("#bd-theme-text");
      const activeThemeIcon = document.querySelector(".theme-icon-active use");
      const btnToActive = document.querySelector(`[data-bs-theme-value="${theme}"]`);
      const svgOfActiveBtn = btnToActive?.querySelector("svg use")?.getAttribute("href");
      document.querySelectorAll("[data-bs-theme-value]").forEach((element) => {
        element.classList.remove("active");
        element.setAttribute("aria-pressed", "false");
      });
      btnToActive?.classList.add("active");
      btnToActive?.setAttribute("aria-pressed", "true");
      activeThemeIcon?.setAttribute("href", svgOfActiveBtn ?? "");
      const themeSwitcherLabel = `${themeSwitcherText?.textContent} (${btnToActive instanceof HTMLElement ? btnToActive?.dataset?.bsThemeValue : ""})`;
      themeSwitcher.setAttribute("aria-label", themeSwitcherLabel);
      if (focus) {
        themeSwitcher instanceof HTMLElement && themeSwitcher?.focus();
      }
    };
    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", () => {
      const storedTheme = getStoredTheme();
      if (storedTheme !== "light" && storedTheme !== "dark") {
        setTheme(getPreferredTheme());
      }
    });
    window.addEventListener("DOMContentLoaded", () => {
      showActiveTheme(getPreferredTheme());
      document.querySelectorAll("[data-bs-theme-value]").forEach((toggle) => {
        toggle.addEventListener("click", () => {
          const theme = toggle.getAttribute("data-bs-theme-value");
          setStoredTheme(theme);
          setTheme(theme);
          showActiveTheme(theme, true);
        });
      });
    });
  })();
}

export {
  getStoredTheme,
  setStoredTheme,
  setTheme
};
