// src/layouts/NotFound/index.tsx
import React from "react";
var NotFound = () => {
  return /* @__PURE__ */ React.createElement("div", { className: "container-fluid" }, /* @__PURE__ */ React.createElement("div", { className: "main_content vh-100 main_content_dashboard pd-t-80 d-flex" }, "Not Found"));
};

export {
  NotFound
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
