// src/Utils/Utils.ts
var Utils = class {
};
Utils.formatDatetimeByLocale = (datetime, locale = "en-US") => {
  if (!datetime)
    return "";
  return new Date(datetime).toLocaleDateString(locale);
};
Utils.createFilters = (filters = {}) => {
  let buildFilter = {};
  for (const [key, value] of Object.entries(filters)) {
    if (typeof value === "object") {
      switch (value.type) {
        case "custom_fields":
          buildFilter["filter[" + value.type + "][" + key + "][]"] = value.value;
          break;
        case "filter":
          buildFilter["filter[" + key + "]"] = value.value;
          break;
        default:
          break;
      }
    } else {
      buildFilter[key] = value;
    }
  }
  return buildFilter;
};
var Utils_default = Utils;

export {
  Utils_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
