// src/components/SVGComponent/index.tsx
import React from "react";
function SVGComponent({ url, width, height, color, className }) {
  return /* @__PURE__ */ React.createElement(
    "span",
    {
      className: `icon d-inline-block align-text-bottom ${className}`,
      style: {
        WebkitMaskImage: `url(${url})`,
        WebkitMaskRepeat: "no-repeat",
        WebkitMaskPosition: "center",
        width: width ?? "24px",
        height: height ?? "24px",
        ...color && {
          backgroundColor: color ? `${color}` : "var(--bs-body-color)"
        }
      }
    }
  );
}

export {
  SVGComponent
};
