// src/components/Checkbox/index.tsx
import React from "react";
var Checkbox = class extends React.Component {
  render() {
    const { name, text, checked, onCheckBoxChange, disabled } = this.props;
    return /* @__PURE__ */ React.createElement("div", { className: "form-check" }, /* @__PURE__ */ React.createElement(
      "input",
      {
        name,
        id: `id_${name}`,
        className: "form-check-input cursor-pointer",
        type: "checkbox",
        checked,
        onChange: onCheckBoxChange,
        disabled
      }
    ), /* @__PURE__ */ React.createElement("label", { className: "form-check-label cursor-pointer", htmlFor: `id_${name}` }, text));
  }
};

export {
  Checkbox
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
