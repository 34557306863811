import {
  CRM_LIST_GROUP_DETAIL_FIELD_KEY
} from "./chunk-Q42BW4RB.js";
import {
  BaseItemModel_default
} from "./chunk-UPAELC5I.js";
import {
  BaseModel_default
} from "./chunk-ITJMNR7M.js";

// src/Crm/CrmListGroup/CrmListGroupModel.ts
var ListGroupModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    if (entities) {
      this.items = entities._embedded.item.map((element) => {
        return new ListGroupItemModel(element);
      });
    }
  }
};
var ListGroupItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.id = null;
    this.crm_listgroup_name = null;
    this.crm_list_group_contacts = [];
    this.created_by = null;
    this.created_time = null;
    this.status = null;
    this.modified_by = null;
    this.modified_time = null;
    this.featured = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [CRM_LIST_GROUP_DETAIL_FIELD_KEY.ID]: this.id,
        [CRM_LIST_GROUP_DETAIL_FIELD_KEY.NAME]: this.crm_listgroup_name,
        [CRM_LIST_GROUP_DETAIL_FIELD_KEY.CONTACTS]: this.crm_list_group_contacts,
        [CRM_LIST_GROUP_DETAIL_FIELD_KEY.CREATED_TIME]: this.created_time,
        [CRM_LIST_GROUP_DETAIL_FIELD_KEY.CREATED_BY]: this.created_by,
        [CRM_LIST_GROUP_DETAIL_FIELD_KEY.STATUS]: this.status,
        [CRM_LIST_GROUP_DETAIL_FIELD_KEY.MODIFIED_BY]: this.modified_by,
        [CRM_LIST_GROUP_DETAIL_FIELD_KEY.MODIFIED_TIME]: this.modified_time,
        [CRM_LIST_GROUP_DETAIL_FIELD_KEY.FEATURED]: this.featured
      };
    };
    if (entity) {
      this.id = entity[CRM_LIST_GROUP_DETAIL_FIELD_KEY.ID] ?? "";
      this.crm_listgroup_name = entity[CRM_LIST_GROUP_DETAIL_FIELD_KEY.NAME] ?? "";
      this.crm_list_group_contacts = entity[CRM_LIST_GROUP_DETAIL_FIELD_KEY.CONTACTS] ?? [];
      this.created_time = entity[CRM_LIST_GROUP_DETAIL_FIELD_KEY.CREATED_TIME] ?? "";
      this.created_by = entity[CRM_LIST_GROUP_DETAIL_FIELD_KEY.CREATED_BY] ?? "";
      this.status = entity[CRM_LIST_GROUP_DETAIL_FIELD_KEY.STATUS] ?? "";
      this.modified_by = entity[CRM_LIST_GROUP_DETAIL_FIELD_KEY.MODIFIED_BY] ?? "";
      this.modified_time = entity[CRM_LIST_GROUP_DETAIL_FIELD_KEY.MODIFIED_TIME] ?? "";
      this.featured = entity[CRM_LIST_GROUP_DETAIL_FIELD_KEY.FEATURED] ?? "";
    }
  }
};
ListGroupItemModel.__transformItemToApiOfCreation = (data) => {
  let formData = new FormData();
  const excluded = [CRM_LIST_GROUP_DETAIL_FIELD_KEY.ID, CRM_LIST_GROUP_DETAIL_FIELD_KEY.CONTACTS];
  Object.keys(CRM_LIST_GROUP_DETAIL_FIELD_KEY).forEach((index) => {
    if (!excluded.includes(CRM_LIST_GROUP_DETAIL_FIELD_KEY[index]) && data[CRM_LIST_GROUP_DETAIL_FIELD_KEY[index]]) {
      formData.append(
        CRM_LIST_GROUP_DETAIL_FIELD_KEY[index],
        data[CRM_LIST_GROUP_DETAIL_FIELD_KEY[index]]
      );
    }
  });
  if (data[CRM_LIST_GROUP_DETAIL_FIELD_KEY.CONTACTS] && data[CRM_LIST_GROUP_DETAIL_FIELD_KEY.CONTACTS].length) {
    data[CRM_LIST_GROUP_DETAIL_FIELD_KEY.CONTACTS].map((contact) => {
      return formData.append(CRM_LIST_GROUP_DETAIL_FIELD_KEY.CONTACTS + "[]", contact.id);
    });
  }
  return formData;
};
ListGroupItemModel.__transformItemToApiOfUpdation = (data) => {
  let formData = {};
  const excluded = [CRM_LIST_GROUP_DETAIL_FIELD_KEY.CONTACTS];
  Object.keys(CRM_LIST_GROUP_DETAIL_FIELD_KEY).forEach((index) => {
    if (!excluded.includes(CRM_LIST_GROUP_DETAIL_FIELD_KEY[index]) && data[CRM_LIST_GROUP_DETAIL_FIELD_KEY[index]]) {
      formData[CRM_LIST_GROUP_DETAIL_FIELD_KEY[index]] = data[CRM_LIST_GROUP_DETAIL_FIELD_KEY[index]];
    }
  });
  if (data[CRM_LIST_GROUP_DETAIL_FIELD_KEY.CONTACTS]?.length) {
    formData[CRM_LIST_GROUP_DETAIL_FIELD_KEY.CONTACTS] = data[CRM_LIST_GROUP_DETAIL_FIELD_KEY.CONTACTS].map((contact) => {
      return contact.id;
    });
  } else {
    formData[CRM_LIST_GROUP_DETAIL_FIELD_KEY.CONTACTS + "[]"] = "";
  }
  return formData;
};

export {
  ListGroupModel,
  ListGroupItemModel
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
