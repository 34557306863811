// src/Constant/ContentConstant.ts
var ESI_CONTENT_FIELD_KEY = {
  ID: "id",
  HEADLINE: "headline",
  CHANNEL_DESCRIPTIONS: "channel_descriptions",
  THEME: "theme",
  CUSTOMIZE_SCHEDULE_FOR_EACH_CHANNEL: "customize_schedule_for_each_channel",
  PUBLISH_DATE: "publish_date",
  PUBLISH_REGULARLY: "publish_regularly",
  SCHEDULE_CHANNEL: "schedule_channel",
  DATE_FROM: "date_from",
  DATE_UNTIL: "date_until",
  TIME: "time",
  PERSONA: "personas",
  CAMPAIGN: "campaign",
  STATUS: "status",
  CHANNEL_ATTACHMENTS: "channel_attachments",
  CONTENT_THEMES: "content_themes",
  CONTENT_TO_POST: "content_to_post",
  CANVA_DESIGN_ID: "canva_design_id",
  CANVA_EXPORTED_URL: "canva_exported_url",
  PROJECT: "project",
  CONTENT_DATA: "data",
  PUBLISH_TYPE: "publishing_type"
};
var ESI_CONTENT_API_RESPONSE_FIELD_KEY = {
  ID: "id",
  HEADLINE: "title",
  CHANNEL_DESCRIPTIONS: "channel_descriptions",
  THEME: "theme",
  CUSTOMIZE_SCHEDULE_FOR_EACH_CHANNEL: "customize_schedule_for_each_channel",
  PUBLISH_DATE: "publish_date",
  PUBLISH_REGULARLY: "publish_regularly",
  SCHEDULE_CHANNEL: "schedule_channel",
  DATE_FROM: "date_from",
  DATE_UNTIL: "date_until",
  TIME: "time",
  PERSONA: "personas",
  CAMPAIGN: "campaign",
  STATUS: "status",
  CHANNEL_ATTACHMENTS: "channel_attachments",
  CONTENT_THEMES: "content_themes",
  CANVA_DATA: "canvaData",
  CANVA_DESIGN_ID: "designId",
  CANVA_EXPORT_URL: "exportUrl",
  PROJECT: "project",
  CONTENT_DATA: "data",
  PUBLISH_TYPE: "publishing_type"
};

export {
  ESI_CONTENT_FIELD_KEY,
  ESI_CONTENT_API_RESPONSE_FIELD_KEY
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
