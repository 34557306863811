// src/Constant/InvesterContactConstant.ts
var ESI_INVESTER_CONTACT_FIELD_KEY = {
  ID: "id",
  ORGANIZATION_NAME: "organization_name",
  CONTACT_PERSON: "contact_person",
  EMAIL: "email",
  PHONE_NUMBER: "phone_number"
};
var ESI_INVESTER_CONTACT_API_RESPONSE_FIELD_KEY = {
  ID: "id",
  ORGANIZATION_NAME: "organization_name",
  CONTACT_PERSON: "contact_person",
  EMAIL: "email",
  PHONE_NUMBER: "phone_number"
};

export {
  ESI_INVESTER_CONTACT_FIELD_KEY,
  ESI_INVESTER_CONTACT_API_RESPONSE_FIELD_KEY
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
