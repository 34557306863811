// src/Constant/ContentXConstant.ts
var CONTENTX_CATE_DETAIL_FIELD_KEY = {
  ID: "id",
  NAME: "name",
  TITLE: "title",
  ALIAS: "alias",
  ORGANISATION: "organisation",
  CONTENT_TYPE: "content_type",
  PARENT_CATEGORY: "parent_category",
  DEFAULT_TEMPLATE: "default_template",
  RELATED_CATEGORY: "related_category",
  FEATURED_IMAGE: "featured_image",
  INTRO_TEXT: "intro_text",
  FULL_TEXT: "full_text",
  STATUS: "status",
  CATEGORY_PATH: "category_path",
  ROBOTS: "robots",
  META_LANGUAGE_SETTING: "meta_language_setting",
  META_KEYWORDS: "meta_keywords",
  DESCRIPTION: "description",
  CANONICAL_URL: "canonical_url",
  SEO_PAGE_HEADING: "seo_page_heading",
  SEO_PAGE_TITLE: "seo_page_title",
  META_DATA: "meta_data",
  ENGAGEMENT: "engagement",
  LANGUAGES: "languages",
  TYPE: "type",
  VISITS: "visits",
  CHECK: "check"
};
var CONTENTX_ITEMS_DETAIL_FIELD_KEY = {
  ID: "id",
  NAME: "title",
  INTRO_TEXT: "excerpt",
  CONTENT: "metaverse_content",
  FEATURED_IMAGE: "featured_image",
  TYPE: "type",
  CATEGORY: "categories",
  STATUS: "status",
  ACCESS: "access",
  FEATURED: "featured",
  START_PUBLISH: "created_time",
  AUTHOR: "author",
  ENGAGEMENT: "engagement",
  VISITS: "visits",
  LANGUAGES: "languages"
};
var GENERAL_INFORMATION = {
  NAME: "name",
  ALIAS: "alias",
  ORGANISATION: "organisation",
  CONTENT_TYPE: "content_type",
  PARENT_CATEGORY: "parent_category",
  DEFAULT_TEMPLATE: "default_template",
  CATEGORY: "category",
  TAGS: "tags",
  VERSION_NOTE: "version_note"
};
var CONTENTX_LIST_DETAIL_FIELD_KEY = {
  ID: "id"
};
var CONTENTX_FIELD_DETAIL_FIELD_KEY = {
  ID: "id",
  NAME: "name",
  TYPES: "types"
};
var CONTENTX_FIELD_GR_DETAIL_FIELD_KEY = {
  ID: "id",
  NAME: "name",
  TYPES: "types",
  ALIAS: "alias",
  PARENT_FIELD_ROUP: "parent_field_group",
  DESCRIPTION: "description"
};
var CONTENTX_CONTENT_DETAIL_FIELD_KEY = {
  ID: "id",
  NAME: "name",
  ALIAS: "alias",
  DESCRIPTION: "description",
  PARENT_TYPE: "parent_type",
  ALLOW_FRONTEND: "allow_frontend",
  UPLOAD_NEW_MEDIA: "upload_new_media"
};

export {
  CONTENTX_CATE_DETAIL_FIELD_KEY,
  CONTENTX_ITEMS_DETAIL_FIELD_KEY,
  GENERAL_INFORMATION,
  CONTENTX_LIST_DETAIL_FIELD_KEY,
  CONTENTX_FIELD_DETAIL_FIELD_KEY,
  CONTENTX_FIELD_GR_DETAIL_FIELD_KEY,
  CONTENTX_CONTENT_DETAIL_FIELD_KEY
};
