// src/Constant/ContentThemeConstant.ts
var ESI_CONTENT_THEME_FIELD_KEY = {
  ID: "id",
  DESIGN_ID: "design_id",
  IMAGE: "image"
};
var ESI_CONTENT_THEME_API_RESPONSE_FIELD_KEY = {
  ID: "id",
  DESIGN_ID: "design_id",
  IMAGE: "image"
};

export {
  ESI_CONTENT_THEME_FIELD_KEY,
  ESI_CONTENT_THEME_API_RESPONSE_FIELD_KEY
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
