// src/Abstract/BaseMasterDataItemModel.ts
var BaseMasterDataItemModel = class {
  constructor(entity) {
    this.id = 0;
    this.title = "";
    this.getId = () => {
      return this.id;
    };
    this.getTitle = () => {
      return this.title;
    };
    if (entity) {
      this.id = entity.id ?? 0;
      this.title = entity.title ?? "";
    }
  }
};
var BaseMasterDataItemModel_default = BaseMasterDataItemModel;

export {
  BaseMasterDataItemModel_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
