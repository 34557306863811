import {
  Image
} from "./chunk-2MXWHUGP.js";

// src/components/NoData/index.tsx
import React from "react";

// src/components/NoData/index.scss
var css = `/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
.icon-post {
  background: #cbf8ef;
}

.icon-project {
  background: #ffeadc;
}

.icon-campain {
  background: #fadae3;
}`;
document.head.appendChild(document.createElement("style")).appendChild(document.createTextNode(css));

// src/components/NoData/index.tsx
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
var NoData = ({
  icons,
  text,
  title,
  link,
  linlText,
  isBtn,
  width,
  className,
  iconColor,
  iconBg
}) => {
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: className ? className : "text-center h-100 d-flex flex-column justify-content-center align-items-center"
    },
    /* @__PURE__ */ React.createElement("div", { className: `mb-2 d-inline-block position-relative rounded-circle ${iconBg}` }, /* @__PURE__ */ React.createElement(Image, { className: `${iconColor}`, src: icons, alt: icons })),
    /* @__PURE__ */ React.createElement("h5", null, title),
    text && /* @__PURE__ */ React.createElement("p", { className: `my-2 fs-14 text-body w-100 mx-auto ${width}` }, text),
    isBtn && /* @__PURE__ */ React.createElement(
      Link,
      {
        to: { pathname: link, state: { openModal: true } },
        className: "btn btn-success d-inline-block w-fit"
      },
      /* @__PURE__ */ React.createElement("i", { className: "text-white me-2" }, /* @__PURE__ */ React.createElement(FontAwesomeIcon, { icon: faPlus })),
      linlText
    )
  );
};

export {
  NoData
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
