import {
  ORGANISATION_ROLE_FIELD
} from "./chunk-R43B7PK2.js";
import {
  BaseItemModel_default
} from "./chunk-UPAELC5I.js";
import {
  BaseModel_default
} from "./chunk-ITJMNR7M.js";

// src/Organization/Role/Model.ts
var OrganizationRoleModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    if (entities) {
      this.items = entities._embedded?.item.map((element) => {
        return new OrganizationRoleItemModel(element);
      });
    }
  }
};
var OrganizationRoleItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.id = 0;
    this.role_name = "";
    this.description = "";
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [ORGANISATION_ROLE_FIELD.ID]: this.id,
        [ORGANISATION_ROLE_FIELD.ROLE_NAME]: this.role_name,
        [ORGANISATION_ROLE_FIELD.DESCRIPTION]: this.description
      };
    };
    this.toObject = () => {
      return {};
    };
    if (entity) {
      this.id = entity[ORGANISATION_ROLE_FIELD.ID] ?? 0;
      this.role_name = entity[ORGANISATION_ROLE_FIELD.ROLE_NAME] ?? "";
      this.description = entity[ORGANISATION_ROLE_FIELD.DESCRIPTION] ?? "";
    }
  }
};
OrganizationRoleItemModel.__transformItemToApiOfCreation = (data) => {
  let formData = new FormData();
  const excluded = [ORGANISATION_ROLE_FIELD.ID];
  Object.keys(ORGANISATION_ROLE_FIELD).forEach((index) => {
    if (!excluded.includes(ORGANISATION_ROLE_FIELD[index]) && data[ORGANISATION_ROLE_FIELD[index]]) {
      formData.append(ORGANISATION_ROLE_FIELD[index], data[ORGANISATION_ROLE_FIELD[index]]);
    }
  });
  return formData;
};
OrganizationRoleItemModel.__transformItemToApiOfUpdation = (data) => {
  let formData = {};
  const excluded = [ORGANISATION_ROLE_FIELD.CUSTOM_FIELDS];
  Object.keys(ORGANISATION_ROLE_FIELD).forEach((index) => {
    if (!excluded.includes(ORGANISATION_ROLE_FIELD[index]) && data[ORGANISATION_ROLE_FIELD[index]]) {
      formData[ORGANISATION_ROLE_FIELD[index]] = data[ORGANISATION_ROLE_FIELD[index]];
    }
  });
  return formData;
};

export {
  OrganizationRoleModel,
  OrganizationRoleItemModel
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
