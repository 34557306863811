import {
  PimUtilsRoute_default
} from "./chunk-CUJEUTVM.js";
import {
  PublishStatusModel
} from "./chunk-NS5C7KZ3.js";

// src/Pim/PimUtils/PimUtils.ts
import axios from "axios";
var AesirxPimUtilApiService = class {
  constructor() {
    this.route = null;
    this.getListPublishStatus = async () => {
      try {
        const data = await this.utilsRoute.getListPublishStatus();
        let results = null;
        if (data?.result) {
          results = await Promise.all(
            data.result.map(async (o) => {
              return new PublishStatusModel(o).toJSON();
            })
          );
        }
        return {
          listPublishStatus: results ?? []
        };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.getListContentType = async (filter) => {
      try {
        const data = await this.utilsRoute.getListContentType(filter);
        let results = null;
        if (data?.result) {
          results = await Promise.all(
            data.result.map(async (o) => {
              return new PublishStatusModel(o).toJSON();
            })
          );
        }
        return {
          listContentType: results ?? []
        };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.getListFieldType = async () => {
      try {
        const data = await this.utilsRoute.getListFieldType();
        let results = null;
        if (data?.result) {
          results = await Promise.all(
            data.result.map(async (o) => {
              return new PublishStatusModel(o).toJSON();
            })
          );
        }
        return {
          listFieldType: results ?? []
        };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.utilsRoute = new PimUtilsRoute_default();
  }
};

export {
  AesirxPimUtilApiService
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
