// src/Utils/Storage.ts
import secureLocalStorage from "react-secure-storage";
var Storage = class {
  static setItem(key, value) {
    secureLocalStorage.setItem(key, value);
  }
  static getItem(key) {
    return secureLocalStorage.getItem(key);
  }
  static removeItem(key) {
    secureLocalStorage.getItem(key);
  }
};

export {
  Storage
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
