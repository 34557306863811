// src/Constant/Format.ts
var FORMAT_DATE = "dd/MM/yyyy";
var FORMAT_TIME = "HH:mm";

export {
  FORMAT_DATE,
  FORMAT_TIME
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
