import {
  Instance_default
} from "./chunk-GZ3JSDB6.js";
import {
  InstanceServiceApi_default
} from "./chunk-LQHYK7HG.js";
import {
  BaseRoute_default
} from "./chunk-Q7RR6V45.js";

// src/Content/ContentRoute.ts
var ContentRoute = class extends BaseRoute_default {
  constructor() {
    super(...arguments);
    this.getContentItemRequest = (ContentID) => Instance_default.get(
      this.createRequestURL({
        option: "content",
        id: ContentID
      })
    );
    this.getContentRequest = (page = 1, limit = 20) => Instance_default.get(
      this.createRequestURL({
        option: "content",
        "list[limitstart]": (page - 1) * limit,
        "list[limit]": limit
      })
    );
    /**
     *
     * @param data
     */
    this.createContentRequest = (data) => Instance_default.post(
      this.createRequestURL({
        option: "content"
      }),
      data
    );
    /**
     *
     * @param data
     */
    this.updateContentRequest = (data) => Instance_default.put(
      this.createRequestURL({
        option: "content"
      }),
      data
    );
    /**
     *
     * @param contentId
     */
    this.deleteContentRequest = (contentId) => {
      const contentIds = JSON.parse(contentId);
      return Instance_default.delete(
        this.createRequestURL({
          option: "content",
          "ids[]": contentIds
        })
      );
    };
    this.getContentMasterDataRequest = () => {
      return Instance_default.get(
        this.createRequestURL({
          option: "content",
          task: "getMasterData"
        })
      );
    };
    this.getContentsByCampaignIDsRequest = (campaignIds, limit = 20) => {
      return Instance_default.get(
        this.createRequestURL({
          option: "content",
          task: "getContentsByCampaignIDs",
          ["campaignIds[]"]: [...campaignIds],
          limit
        })
      );
    };
    this.searchContentsRequest = (dataFilter, page = 1, limit = 20) => Instance_default.get(
      this.createRequestURL({
        option: "content",
        task: "filterPosts",
        limitStart: (page - 1) * limit,
        limit,
        ...dataFilter
      })
    );
    /**
     *
     * @param data
     */
    this.createPostRequest = (data, publishingType) => Instance_default.post(
      this.createRequestURL({
        option: "content",
        task: "createPost"
      }),
      {
        content: data,
        type: publishingType
      }
    );
    /**
     *
     * @param data
     */
    this.getPostsRequest = () => Instance_default.get(
      this.createRequestURL({
        option: "content",
        task: "getPosts"
      })
    );
    /**
     *
     * @param id
     */
    this.getPostItemRequest = (categoryId, itemId) => {
      return Instance_default.get(
        this.createRequestURL({
          option: "content",
          task: "getPostItem",
          categoryId,
          itemId
        })
      );
    };
    this.getContentChannelItemRequest = (categoryId) => {
      return Instance_default.post(
        this.createRequestURL({
          option: "content",
          task: "generateSubContentChannels"
        }),
        {
          categoryId
        }
      );
    };
    this.getScheduleChannelRequest = (memberId) => {
      return InstanceServiceApi_default.get("/api/schedule/" + memberId);
    };
  }
};
var ContentRoute_default = ContentRoute;

export {
  ContentRoute_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
