import {
  SVGComponent
} from "./chunk-C5UZGLWY.js";

// src/components/Form/FormColor/index.tsx
import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

// src/components/Form/FormColor/index.scss
var css = `/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */`;
document.head.appendChild(document.createElement("style")).appendChild(document.createTextNode(css));

// src/components/Form/FormColor/index.tsx
import { withTranslation } from "react-i18next";
import { BlockPicker, CompactPicker, PhotoshopPicker, SketchPicker } from "react-color";
var FormColor = ({ field, ...props }) => {
  const { t } = props;
  const [colorSelected, setColor] = useState(field.getValueSelected ?? "#ffffff");
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const handleChange = (color2) => {
    setColor(color2?.hex);
    field.handleChange(color2?.hex);
  };
  const handleChangeInput = (e) => {
    if (Object.prototype.hasOwnProperty.call(field, "handleChange")) {
      e.target.value = e.target.value.normalize("NFKC");
      field.handleChange(e.target.value);
      setColor(e.target.value);
    }
  };
  const handleClose = () => {
    setDisplayColorPicker(false);
  };
  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };
  const color = {
    height: "36px",
    borderRadius: "2px",
    background: `${colorSelected}`,
    width: "100%"
  };
  const swatch = {
    padding: "5px",
    background: "#fff",
    borderRadius: "1px",
    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    display: "block",
    cursor: "pointer",
    width: "100%",
    ...field?.readOnly && {
      pointerEvents: "none",
      background: "#ebebeb",
      cursor: "default"
    }
  };
  const popover = {
    position: "absolute",
    zIndex: "2",
    ...field?.params?.position?.includes("bottom") && {
      top: "100%",
      left: "0"
    },
    ...field?.params?.position?.includes("top") && {
      bottom: "100%",
      top: "auto"
    },
    ...field?.params?.position?.includes("right") && {
      left: "auto",
      right: "0"
    }
  };
  const cover = {
    position: "absolute",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
    height: "200vh"
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, displayColorPicker && /* @__PURE__ */ React.createElement("div", { style: cover, onClick: handleClose }), /* @__PURE__ */ React.createElement("div", { className: "position-relative" }, /* @__PURE__ */ React.createElement(Row, { className: "align-items-center" }, /* @__PURE__ */ React.createElement(Col, { sm: "3" }, /* @__PURE__ */ React.createElement(
    Form.Control,
    {
      as: "input",
      value: colorSelected,
      type: "text",
      required: field.required ?? false,
      id: field.key,
      onPaste: field.pasted ?? void 0,
      className: `${field.classNameInput} py-14 lh-sm`,
      onChange: (e) => handleChangeInput(e),
      onSelect: (e) => handleChangeInput(e),
      onBlur: field.blurred ?? void 0,
      placeholder: field.placeholder ?? t("txt_type"),
      disabled: field.disabled,
      maxLength: field.maxLength
    }
  )), /* @__PURE__ */ React.createElement(Col, { sm: "9" }, /* @__PURE__ */ React.createElement("div", { className: "d-flex" }, /* @__PURE__ */ React.createElement("div", { className: "w-100 " }, /* @__PURE__ */ React.createElement("div", { style: swatch, onClick: handleClick }, /* @__PURE__ */ React.createElement("div", { style: color })), displayColorPicker ? /* @__PURE__ */ React.createElement("div", { style: popover }, field?.params?.control === "photoshop" ? /* @__PURE__ */ React.createElement(
    PhotoshopPicker,
    {
      color: colorSelected,
      onChange: handleChange,
      onAccept: handleClose,
      onCancel: handleClose
    }
  ) : field?.params?.control === "block" ? /* @__PURE__ */ React.createElement(BlockPicker, { color: colorSelected, onChange: handleChange }) : field?.params?.control === "compact" ? /* @__PURE__ */ React.createElement(CompactPicker, { color: colorSelected, onChange: handleChange }) : /* @__PURE__ */ React.createElement(SketchPicker, { color: colorSelected, onChange: handleChange })) : null), /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "border-1 rounded-1 d-flex align-items-center justify-content-center ms-24 px-8px cursor-pointer",
      onClick: () => {
        setColor("#ffffff");
        field.handleChange("#ffffff");
      }
    },
    /* @__PURE__ */ React.createElement(SVGComponent, { url: "/assets/images/cancel.svg", className: "bg-danger" })
  ))))));
};
var A = withTranslation()(FormColor);

export {
  A
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
