import {
  notify
} from "./chunk-DL5QNKBI.js";
import {
  PAGE_STATUS
} from "./chunk-OSVSI4BW.js";

// src/pages/Permission/PermissionViewModel/PermissionDetailViewModel.ts
import { PERMISSION_FIELD } from "aesirx-lib";
import { makeAutoObservable, runInAction } from "mobx";
var PermissionDetailViewModel = class {
  constructor(permissionStore) {
    this.formStatus = PAGE_STATUS.READY;
    this.permissionDetailViewModel = { formPropsData: [{}] };
    this.aliasChange = "";
    this.permissionList = [];
    this.successResponse = {
      state: true,
      content_id: "",
      filters: {}
    };
    this.setForm = (permissionDetailViewModel) => {
      this.permissionDetailViewModel = permissionDetailViewModel;
    };
    this.initializeData = async () => {
      this.formStatus = PAGE_STATUS.LOADING;
      const data = await this.permissionStore.getDetail(
        this.permissionDetailViewModel.formPropsData[PERMISSION_FIELD.ID]
      );
      runInAction(() => {
        if (!data?.error) {
          this.onGetPermissionSuccessHandler(data?.response);
        } else {
          this.onErrorHandler(data?.response);
        }
      });
    };
    this.create = async () => {
      this.formStatus = PAGE_STATUS.LOADING;
      const data = await this.permissionStore.create(this.permissionDetailViewModel.formPropsData);
      runInAction(() => {
        if (!data?.error) {
          this.onSuccessHandler(data?.response, "Created successfully");
        } else {
          this.onErrorHandler(data?.response);
        }
      });
      return data;
    };
    this.update = async () => {
      this.formStatus = PAGE_STATUS.LOADING;
      const data = await this.permissionStore.update(this.permissionDetailViewModel.formPropsData);
      runInAction(() => {
        if (!data?.error) {
          this.onSuccessHandler(data?.response, "Updated successfully");
        } else {
          this.onErrorHandler(data?.response);
        }
      });
      return data;
    };
    this.onErrorHandler = (error) => {
      error._messages[0]?.message ? notify(error._messages[0]?.message, "error") : error.message && notify(error.message, "error");
      this.successResponse.state = false;
      this.successResponse.content_id = error.result;
      this.formStatus = PAGE_STATUS.READY;
    };
    this.onSuccessHandler = (result, message) => {
      if (result && message) {
        notify(message, "success");
      }
      this.formStatus = PAGE_STATUS.READY;
    };
    this.onGetPermissionSuccessHandler = (result) => {
      if (result && result[PERMISSION_FIELD.ID]) {
        this.permissionDetailViewModel.formPropsData = {
          ...this.permissionDetailViewModel.formPropsData,
          ...Object.keys(PERMISSION_FIELD).map((index) => {
            return {
              [PERMISSION_FIELD[index]]: result[PERMISSION_FIELD[index]]
            };
          }).reduce((prev, cur) => ({ ...prev, ...cur }))
        };
        this.formStatus = PAGE_STATUS.READY;
      }
    };
    this.onGetPermissionListSuccessHandler = (result) => {
      if (result) {
        this.permissionList = result;
      }
      this.formStatus = PAGE_STATUS.READY;
    };
    this.handleFormPropsData = (key, value) => {
      if (key && value !== null) {
        if (typeof value === "object" && value !== null && !Array.isArray(value)) {
          Object.assign(this.permissionDetailViewModel.formPropsData[key], value);
        } else {
          this.permissionDetailViewModel.formPropsData[key] = value;
        }
      }
    };
    this.handleAliasChange = (value) => {
      this.aliasChange = value;
    };
    makeAutoObservable(this);
    this.permissionStore = permissionStore;
  }
};
var PermissionDetailViewModel_default = PermissionDetailViewModel;

export {
  PermissionDetailViewModel_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
