// src/env.ts
if (typeof window !== "undefined") {
  window.process = { env: "" };
}
var s = false ? [] : typeof window !== "undefined" && window;
var env = { ...process.env, ...s["env"] };

export {
  env
};
