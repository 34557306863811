// src/Abstract/BaseModel.ts
var BaseModel = class {
  constructor(entities) {
    this.items = [];
    this.unTransformedItems = [];
    this.pureEntities = null;
    this.getPureEntities = () => this.pureEntities;
    this.getItems = () => {
      return this.items;
    };
    this.getUntransformedItems = () => {
      return this.unTransformedItems;
    };
    this.totalItems = () => this.items.length;
    this.toJSON = () => {
      if (!this.items)
        return null;
      return this.items.map((element) => {
        return element ? element.toJSON() : null;
      });
    };
    this.getPagination = () => {
      return {
        page: this.pureEntities.page ? parseInt(this.pureEntities.page) : 1,
        pageLimit: this.pureEntities.pageLimit ? parseInt(this.pureEntities.pageLimit) : 0,
        limitstart: this.pureEntities.limitstart ? parseInt(this.pureEntities.limitstart) : 0,
        totalItems: this.pureEntities.totalItems ? parseInt(this.pureEntities.totalItems) : 0,
        totalPages: this.pureEntities.totalPages ? parseInt(this.pureEntities.totalPages) : 0
      };
    };
    this.getBiPagination = () => {
      return {
        page: this.pureEntities.page ? parseInt(this.pureEntities.page) : null,
        page_size: this.pureEntities.page_size ? parseInt(this.pureEntities.page_size) : null,
        total_pages: this.pureEntities.total_pages ? parseInt(this.pureEntities.total_pages) : null,
        total_elements: this.pureEntities.total_elements ? parseInt(this.pureEntities.total_elements) : null
      };
    };
    this.pureEntities = entities;
  }
};
var BaseModel_default = BaseModel;

export {
  BaseModel_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
