import {
  Instance_default
} from "./chunk-GZ3JSDB6.js";
import {
  BaseRoute_default
} from "./chunk-Q7RR6V45.js";

// src/Dam/DamRoute.ts
var DamRoute = class extends BaseRoute_default {
  constructor() {
    super(...arguments);
    this.getSubscription = () => {
      return Instance_default.get(
        this.createRequestURL({
          option: "com_dam_asset",
          task: "getTotalStorage"
        })
      );
    };
    this.updateDamSubscription = (data) => {
      return Instance_default.put(
        this.createRequestURL({
          option: "user_subscription"
        }),
        data,
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
    };
    this.getAsset = (id) => {
      return Instance_default.get(
        this.createRequestURL({
          option: "dam_asset",
          id
        })
      );
    };
    this.getAssets = (collectionId = 0, dataFilter = { limitAsset: 2 }) => {
      return Instance_default.get(
        this.createRequestURL({
          option: "dam_asset",
          "filter[collection_id]": collectionId,
          ...dataFilter
        })
      );
    };
    this.searchAssets = (dataFilter = {}) => {
      return Instance_default.get(
        this.createRequestURL({
          option: "dam_asset",
          ...dataFilter
        })
      );
    };
    this.createAssets = (data) => {
      return Instance_default.post(
        this.createRequestURL({
          option: "dam_asset"
        }),
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
    };
    this.updateAssets = (data) => {
      return Instance_default.put(
        this.createRequestURL({
          option: "dam_asset"
        }),
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
    };
    this.deleteAssets = (ids) => {
      return Instance_default.delete(
        this.createRequestURL({
          option: "dam_asset"
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          data: ids
        }
      );
    };
    this.getCollection = (id) => {
      return Instance_default.get(
        this.createRequestURL({
          option: "dam_collection",
          id
        })
      );
    };
    this.getCollections = (collectionId = 0, dataFilter = {}) => {
      if (collectionId) {
      }
      return Instance_default.get(
        this.createRequestURL({
          option: "dam_collection",
          // 'filter[id]': collectionId,
          ...dataFilter
        })
      );
    };
    this.searchCollections = (dataFilter = {}) => {
      return Instance_default.get(
        this.createRequestURL({
          option: "dam_collection",
          ...dataFilter
        })
      );
    };
    this.createCollections = (data) => {
      return Instance_default.post(
        this.createRequestURL({
          option: "dam_collection"
        }),
        data
      );
    };
    this.updateCollections = (data) => {
      return Instance_default.put(
        this.createRequestURL({
          option: "dam_collection"
        }),
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      );
    };
    this.deleteCollections = (ids) => {
      return Instance_default.delete(
        this.createRequestURL({
          option: "dam_collection"
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          data: ids
        }
      );
    };
    this.moveToFolder = (data) => {
      return Instance_default.post(
        this.createRequestURL({
          option: "dam_collection",
          task: "changeParrentId"
        }),
        data
      );
    };
    this.downloadCollections = (ids) => {
      return Instance_default.post(
        this.createRequestURL({
          option: "dam_collection",
          task: "downloadFiles"
        }),
        ids,
        {
          responseType: "blob"
        }
      );
    };
  }
};
var DamRoute_default = DamRoute;

export {
  DamRoute_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
