import {
  PERMISSION_FIELD
} from "./chunk-R43B7PK2.js";
import {
  BaseItemModel_default
} from "./chunk-UPAELC5I.js";
import {
  BaseModel_default
} from "./chunk-ITJMNR7M.js";

// src/Organization/Permission/Model.ts
var PermissionModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    if (entities) {
      this.items = entities._embedded?.item.map((element) => {
        return new PermissionItemModel(element);
      });
    }
  }
};
var PermissionItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.group_id = 0;
    this.role_name = "";
    this.rules = [];
    this.asset_id = 0;
    this.action = "";
    this.value = "";
    this.entity = "";
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [PERMISSION_FIELD.GROUP_ID]: this.group_id,
        [PERMISSION_FIELD.ROLE_NAME]: this.role_name,
        [PERMISSION_FIELD.RULES]: this.rules,
        [PERMISSION_FIELD.ASSET_ID]: this.asset_id,
        [PERMISSION_FIELD.ACTION]: this.action,
        [PERMISSION_FIELD.VALUE]: this.value,
        [PERMISSION_FIELD.ENTITY]: this.entity
      };
    };
    this.toObject = () => {
      return {};
    };
    if (entity) {
      this.group_id = entity[PERMISSION_FIELD.GROUP_ID] ?? 0;
      this.role_name = entity[PERMISSION_FIELD.ROLE_NAME] ?? "";
      this.rules = entity[PERMISSION_FIELD.RULES] ?? [];
      this.asset_id = entity[PERMISSION_FIELD.ASSET_ID] ?? 0;
      this.action = entity[PERMISSION_FIELD.ACTION] ?? "";
      this.value = entity[PERMISSION_FIELD.VALUE] ?? "";
      this.entity = entity[PERMISSION_FIELD.ENTITY] ?? "";
    }
  }
};
PermissionItemModel.__transformItemToApiOfCreation = (data) => {
  let formData = new FormData();
  const excluded = [];
  Object.keys(PERMISSION_FIELD).forEach((index) => {
    if (!excluded.includes(PERMISSION_FIELD[index]) && data[PERMISSION_FIELD[index]]) {
      formData.append(PERMISSION_FIELD[index], data[PERMISSION_FIELD[index]]);
    }
  });
  return formData;
};
PermissionItemModel.__transformItemToApiOfUpdation = (data) => {
  let formData = {};
  const excluded = [PERMISSION_FIELD.CUSTOM_FIELDS];
  Object.keys(PERMISSION_FIELD).forEach((index) => {
    if (!excluded.includes(PERMISSION_FIELD[index]) && data[PERMISSION_FIELD[index]]) {
      formData[PERMISSION_FIELD[index]] = data[PERMISSION_FIELD[index]];
    }
  });
  return formData;
};

export {
  PermissionModel,
  PermissionItemModel
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
