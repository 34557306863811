// src/pages/MemberRole/store.ts
import { MemberRoleApiService, MemberRoleItemModel } from "aesirx-lib";
var MemberRoleStore = class {
  async getList(filters) {
    try {
      const getListAPIService = new MemberRoleApiService();
      const respondedData = await getListAPIService.getList(filters);
      return { error: false, response: respondedData };
    } catch (error) {
      return { error: true, response: error?.response?.data };
    }
  }
  async getMemberRoleList(filters) {
    try {
      const getListAPIService = new MemberRoleApiService();
      const respondedData = await getListAPIService.getList(filters);
      return { error: false, response: respondedData };
    } catch (error) {
      return { error: true, response: error?.response?.data };
    }
  }
  async getDetail(id) {
    if (!id)
      return { error: false, response: false };
    try {
      const results = true;
      if (results) {
        const getDetailInfoAPIService = new MemberRoleApiService();
        const respondedData = await getDetailInfoAPIService.getDetail(id);
        return { error: false, response: respondedData };
      }
    } catch (error) {
      return { error: true, response: error?.response?.data };
    }
  }
  async create(createFieldData) {
    try {
      const convertedUpdateGeneralData = MemberRoleItemModel.__transformItemToApiOfCreation(createFieldData);
      let resultOnSave;
      const createOrganizationApiService = new MemberRoleApiService();
      resultOnSave = await createOrganizationApiService.create(convertedUpdateGeneralData);
      return { error: false, response: resultOnSave?.result };
    } catch (error) {
      return { error: true, response: error?.response?.data };
    }
  }
  async update(updateFieldData) {
    try {
      const convertedUpdateGeneralData = MemberRoleItemModel.__transformItemToApiOfUpdation(updateFieldData);
      let resultOnSave;
      const updateOrganizationApiService = new MemberRoleApiService();
      resultOnSave = await updateOrganizationApiService.update(convertedUpdateGeneralData);
      return { error: false, response: resultOnSave?.result };
    } catch (error) {
      return { error: true, response: error?.response?.data };
    }
  }
  async delete(arr) {
    try {
      const aesirxOrganizationApiService = new MemberRoleApiService();
      const respondedData = await aesirxOrganizationApiService.delete(arr);
      return { error: false, response: respondedData };
    } catch (error) {
      return { error: true, response: error?.response?.data };
    }
  }
};

export {
  MemberRoleStore
};
