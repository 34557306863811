import {
  Dropzone_default,
  index_module_default,
  useDamViewModel,
  withDamViewModel
} from "./chunk-JIKNAPG2.js";
import {
  AesirXDamForm_default,
  TrashIcon_default
} from "./chunk-MGOXJZAK.js";
import {
  __publicField
} from "./chunk-XXPGZHWZ.js";

// src/integration/lib/AesirXDamForm/AesirXDamFormModel.jsx
import React6, { Component, Suspense } from "react";
import { observer as observer2 } from "mobx-react";
import { withTranslation } from "react-i18next";
import SimpleReactValidator from "simple-react-validator";
import {
  DAM_ASSETS_API_FIELD_KEY,
  DAM_ASSETS_FIELD_KEY as DAM_ASSETS_FIELD_KEY2,
  DAM_COLLECTION_API_RESPONSE_FIELD_KEY,
  DAM_COLLECTION_FIELD_KEY
} from "aesirx-lib";
import { faFolder } from "@fortawesome/free-regular-svg-icons/faFolder";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons/faCloudUploadAlt";
import { FontAwesomeIcon as FontAwesomeIcon2 } from "@fortawesome/react-fontawesome";
import { Image as Image2, ModalComponent, Button } from "aesirx-uikit";

// src/components/MoveToFolder/index.jsx
import { faAngleRight, faArrowLeft, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DAM_ASSETS_FIELD_KEY } from "aesirx-lib";
import { Image } from "aesirx-uikit";
var moveToFolder_timer = 0;
var moveToFolder_delay = 200;
var moveToFolder_prevent = false;
var MoveToFolder = observer(({ current = 0 }) => {
  const [currentCollection, setCurrentCollection] = useState(0);
  const [selectCollection, setSelectCollection] = useState(null);
  const {
    damListViewModel: {
      collections = [],
      moveToFolder,
      actionState: { selectedCards }
    },
    damFormViewModel: { closeMoveToFolder }
  } = useDamViewModel();
  const { t } = useTranslation();
  const mapDataSelectCards = useMemo(
    () => selectedCards.map((selectedItem) => +selectedItem?.id) ?? [],
    [selectedCards]
  );
  const isNoCollection = useMemo(
    () => selectedCards.filter((selectedItem) => !selectedItem?.[DAM_ASSETS_FIELD_KEY.TYPE]) ?? [],
    [selectedCards]
  );
  useEffect(() => {
    if (current) {
      const currentItem = collections.find((collection) => +collection.id === +current);
      if (currentItem?.id) {
        setCurrentCollection(currentItem);
      }
    }
    return () => {
    };
  }, []);
  const goToFolder = (curretnId) => {
    if (curretnId) {
      setCurrentCollection(curretnId);
      setSelectCollection(curretnId);
    } else {
      const backCollection = collections.find(
        (collection) => +collection.id === currentCollection?.parent_id
      );
      if (backCollection) {
        setCurrentCollection(backCollection);
        setSelectCollection(backCollection);
      } else {
        setCurrentCollection(0);
        setSelectCollection(0);
      }
    }
  };
  const onSelect = (collection) => {
    setSelectCollection((prevState) => {
      if (prevState === collection?.id) {
        return null;
      }
      return collection;
    });
  };
  const moveTo = () => {
    if (selectCollection) {
      moveToFolder(null, selectCollection?.id);
    } else {
      moveToFolder(null, 0);
    }
    closeMoveToFolder();
  };
  return /* @__PURE__ */ React.createElement("div", { className: "bg-white" }, /* @__PURE__ */ React.createElement("div", { className: ` border-0  py-0 btn d-flex  align-items-center ` }, /* @__PURE__ */ React.createElement(
    FontAwesomeIcon,
    {
      onClick: () => goToFolder(),
      className: `text-gray-dark cursor-pointer px-2 ${!currentCollection ? "pe-none opacity-0 d-none" : null}`,
      icon: faArrowLeft
    }
  ), /* @__PURE__ */ React.createElement("span", { className: " m-0 text-gray-dark fs-24 fw-medium" }, t("txt_move_to_folder"), " ")), /* @__PURE__ */ React.createElement("div", { className: `w-100 ${index_module_default.list}` }, collections.map((collection, index) => {
    if (+collection?.parent_id === (currentCollection.id ? +currentCollection.id : 0)) {
      return collection?.name && /* @__PURE__ */ React.createElement(
        OverlayTrigger,
        {
          key: index,
          placement: "bottom",
          overlay: /* @__PURE__ */ React.createElement(
            Tooltip,
            {
              id: `folder_${collection.id}`,
              className: `zindex-5 text-white px-2 rounded-2 bg-gray-dark
                        ${mapDataSelectCards.includes(+collection?.id) ? "" : "d-none"}
                        `
            },
            t("txt_can_not_move_to_itself")
          )
        },
        /* @__PURE__ */ React.createElement("span", { className: "d-block " }, /* @__PURE__ */ React.createElement(
          "button",
          {
            className: `text-body m-0 btn border-0 rounded-0 d-flex align-items-center cursor-pointer h-48px w-100 ${index_module_default.item} ${selectCollection?.id === collection?.id ? `bg-color-blue-9 rounded-2 text-gray-dark fw-semibold ${index_module_default.active}` : "btn-outline-light rounded-2 text-gray-dark"} ${mapDataSelectCards.includes(+collection?.id) ? index_module_default.disabled : ""}`,
            disabled: mapDataSelectCards.includes(+collection?.id) ? true : false,
            onDoubleClick: () => {
              clearTimeout(moveToFolder_timer);
              moveToFolder_prevent = true;
              goToFolder(collection);
            },
            onClick: () => {
              moveToFolder_timer = setTimeout(function() {
                if (!moveToFolder_prevent) {
                  onSelect(collection);
                }
                moveToFolder_prevent = false;
              }, moveToFolder_delay);
            }
          },
          /* @__PURE__ */ React.createElement(React.Fragment, null, selectCollection?.id === collection?.id ? /* @__PURE__ */ React.createElement(FontAwesomeIcon, { className: "text-green ", icon: faCircleCheck }) : /* @__PURE__ */ React.createElement(
            Image,
            {
              visibleByDefault: true,
              src: "/assets/images/folder.svg",
              className: "pe-none",
              width: 20,
              height: 20
            }
          ), /* @__PURE__ */ React.createElement("span", { className: "ps-3" }, collection.name), /* @__PURE__ */ React.createElement(
            FontAwesomeIcon,
            {
              size: "lg",
              className: `px-2 ms-auto ${selectCollection?.id === collection?.id ? `text-green` : "d-none"}`,
              icon: faAngleRight
            }
          ))
        ))
      );
    }
  })), /* @__PURE__ */ React.createElement("div", { className: "d-flex  px-2 pb-3 mb-3 pt-2 justify-content-end align-items-center" }, /* @__PURE__ */ React.createElement("button", { className: "m-0 btn py-2 btn-outline-secondary", onClick: closeMoveToFolder }, /* @__PURE__ */ React.createElement("span", { className: "text-body" }, t("txt_cancel"))), /* @__PURE__ */ React.createElement(
    OverlayTrigger,
    {
      placement: "bottom",
      overlay: /* @__PURE__ */ React.createElement(
        Tooltip,
        {
          id: `moveToFolderButton`,
          className: `zindex-5 text-white px-2 rounded-2 bg-gray-dark ${+currentCollection?.id !== +current && +selectCollection?.id !== +current || +currentCollection?.id !== +selectCollection?.id && +selectCollection?.id && +selectCollection?.id !== +current || +current !== 0 && selectCollection === 0 || isNoCollection.length < 1 ? "d-none" : ""}`
        },
        t("txt_item_already_in_this_folder")
      )
    },
    /* @__PURE__ */ React.createElement("span", { className: "d-inline-block" }, /* @__PURE__ */ React.createElement(
      "button",
      {
        disabled: +currentCollection?.id !== +current && +selectCollection?.id !== +current || +currentCollection?.id !== +selectCollection?.id && +selectCollection?.id && +selectCollection?.id !== +current || +current !== 0 && selectCollection === 0 || isNoCollection.length < 1 ? false : true,
        className: "ps-3 text-body m-0 py-2 btn btn-success ms-2 ",
        onClick: moveTo
      },
      t("txt_move_to_folder")
    ))
  )));
});
var MoveToFolder_default = MoveToFolder;

// src/svg/EddingIcon.jsx
import React2 from "react";
var Edding = ({ fill = "none", ...props }) => {
  return /* @__PURE__ */ React2.createElement(
    "svg",
    {
      ...props,
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill,
      xmlns: "http://www.w3.org/2000/svg"
    },
    /* @__PURE__ */ React2.createElement("g", { clipPath: "url(#clip0_4044_16037)" }, /* @__PURE__ */ React2.createElement(
      "path",
      {
        d: "M19.4537 10.4058C19.0654 10.4058 18.7506 10.7207 18.7506 11.109V21.4218C18.7506 22.068 18.2249 22.5937 17.5787 22.5937H2.57821C1.93201 22.5937 1.40629 22.068 1.40629 21.4218V6.42134C1.40629 5.77515 1.93201 5.24943 2.57821 5.24943H12.891C13.2794 5.24943 13.5942 4.93461 13.5942 4.54629C13.5942 4.15796 13.2794 3.84314 12.891 3.84314H2.57821C1.15658 3.84314 0 4.99972 0 6.42134V21.4218C0 22.8434 1.15658 24 2.57821 24H17.5787C19.0003 24 20.1569 22.8434 20.1569 21.4218V11.109C20.1569 10.7207 19.8421 10.4058 19.4537 10.4058V10.4058Z",
        fill: "#1A2226"
      }
    ), /* @__PURE__ */ React2.createElement(
      "path",
      {
        d: "M23.5204 1.80566L22.1945 0.479757C21.5549 -0.159919 20.514 -0.159919 19.8743 0.479757L9.2674 11.0867C9.16924 11.1848 9.10235 11.3099 9.07507 11.446L8.41209 14.7607C8.36601 14.9912 8.43815 15.2295 8.60443 15.3958C8.7376 15.5289 8.917 15.6017 9.1016 15.6017C9.14749 15.6017 9.19362 15.5972 9.23946 15.5881L12.5541 14.9251C12.6903 14.8979 12.8153 14.8309 12.9135 14.7328L23.5204 4.1259C23.5204 4.1259 23.5205 4.1259 23.5205 4.12586C24.1601 3.48623 24.1601 2.44538 23.5204 1.80566V1.80566ZM12.0696 13.5879L9.99797 14.0023L10.4124 11.9306L19.0456 3.29722L20.703 4.95458L12.0696 13.5879ZM22.526 3.13151L21.6974 3.96019L20.04 2.30283L20.8686 1.47419C20.96 1.38279 21.1087 1.38274 21.2001 1.47415L22.526 2.80005C22.6174 2.89141 22.6174 3.04015 22.526 3.13151Z",
        fill: "#1A2226"
      }
    )),
    /* @__PURE__ */ React2.createElement("defs", null, /* @__PURE__ */ React2.createElement("clipPath", { id: "clip0_4044_16037" }, /* @__PURE__ */ React2.createElement("rect", { width: "24", height: "24", fill: "white" })))
  );
};
var EddingIcon_default = Edding;

// src/svg/MoveFolderIcon.jsx
import React3 from "react";
var MoveFolder = ({ fill = "none", ...props }) => {
  return /* @__PURE__ */ React3.createElement(
    "svg",
    {
      ...props,
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill,
      xmlns: "http://www.w3.org/2000/svg"
    },
    /* @__PURE__ */ React3.createElement(
      "path",
      {
        d: "M22 19V9C22 8.46957 21.7893 7.96086 21.4142 7.58579C21.0391 7.21071 20.5304 7 20 7H13.236C12.8645 6.99999 12.5004 6.89651 12.1844 6.70116C11.8684 6.50581 11.6131 6.22631 11.447 5.894L10.553 4.106C10.3869 3.77353 10.1314 3.49394 9.8152 3.29858C9.49902 3.10322 9.13466 2.99983 8.763 3H4C3.46957 3 2.96086 3.21071 2.58579 3.58579C2.21071 3.96086 2 4.46957 2 5V19C2 19.5304 2.21071 20.0391 2.58579 20.4142C2.96086 20.7893 3.46957 21 4 21H20C20.5304 21 21.0391 20.7893 21.4142 20.4142C21.7893 20.0391 22 19.5304 22 19Z",
        stroke: "#282831",
        strokeLinecap: "round",
        strokeLinejoin: "round"
      }
    ),
    /* @__PURE__ */ React3.createElement(
      "path",
      {
        d: "M16 13.5L8 13.5M16 13.5L13.3333 16M16 13.5L13.3333 11",
        stroke: "#222328",
        strokeLinecap: "round",
        strokeLinejoin: "round"
      }
    )
  );
};
var MoveFolderIcon_default = MoveFolder;

// src/svg/EyeIcon.jsx
import React4 from "react";
var Eye = ({ fill = "none", ...props }) => {
  return /* @__PURE__ */ React4.createElement(
    "svg",
    {
      ...props,
      width: "22",
      height: "16",
      viewBox: "0 0 22 16",
      fill,
      xmlns: "http://www.w3.org/2000/svg"
    },
    /* @__PURE__ */ React4.createElement(
      "path",
      {
        d: "M20.257 6.962C20.731 7.582 20.731 8.419 20.257 9.038C18.764 10.987 15.182 15 11 15C6.81801 15 3.23601 10.987 1.74301 9.038C1.51239 8.74113 1.38721 8.37592 1.38721 8C1.38721 7.62408 1.51239 7.25887 1.74301 6.962C3.23601 5.013 6.81801 1 11 1C15.182 1 18.764 5.013 20.257 6.962V6.962Z",
        stroke: "#1A2226",
        strokeLinecap: "round",
        strokeLinejoin: "round"
      }
    ),
    /* @__PURE__ */ React4.createElement(
      "path",
      {
        d: "M11 11C12.6569 11 14 9.65685 14 8C14 6.34315 12.6569 5 11 5C9.34315 5 8 6.34315 8 8C8 9.65685 9.34315 11 11 11Z",
        stroke: "#1A2226",
        strokeLinecap: "round",
        strokeLinejoin: "round"
      }
    )
  );
};
var EyeIcon_default = Eye;

// src/svg/DownloadIcon.jsx
import React5 from "react";
var Edding2 = ({ fill = "none", ...props }) => {
  return /* @__PURE__ */ React5.createElement(
    "svg",
    {
      ...props,
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill,
      xmlns: "http://www.w3.org/2000/svg"
    },
    /* @__PURE__ */ React5.createElement(
      "path",
      {
        d: "M12 15V3M12 15L8 11M12 15L16 11M2 17L2.621 19.485C2.72915 19.9177 2.97882 20.3018 3.33033 20.5763C3.68184 20.8508 4.11501 20.9999 4.561 21H19.439C19.885 20.9999 20.3182 20.8508 20.6697 20.5763C21.0212 20.3018 21.2708 19.9177 21.379 19.485L22 17",
        stroke: "#222328",
        strokeLinecap: "round",
        strokeLinejoin: "round"
      }
    )
  );
};
var DownloadIcon_default = Edding2;

// src/integration/lib/AesirXDamForm/AesirXDamFormModel.jsx
var AesirXDamFormModal = observer2(
  class AesirXDamFormModal2 extends Component {
    constructor(props) {
      super(props);
      __publicField(this, "damFormModalViewModel", null);
      __publicField(this, "damListViewModel", null);
      __publicField(this, "updateDetail", () => {
        if (this.isFormValid()) {
          this.damFormModalViewModel.saveOnModal();
        }
      });
      __publicField(this, "isFormValid", () => {
        if (this.validator.allValid()) {
          return true;
        } else {
          this.validator.showMessages();
          this.forceUpdate();
          return false;
        }
      });
      __publicField(this, "handleUpdate", (data) => {
        this.damFormModalViewModel.closeModal();
        if (this.damFormModalViewModel.damEditdata?.type) {
          this.damListViewModel.updateAssets({
            [DAM_ASSETS_FIELD_KEY2.ID]: this.damFormModalViewModel.damEditdata?.[DAM_ASSETS_FIELD_KEY2.ID],
            ...data
          });
        } else {
          this.damListViewModel.updateCollections({
            [DAM_COLLECTION_FIELD_KEY.ID]: this.damFormModalViewModel.damEditdata?.[DAM_COLLECTION_FIELD_KEY.ID],
            [DAM_COLLECTION_FIELD_KEY.PARENT_ID]: this.damFormModalViewModel.damEditdata?.[DAM_COLLECTION_FIELD_KEY.PARENT_ID],
            ...data
          });
        }
      });
      __publicField(this, "handleRename", () => {
        this.damFormModalViewModel.openCreateCollectionModal();
        this.damFormModalViewModel.closeContextMenuItem();
        document.querySelector(`#id_${this.damFormModalViewModel.damEditdata?.id}`).focus();
      });
      __publicField(this, "handleCreateFolder", () => {
        const { t } = this.props;
        const collectionId = this.damListViewModel.damLinkFolder.split("/");
        const currentCollection = !isNaN(collectionId[collectionId.length - 1]) ? collectionId[collectionId.length - 1] : 0;
        this.damFormModalViewModel.closeContextMenu();
        this.damListViewModel.createCollections({
          [DAM_COLLECTION_API_RESPONSE_FIELD_KEY.NAME]: t("txt_new_folder"),
          [DAM_COLLECTION_API_RESPONSE_FIELD_KEY.PARENT_ID]: currentCollection
        });
      });
      __publicField(this, "handleCreateAssets", (data) => {
        if (data) {
          const collectionId = this.damListViewModel.damLinkFolder.split("/");
          const currentCollection = !isNaN(collectionId[collectionId.length - 1]) ? collectionId[collectionId.length - 1] : 0;
          this.damListViewModel.createAssets({
            [DAM_ASSETS_API_FIELD_KEY.NAME]: data?.name ?? "",
            [DAM_ASSETS_API_FIELD_KEY.FILE_NAME]: data?.name ?? "",
            [DAM_ASSETS_API_FIELD_KEY.COLLECTION_ID]: currentCollection,
            [DAM_ASSETS_API_FIELD_KEY.FILE]: data
          });
          this.damFormModalViewModel.closeContextMenu();
        }
      });
      this.validator = new SimpleReactValidator({ autoForceUpdate: this });
      const { viewModel } = props;
      this.damFormModalViewModel = viewModel ? viewModel.getDamFormViewModel() : null;
      this.damListViewModel = viewModel ? viewModel.getDamListViewModel() : null;
    }
    render() {
      const {
        show,
        showDeleteModal,
        showContextMenu,
        showContextMenuItem,
        openModal,
        downloadFile,
        setOnEditCollection,
        showMoveToFolder,
        openMoveToFolder
      } = this.damFormModalViewModel;
      const {
        deleteItem,
        actionState: { selectedCards }
      } = this.damListViewModel;
      const { t } = this.props;
      const collectionId = this.damListViewModel.damLinkFolder.split("/");
      const currentCollectionId = !isNaN(collectionId[collectionId.length - 1]) ? collectionId[collectionId.length - 1] : 0;
      return /* @__PURE__ */ React6.createElement(React6.Fragment, null, show && /* @__PURE__ */ React6.createElement(Suspense, { fallback: "" }, /* @__PURE__ */ React6.createElement(
        ModalComponent,
        {
          show,
          onHide: this.damFormModalViewModel.closeModal,
          onShow: () => {
            this.damFormModalViewModel.closeContextMenuItem();
            this.damFormModalViewModel.closeContextMenu();
          },
          closeButton: true,
          contentClassName: "bg-white shadow",
          body: /* @__PURE__ */ React6.createElement(
            AesirXDamForm_default,
            {
              delete: deleteItem,
              handleUpdate: this.handleUpdate,
              viewModel: this.damFormModalViewModel
            }
          ),
          dialogClassName: "modal-fullscreen"
        }
      )), showContextMenu && /* @__PURE__ */ React6.createElement(
        "div",
        {
          id: "contextMenu",
          className: `col_thumb cursor-pointer align-self-center mb-4 bg-white zindex-5 position-fixed`,
          style: { ...this.damListViewModel.actionState?.style }
        },
        /* @__PURE__ */ React6.createElement("div", { className: "item_thumb d-flex bg-white shadow-sm rounded-2  flex-column" }, /* @__PURE__ */ React6.createElement(Dropzone_default, { createAssets: this.handleCreateAssets }, /* @__PURE__ */ React6.createElement(
          "div",
          {
            className: `d-flex align-items-center rounded-1 px-3 py-2 mb-1  text-decoration-none cursor-pointer txt_hover`
          },
          /* @__PURE__ */ React6.createElement(
            FontAwesomeIcon2,
            {
              icon: faCloudUploadAlt,
              className: " d-inline-block align-text-bottom"
            }
          ),
          /* @__PURE__ */ React6.createElement("span", { className: "ms-3 text py-1 d-inline-block" }, t("txt_upload_file"))
        )), /* @__PURE__ */ React6.createElement(
          "div",
          {
            className: `d-flex align-items-center rounded-1 px-3 py-2 mb-1  text-decoration-none `,
            onClick: setOnEditCollection
          },
          /* @__PURE__ */ React6.createElement(FontAwesomeIcon2, { icon: faFolder, className: " d-inline-block align-text-bottom" }),
          /* @__PURE__ */ React6.createElement("span", { className: "ms-3 text py-1 d-inline-block" }, t("txt_create_folder"))
        ))
      ), showContextMenuItem && /* @__PURE__ */ React6.createElement(
        "div",
        {
          id: "contextMenuItem",
          className: `d-flex align-items-center justify-content-center bg-white shadow-sm rounded-2 flex-column zindex-5 position-fixed cursor-pointer`,
          style: { ...this.damListViewModel.actionState?.style }
        },
        /* @__PURE__ */ React6.createElement(
          "div",
          {
            className: `d-flex align-items-center px-4 py-3  text-decoration-none w-100`,
            onClick: openModal
          },
          /* @__PURE__ */ React6.createElement(Suspense, { fallback: "" }, /* @__PURE__ */ React6.createElement(EyeIcon_default, { className: "stroke-dark" })),
          /* @__PURE__ */ React6.createElement("span", { className: "ms-3 text-color py-1 d-inline-block" }, t("txt_preview"))
        ),
        selectedCards.length < 2 && /* @__PURE__ */ React6.createElement(
          "div",
          {
            className: `d-flex align-items-center px-4 py-3  text-decoration-none w-100`,
            onClick: this.handleRename
          },
          /* @__PURE__ */ React6.createElement(Suspense, { fallback: "" }, /* @__PURE__ */ React6.createElement(EddingIcon_default, null)),
          /* @__PURE__ */ React6.createElement("span", { className: "ms-3 text-color py-1 d-inline-block" }, t("txt_rename"))
        ),
        /* @__PURE__ */ React6.createElement(
          "div",
          {
            className: `d-flex align-items-center px-4 py-3  text-decoration-none w-100`,
            onClick: openMoveToFolder
          },
          /* @__PURE__ */ React6.createElement(Suspense, { fallback: "" }, /* @__PURE__ */ React6.createElement(MoveFolderIcon_default, { className: "stroke-dark" })),
          /* @__PURE__ */ React6.createElement("span", { className: "ms-3 text-color py-1 d-inline-block" }, t("txt_move_to_folder"))
        ),
        this.damFormModalViewModel.damEditdata?.[DAM_ASSETS_FIELD_KEY2.TYPE] && /* @__PURE__ */ React6.createElement(
          "div",
          {
            className: `d-flex align-items-center px-4 py-3  text-decoration-none w-100`,
            onClick: downloadFile
          },
          /* @__PURE__ */ React6.createElement(Suspense, { fallback: /* @__PURE__ */ React6.createElement("div", null, "Loading...") }, /* @__PURE__ */ React6.createElement(DownloadIcon_default, { className: "stroke-dark" })),
          /* @__PURE__ */ React6.createElement("span", { className: "ms-3 text-color py-1 d-inline-block" }, t("txt_download_folder"))
        ),
        /* @__PURE__ */ React6.createElement(
          "div",
          {
            className: `d-flex align-items-center px-4 py-3  text-decoration-none w-100`,
            onClick: this.damFormModalViewModel.openDeleteModal
          },
          /* @__PURE__ */ React6.createElement(Suspense, { fallback: "" }, /* @__PURE__ */ React6.createElement(TrashIcon_default, null)),
          /* @__PURE__ */ React6.createElement("span", { className: "ms-3 text-color py-1 d-inline-block text-danger" }, t("txt_delete"))
        )
      ), showDeleteModal ? /* @__PURE__ */ React6.createElement(Suspense, { fallback: /* @__PURE__ */ React6.createElement("div", null, "Loading...") }, /* @__PURE__ */ React6.createElement(
        ModalComponent,
        {
          closeButton: true,
          show: showDeleteModal,
          onHide: this.damFormModalViewModel.closeDeleteModal,
          onShow: () => {
            this.damFormModalViewModel.closeContextMenuItem();
            this.damFormModalViewModel.closeContextMenu();
          },
          contentClassName: "bg-white shadow",
          body: /* @__PURE__ */ React6.createElement("div", { className: "d-flex flex-column justify-content-center align-items-center pb-5" }, /* @__PURE__ */ React6.createElement(Image2, { className: "mb-3", src: "/assets/images/ep_circle-close.png" }), /* @__PURE__ */ React6.createElement("h4", { className: "mb-4" }, t("txt_are_you_sure")), /* @__PURE__ */ React6.createElement("p", { className: "text-center" }, this.damFormModalViewModel.damEditdata?.[DAM_ASSETS_FIELD_KEY2.TYPE] ? t("txt_delete_assets_popup_desc") : t("txt_delete_collections_popup_desc")), /* @__PURE__ */ React6.createElement("div", { className: "row" }, /* @__PURE__ */ React6.createElement("div", { className: "col-auto" }, /* @__PURE__ */ React6.createElement(
            Button,
            {
              text: t("txt_Cancel"),
              onClick: this.damFormModalViewModel.closeDeleteModal,
              className: "btn btn-outline-gray-300 bg-white text-blue-0 border-1 "
            }
          )), /* @__PURE__ */ React6.createElement("div", { className: "col-auto" }, /* @__PURE__ */ React6.createElement(
            Button,
            {
              text: t("txt_yes_delete"),
              onClick: deleteItem,
              className: "btn btn-danger "
            }
          ))))
        }
      )) : null, showMoveToFolder && /* @__PURE__ */ React6.createElement(
        ModalComponent,
        {
          show: showMoveToFolder,
          onHide: this.damFormModalViewModel.closeMoveToFolder,
          contentClassName: "bg-white shadow",
          header: false,
          body: /* @__PURE__ */ React6.createElement(MoveToFolder_default, { current: currentCollectionId })
        }
      ));
    }
  }
);
var AesirXDamFormModel_default = withTranslation()(withDamViewModel(AesirXDamFormModal));

export {
  AesirXDamFormModel_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
