// src/components/Hambuger/index.tsx
import React from "react";

// src/components/Hambuger/index.scss
var css = `/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
.item_hambuger {
  width: 25px;
  height: 3px;
  background-color: #cb222c;
  transition: all 0.4s ease;
  margin-bottom: 5px;
  margin: 4px 0;
}

@media (max-width: 1199.98px) {
  .wrapper_hambuger {
    display: inline-block !important;
  }
}`;
document.head.appendChild(document.createElement("style")).appendChild(document.createTextNode(css));

// src/components/Hambuger/index.tsx
var Hambuger = ({ handleAction, className }) => {
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: `wrapper_hambuger d-xl-none cursor-pointer ps-3 pe-2 ${className}`,
      onClick: handleAction
    },
    /* @__PURE__ */ React.createElement("div", { className: "item_hambuger" }),
    /* @__PURE__ */ React.createElement("div", { className: "item_hambuger" }),
    /* @__PURE__ */ React.createElement("div", { className: "item_hambuger" })
  );
};

export {
  Hambuger
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
