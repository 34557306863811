import {
  AesirXSelectExtended
} from "./chunk-HMAYFD5S.js";
import {
  ComponentSVG
} from "./chunk-APR4SZIP.js";
import {
  useI18nextContext
} from "./chunk-7RILKSMK.js";

// src/components/LanguagesSwitcher/index.tsx
import React from "react";
import i18n from "i18next";
var LanguagesSwitcher = () => {
  const { listLanguages } = useI18nextContext();
  const currentLanguage = listLanguages.filter((lang) => lang.value == i18n.language);
  return /* @__PURE__ */ React.createElement("div", { className: "ms-auto me-3 d-flex align-items-center fs-sm" }, /* @__PURE__ */ React.createElement(
    ComponentSVG,
    {
      url: "/assets/images/language-icon-light.svg",
      color: "var(--aesirxui-body-color)"
    }
  ), /* @__PURE__ */ React.createElement(
    AesirXSelectExtended,
    {
      isClearable: false,
      isSearchable: false,
      isBorder: false,
      isShadow: false,
      options: listLanguages,
      getOptionLabel: (options) => /* @__PURE__ */ React.createElement("div", { className: "language-option d-flex align-items-center" }, /* @__PURE__ */ React.createElement("span", null, options.label)),
      className: "shadow-none",
      onChange: (data) => {
        i18n.changeLanguage(data.value);
      },
      defaultValue: currentLanguage ?? [{ label: "English", value: "en" }],
      minWidth: 120,
      isLanguageSelect: true
    }
  ));
};

export {
  LanguagesSwitcher
};
