import {
  ESI_ORGANISATION_CHANNEL_API_RESPONSE_FIELD_KEY,
  ESI_ORGANISATION_CHANNEL_FIELD_KEY
} from "./chunk-IX5DZY3O.js";
import {
  BaseItemModel_default
} from "./chunk-UPAELC5I.js";
import {
  BaseModel_default
} from "./chunk-ITJMNR7M.js";

// src/OrganisationChannel/OrganisationChannelModel.ts
var OrganisationChannelModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = [];
    this.unTransformedItems = [];
    if (entities) {
      this.unTransformedItems = entities._embedded.item;
      this.items = entities._embedded.item.map((element) => {
        return new OrganisationChannelItemModel(element);
      });
    }
  }
};
var OrganisationChannelByOrganisationIdModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    this.items = [];
    this.unTransformedItems = [];
    if (entities) {
      const organisationChannels = entities.result;
      this.unTransformedItems = organisationChannels;
      this.items = organisationChannels.map((element) => {
        return new OrganisationChannelItemModel(element);
      });
    }
  }
};
var OrganisationChannelItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.channel = null;
    this.organisation = null;
    this.handle = "";
    this.channelName = null;
    this.getChannel = () => {
      return this.channel;
    };
    this.getProject = () => {
      return this.organisation;
    };
    this.getHandle = () => {
      return this.handle;
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [ESI_ORGANISATION_CHANNEL_FIELD_KEY.ID]: this.id,
        [ESI_ORGANISATION_CHANNEL_FIELD_KEY.CHANNEL]: this.channel,
        [ESI_ORGANISATION_CHANNEL_FIELD_KEY.ORGANISATION]: this.organisation,
        [ESI_ORGANISATION_CHANNEL_FIELD_KEY.HANDLE]: this.handle,
        [ESI_ORGANISATION_CHANNEL_FIELD_KEY.CHANNEL_NAME]: this.channelName
      };
    };
    if (entity) {
      this.channel = entity[ESI_ORGANISATION_CHANNEL_API_RESPONSE_FIELD_KEY.CHANNEL] ?? [0];
      this.organisation = entity[ESI_ORGANISATION_CHANNEL_API_RESPONSE_FIELD_KEY.ORGANISATION] ?? [
        0
      ];
      this.handle = entity[ESI_ORGANISATION_CHANNEL_API_RESPONSE_FIELD_KEY.HANDLE] ?? "";
      this.channelName = entity[ESI_ORGANISATION_CHANNEL_API_RESPONSE_FIELD_KEY.CHANNEL_NAME] ?? "";
    }
  }
};
OrganisationChannelItemModel.__transformItemToApiOfCreation = (data) => {
  return {
    [ESI_ORGANISATION_CHANNEL_API_RESPONSE_FIELD_KEY.CHANNEL]: data[ESI_ORGANISATION_CHANNEL_FIELD_KEY.CHANNEL] ?? "",
    [ESI_ORGANISATION_CHANNEL_API_RESPONSE_FIELD_KEY.ORGANISATION]: data[ESI_ORGANISATION_CHANNEL_FIELD_KEY.ORGANISATION] ?? "",
    [ESI_ORGANISATION_CHANNEL_API_RESPONSE_FIELD_KEY.HANDLE]: data[ESI_ORGANISATION_CHANNEL_FIELD_KEY.HANDLE] ?? ""
  };
};
OrganisationChannelItemModel.__transformItemToApiOfUpdation = (data) => {
  return {
    [ESI_ORGANISATION_CHANNEL_API_RESPONSE_FIELD_KEY.CHANNEL]: data[ESI_ORGANISATION_CHANNEL_FIELD_KEY.CHANNEL],
    [ESI_ORGANISATION_CHANNEL_API_RESPONSE_FIELD_KEY.ORGANISATION]: data[ESI_ORGANISATION_CHANNEL_FIELD_KEY.ORGANISATION],
    [ESI_ORGANISATION_CHANNEL_API_RESPONSE_FIELD_KEY.HANDLE]: data[ESI_ORGANISATION_CHANNEL_FIELD_KEY.HANDLE]
  };
};

export {
  OrganisationChannelModel,
  OrganisationChannelByOrganisationIdModel,
  OrganisationChannelItemModel
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
