import {
  Folder_default,
  HomeUtils_default
} from "./chunk-X32SVBO7.js";
import {
  __publicField
} from "./chunk-XXPGZHWZ.js";

// src/integration/lib/AesirXDamForm/AesirXDamForm.jsx
import React3, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { FORM_FIELD_TYPE, renderingGroupFieldHandler, Spinner, PAGE_STATUS } from "aesirx-uikit";
import { DAM_ASSETS_FIELD_KEY as DAM_ASSETS_FIELD_KEY2, DAM_COLLECTION_FIELD_KEY } from "aesirx-lib";
import { withTranslation } from "react-i18next";
import moment2 from "moment";
import { Button } from "aesirx-uikit";

// src/svg/TrashIcon.jsx
import React from "react";
var Trash = ({ fill = "none", ...props }) => {
  return /* @__PURE__ */ React.createElement(
    "svg",
    {
      ...props,
      width: "24",
      height: "24",
      viewBox: "0 0 24 24",
      fill,
      xmlns: "http://www.w3.org/2000/svg"
    },
    /* @__PURE__ */ React.createElement(
      "path",
      {
        d: "M5.25 5.25L6.1875 20.25C6.23203 21.1167 6.8625 21.75 7.6875 21.75H16.3125C17.1408 21.75 17.7595 21.1167 17.8125 20.25L18.75 5.25",
        stroke: "#C8192E",
        strokeLinecap: "round",
        strokeLinejoin: "round"
      }
    ),
    /* @__PURE__ */ React.createElement("path", { d: "M3.75 5.25H20.25Z", fill: "#C8192E" }),
    /* @__PURE__ */ React.createElement("path", { d: "M3.75 5.25H20.25", stroke: "#C8192E", strokeMiterlimit: "10", strokeLinecap: "round" }),
    /* @__PURE__ */ React.createElement(
      "path",
      {
        d: "M9 5.25V3.375C8.99957 3.22715 9.02837 3.08066 9.08475 2.94397C9.14114 2.80729 9.22399 2.6831 9.32854 2.57854C9.43309 2.47399 9.55728 2.39114 9.69397 2.33476C9.83066 2.27838 9.97714 2.24957 10.125 2.25H13.875C14.0229 2.24957 14.1693 2.27838 14.306 2.33476C14.4427 2.39114 14.5669 2.47399 14.6715 2.57854C14.776 2.6831 14.8589 2.80729 14.9152 2.94397C14.9716 3.08066 15.0004 3.22715 15 3.375V5.25M12 8.25001V18.75M8.625 8.25001L9 18.75M15.375 8.25001L15 18.75",
        stroke: "#C8192E",
        strokeLinecap: "round",
        strokeLinejoin: "round"
      }
    )
  );
};
var TrashIcon_default = Trash;

// src/components/ImageEditor/index.jsx
import React2 from "react";
import ImageEditor from "@toast-ui/react-image-editor";
import { DAM_ASSETS_FIELD_KEY } from "aesirx-lib";
import moment from "moment";
function ImageEditorComponent({ editorRef, damEditdata }) {
  const myTheme = {
    "common.bi.image": "/assets/images/file.svg",
    "common.bisize.width": "21px",
    "loadButton.display": "none"
  };
  const imageEditorOptions = {
    includeUI: {
      loadImage: {
        path: `${damEditdata?.[DAM_ASSETS_FIELD_KEY.DOWNLOAD_URL]}?${moment(
          damEditdata[DAM_ASSETS_FIELD_KEY.LAST_MODIFIED]
        ).unix()}`,
        name: damEditdata?.[DAM_ASSETS_FIELD_KEY.NAME]
      },
      theme: myTheme,
      menuBarPosition: "bottom"
    },
    cssMaxHeight: 500,
    cssMaxWidthL: 700,
    usageStatistics: false
  };
  return /* @__PURE__ */ React2.createElement(ImageEditor, { ref: editorRef, ...imageEditorOptions });
}

// src/integration/lib/AesirXDamForm/AesirXDamForm.jsx
var AesirDamForm = class extends Component {
  constructor(props) {
    super(props);
    __publicField(this, "editorRef", React3.createRef());
    __publicField(this, "formPropsData", {
      [DAM_ASSETS_FIELD_KEY2.NAME]: this.props.viewModel.damEditdata?.[DAM_ASSETS_FIELD_KEY2.NAME],
      [DAM_ASSETS_FIELD_KEY2.COLLECTION_ID]: this.props.viewModel.damEditdata?.[DAM_ASSETS_FIELD_KEY2.COLLECTION_ID],
      [DAM_ASSETS_FIELD_KEY2.DOWNLOAD_URL]: this.props.viewModel.damEditdata?.[DAM_ASSETS_FIELD_KEY2.DOWNLOAD_URL],
      [DAM_ASSETS_FIELD_KEY2.FILE_SIZE]: this.props.viewModel.damEditdata?.[DAM_ASSETS_FIELD_KEY2.FILE_SIZE] ?? this.props.viewModel.damEditdata?.[DAM_COLLECTION_FIELD_KEY.FILE_SIZE],
      [DAM_ASSETS_FIELD_KEY2.TYPE]: this.props.viewModel.damEditdata?.[DAM_ASSETS_FIELD_KEY2.TYPE],
      [DAM_ASSETS_FIELD_KEY2.LAST_MODIFIED]: this.props.viewModel.damEditdata?.modified_date_org
    });
    __publicField(this, "handleOnSubmit", () => {
      if (this.validator.allValid()) {
        this.formPropsData = HomeUtils_default.convertImageEditortoFile(
          this.props.viewModel.damEditdata,
          this.formPropsData,
          this.editorRef
        );
        this.props.handleUpdate(this.formPropsData);
      } else {
        this.validator.showMessages();
        this.forceUpdate();
      }
    });
    __publicField(this, "generateFormSetting", () => {
      const { t } = this.props;
      return [
        {
          fields: [
            {
              label: t("txt_title"),
              key: DAM_ASSETS_FIELD_KEY2.NAME,
              type: FORM_FIELD_TYPE.INPUT,
              value: this.formPropsData[DAM_ASSETS_FIELD_KEY2.NAME],
              validation: "required",
              required: true,
              className: "col-12",
              inputClassName: "border bg-transparent fs-sm text-gray-dark",
              handleChange: (event) => {
                this.formPropsData[DAM_ASSETS_FIELD_KEY2.NAME] = event.target.value;
                this.forceUpdate();
              },
              blurred: () => {
                this.validator.showMessageFor(t("txt_title"));
              }
            },
            {
              label: t("txt_url"),
              key: DAM_ASSETS_FIELD_KEY2.DOWNLOAD_URL,
              disabled: true,
              type: FORM_FIELD_TYPE.INPUT,
              value: this.formPropsData[DAM_ASSETS_FIELD_KEY2.DOWNLOAD_URL],
              className: `col-12  ${this.props.viewModel.damEditdata?.[DAM_ASSETS_FIELD_KEY2.TYPE] ? "" : "d-none"}`,
              inputClassName: "border bg-transparent fs-sm text-gray-dark"
            },
            {
              label: t("txt_file_type"),
              key: DAM_ASSETS_FIELD_KEY2.TYPE,
              type: FORM_FIELD_TYPE.INPUT,
              value: this.formPropsData[DAM_ASSETS_FIELD_KEY2.TYPE],
              disabled: true,
              className: `col-6 file_type ${this.props.viewModel.damEditdata?.[DAM_ASSETS_FIELD_KEY2.TYPE] ? "" : "d-none"}`,
              inputClassName: "bg-transparent border-0 p-0 text-gray-dark"
            },
            {
              label: t("txt_file_size"),
              key: DAM_ASSETS_FIELD_KEY2.FILE_SIZE,
              type: FORM_FIELD_TYPE.INPUT,
              value: this.formPropsData[DAM_ASSETS_FIELD_KEY2.FILE_SIZE] + " kb",
              disabled: true,
              className: "col-6 file_size",
              inputClassName: "bg-transparent border-0 p-0 text-gray-dark"
            },
            {
              label: t("txt_dimension"),
              disabled: true,
              type: FORM_FIELD_TYPE.INPUT,
              value: " ",
              className: "col-6 dimension ",
              inputClassName: "bg-transparent border-0 p-0 text-gray-dark"
            },
            {
              label: t("txt_last_modified"),
              key: DAM_ASSETS_FIELD_KEY2.LAST_MODIFIED,
              type: FORM_FIELD_TYPE.INPUT,
              value: this.formPropsData[DAM_ASSETS_FIELD_KEY2.LAST_MODIFIED] && moment2(this.formPropsData[DAM_ASSETS_FIELD_KEY2.LAST_MODIFIED]).format("DD MMM, YYYY"),
              disabled: true,
              className: "col-6 last_modified ",
              inputClassName: "bg-transparent border-0 p-0 text-gray-dark"
            }
          ]
        }
      ];
    });
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.viewModel = this.props.viewModel;
  }
  render() {
    const { formStatus, closeModal } = this.viewModel;
    if (formStatus === PAGE_STATUS.LOADING) {
      return /* @__PURE__ */ React3.createElement(Spinner, null);
    }
    const formSetting = this.generateFormSetting();
    const { t } = this.props;
    return /* @__PURE__ */ React3.createElement(React3.Fragment, null, /* @__PURE__ */ React3.createElement("div", { className: "row pb-3 h-100" }, /* @__PURE__ */ React3.createElement("div", { className: "col-lg-8 col-12 h-680px" }, /* @__PURE__ */ React3.createElement("div", { className: "h-100 p-3 bg-gray-400" }, /* @__PURE__ */ React3.createElement(
      Button,
      {
        svg: /* @__PURE__ */ React3.createElement(TrashIcon_default, null),
        text: t("txt_delete"),
        onClick: this.props.delete,
        className: "btn-outline-gray-300 h-48px bg-white text-danger "
      }
    ), /* @__PURE__ */ React3.createElement("div", { className: `d-flex align-items-center justify-content-center h-100 ` }, !this.props.viewModel.damEditdata?.[DAM_ASSETS_FIELD_KEY2.TYPE] ? /* @__PURE__ */ React3.createElement(Folder_default, null) : this.props.viewModel.damEditdata?.[DAM_ASSETS_FIELD_KEY2.TYPE] === "image" ? /* @__PURE__ */ React3.createElement(
      ImageEditorComponent,
      {
        editorRef: this.editorRef,
        damEditdata: this.props.viewModel.damEditdata
      }
    ) : HomeUtils_default.checkFileTypeFormData(this.props.viewModel.damEditdata)))), /* @__PURE__ */ React3.createElement("div", { className: "col-lg-4 col-12 h-auto d-flex flex-column" }, /* @__PURE__ */ React3.createElement("div", { className: "row" }, Object.keys(formSetting).map((groupIndex) => {
      return [...Array(formSetting[groupIndex])].map((group) => {
        return renderingGroupFieldHandler(group, this.validator);
      });
    }).reduce((arr, el) => {
      return arr.concat(el);
    }, [])), /* @__PURE__ */ React3.createElement("div", { className: "row justify-content-end" }, /* @__PURE__ */ React3.createElement("div", { className: "col-xxl-4 col-xl-5 col-6" }, /* @__PURE__ */ React3.createElement(
      Button,
      {
        text: t("txt_Cancel"),
        onClick: closeModal,
        className: "btn btn-outline-dark w-100"
      }
    )), /* @__PURE__ */ React3.createElement("div", { className: "col-xxl-4 col-xl-5 col-6" }, /* @__PURE__ */ React3.createElement(
      Button,
      {
        text: t("txt_save_update"),
        onClick: this.handleOnSubmit,
        className: "btn btn-success w-100"
      }
    ))))));
  }
};
var AesirXDamForm_default = withTranslation()(AesirDamForm);

export {
  TrashIcon_default,
  AesirXDamForm_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
