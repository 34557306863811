// src/pages/Profile/store.ts
import { AesirxMemberApiService } from "aesirx-lib";
var ProfileStore = class {
  async updateProfile(data) {
    try {
      const updateGeneralApiService = new AesirxMemberApiService();
      return await updateGeneralApiService.updateMember(data);
    } catch (error) {
      return false;
    }
  }
  async updatePreregistration(jwt, data) {
    try {
      const updateGeneralApiService = new AesirxMemberApiService();
      return await updateGeneralApiService.updatePreregistration(jwt, data);
    } catch (error) {
      return false;
    }
  }
  async getMember(id) {
    if (!id)
      return null;
    try {
      const getMemberInfoAPIService = new AesirxMemberApiService();
      return await getMemberInfoAPIService.getMemberInfo(id);
    } catch (error) {
      return null;
    }
  }
  async getMemberWeb3(jwt) {
    try {
      const getMemberInfoAPIService = new AesirxMemberApiService();
      return await getMemberInfoAPIService.getPreregistration(jwt);
    } catch (error) {
      return null;
    }
  }
  async updatePassword(data) {
    try {
      const updatePasswordApiService = new AesirxMemberApiService();
      return await updatePasswordApiService.updateMemberPassword(data);
    } catch (error) {
      return error;
    }
  }
  async checkEmail(data) {
    if (!data)
      return false;
    try {
      const CheckEmailAPIService = new AesirxMemberApiService();
      const respondedData = await CheckEmailAPIService.checkEmail(data);
      return respondedData;
    } catch (error) {
    }
    return false;
  }
};

export {
  ProfileStore
};
