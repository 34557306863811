import {
  ComponentSVG
} from "./chunk-APR4SZIP.js";

// src/components/ActionsBar/index.tsx
import React from "react";
import { Button } from "react-bootstrap";

// src/components/ActionsBar/index.scss
var css = `/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
.icon-success {
  background-color: #fff;
}

.btn-light .icon {
  background-color: #0a083b;
}
.btn-light:hover .icon {
  background-color: #fff;
}
.btn-light:hover:active .icon {
  background-color: #0a083b;
}
.btn-light.text-danger .icon {
  background-color: #c8192e;
}
.btn-light.text-danger:hover .icon {
  background-color: #c8192e;
}

.icon {
  transition: all 0.15s ease-in-out;
}

.btn-success .icon {
  background-color: #fff;
}`;
document.head.appendChild(document.createElement("style")).appendChild(document.createTextNode(css));

// src/components/ActionsBar/index.tsx
import { withTranslation } from "react-i18next";
var ActionsBar = ({ t, buttons = [] }) => {
  return /* @__PURE__ */ React.createElement("div", { className: "d-flex align-items-center" }, buttons.map((item, key) => {
    return /* @__PURE__ */ React.createElement("div", { key, className: "ms-15" }, /* @__PURE__ */ React.createElement(
      Button,
      {
        variant: `${item.variant ? item.variant : "light"}`,
        className: `${item.title === t("txt_cancel") ? "text-danger" : ""} px-16 fw-semibold d-flex align-items-center rounded-1`,
        onClick: item.handle
      },
      item.icon && /* @__PURE__ */ React.createElement(
        ComponentSVG,
        {
          color: item.iconColor,
          url: item.icon,
          className: `icon-${item.variant ? item.variant : "light"} me-1`
        }
      ),
      /* @__PURE__ */ React.createElement("span", { style: { color: item.textColor } }, item.title)
    ));
  }));
};
var A = withTranslation()(ActionsBar);

export {
  A
};
