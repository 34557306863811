// src/Constant/CampaignConstant.ts
var CAMPAIGN_FIELD_KEY = {
  ID: "id",
  NAME: "title",
  STATUS: "status",
  PROJECT: "project",
  START_DATE: "start_date",
  END_DATE: "end_date",
  PERCENT_COMPLETE: "percent_complete",
  NO_TO_DO_POSTS: "no_to_do_posts",
  NO_SCHEDULED_POSTS: "no_scheduled_posts",
  NO_PUBLISHED_POSTS: "no_published_posts",
  DATA: "data",
  PUBLISHED: "status"
};
var CAMPAIGN_API_RESPONSE_FIELD_KEY = {
  ID: "id",
  NAME: "title",
  STATUS: "status",
  PROJECT: "project",
  START_DATE: "start_date",
  END_DATE: "end_date",
  PERCENT_COMPLETE: "percent_complete",
  NO_TO_DO_POSTS: "no_to_do_posts",
  NO_SCHEDULED_POSTS: "no_scheduled_posts",
  NO_PUBLISHED_POSTS: "no_published_posts",
  DATA: "data",
  PUBLISHED: "status"
};

export {
  CAMPAIGN_FIELD_KEY,
  CAMPAIGN_API_RESPONSE_FIELD_KEY
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
