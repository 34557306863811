import {
  GlobalFilter
} from "./chunk-TB2DL46M.js";
import {
  AesirXDatePicker
} from "./chunk-UKRJ3UBK.js";

// src/components/TableBar/index.tsx
import { faChevronDown, faColumns, faList, faTh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
var TableBar = ({
  dataFilter,
  setFilter,
  tableRowHeader,
  setGlobalFilters,
  onAction,
  isList,
  onDelete,
  onShowColumns,
  isSearch,
  isColumnSelected,
  isAction,
  isDateRange,
  // defaultDate,
  // handleOnChange,
  // setDateFilter,
  // onSearch,
  actionList,
  onDateFilter
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const setDateFilter = (startDate, endDate) => {
    const dateFilter = {
      start_date: startDate,
      end_date: endDate
    };
    onDateFilter(dateFilter);
  };
  return /* @__PURE__ */ React.createElement("div", { className: "px-3 d-flex justify-content-between w-100" }, /* @__PURE__ */ React.createElement("div", { className: "d-flex" }, isSearch && /* @__PURE__ */ React.createElement(
    GlobalFilter,
    {
      setGlobalFilter: setGlobalFilters,
      searchText: "Search...",
      filter: dataFilter,
      setFilter
    }
  ), isColumnSelected && /* @__PURE__ */ React.createElement("div", { className: "me-3" }, /* @__PURE__ */ React.createElement(Dropdown, null, /* @__PURE__ */ React.createElement(Dropdown.Toggle, { variant: "white", className: "bg-white" }, /* @__PURE__ */ React.createElement("i", null, /* @__PURE__ */ React.createElement(FontAwesomeIcon, { icon: faColumns })), /* @__PURE__ */ React.createElement("span", { className: "ps-2 pe-3" }, t("txt_columns")), /* @__PURE__ */ React.createElement("i", { className: "text-green" }, /* @__PURE__ */ React.createElement(FontAwesomeIcon, { icon: faChevronDown }))), /* @__PURE__ */ React.createElement(Dropdown.Menu, { className: "w-max" }, tableRowHeader.map(
    (rowHeader, index) => /* @__PURE__ */ React.createElement("div", { key: index, className: "p-2 d-flex" }, /* @__PURE__ */ React.createElement(
      "input",
      {
        type: "checkbox",
        id: `item${index}`,
        className: "form-check-input d-block",
        onChange: (e) => e.target.checked && onShowColumns(rowHeader.accessor)
      }
    ), /* @__PURE__ */ React.createElement("label", { className: "ps-2", htmlFor: `item${index}` }, rowHeader.Header))
  )))), isAction && /* @__PURE__ */ React.createElement("div", { className: "me-3" }, /* @__PURE__ */ React.createElement(Dropdown, null, /* @__PURE__ */ React.createElement(Dropdown.Toggle, { variant: "white", className: "bg-white" }, /* @__PURE__ */ React.createElement("span", { className: "ps-2 pe-3" }, t("choose_an_action")), /* @__PURE__ */ React.createElement("i", { className: "text-green" }, /* @__PURE__ */ React.createElement(FontAwesomeIcon, { icon: faChevronDown }))), /* @__PURE__ */ React.createElement(Dropdown.Menu, { className: "w-max" }, /* @__PURE__ */ React.createElement("div", { className: "p-2 d-flex" }, /* @__PURE__ */ React.createElement(
    "input",
    {
      type: "checkbox",
      id: "delete_row",
      className: "form-check-input d-none",
      onChange: (e) => e?.target?.checked && onDelete()
    }
  ), /* @__PURE__ */ React.createElement("label", { className: "ps-2", htmlFor: "delete_row" }, "Delete item"))))), isDateRange && /* @__PURE__ */ React.createElement("div", { className: "me-3" }, /* @__PURE__ */ React.createElement(
    AesirXDatePicker,
    {
      placeholder: "All dates",
      setIsOpen,
      isOpen,
      setDateFilter,
      classContainer: "d-flex align-items-center pe-10",
      icon: true,
      inputClass: "border-0",
      onChange: () => {
      }
    }
  ))), /* @__PURE__ */ React.createElement("div", { className: "d-flex items-center" }, actionList && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    "button",
    {
      type: "button",
      className: `btn  rounded-0 px-4 shadow-none ${isList ? "bg-blue-3 text-white" : ""}`,
      onClick: () => onAction()
    },
    /* @__PURE__ */ React.createElement("i", null, /* @__PURE__ */ React.createElement(FontAwesomeIcon, { icon: faList })),
    /* @__PURE__ */ React.createElement("span", { className: "ms-2 opacity-75" }, t("txt_list"))
  ), /* @__PURE__ */ React.createElement(
    "button",
    {
      type: "button",
      className: `btn  rounded-0 px-4 shadow-none ${isList ? "" : "bg-blue-3 text-white"}`,
      onClick: () => onAction()
    },
    /* @__PURE__ */ React.createElement("i", null, /* @__PURE__ */ React.createElement(FontAwesomeIcon, { icon: faTh })),
    /* @__PURE__ */ React.createElement("span", { className: "ms-2 opacity-75" }, t("txt_thumb"))
  ))));
};

export {
  TableBar
};
