import {
  envHelper_default
} from "./chunk-XCUGIXHQ.js";

// src/lib/utils.ts
var getSecurePrefix = () => {
  const KEY_PREFIX = envHelper_default.getStoragePrefix() || "@secure.";
  if (!KEY_PREFIX.endsWith("."))
    return KEY_PREFIX + ".";
  return KEY_PREFIX;
};
var FINGERPRINT_KEYS = {
  USERAGENT: "UserAgent",
  SCREEN_PRINT: "ScreenPrint",
  PLUGINS: "Plugins",
  FONTS: "Fonts",
  LOCAL_STORAGE: "LocalStorage",
  SESSION_STORAGE: "SessionStorage",
  TIMEZONE: "TimeZone",
  LANGUAGE: "Language",
  SYSTEM_LANGUAGE: "SystemLanguage",
  COOKIE: "Cookie",
  CANVAS: "Canvas",
  HOSTNAME: "Hostname"
};
var getDisabledKeys = () => {
  const DISABLED_KEYS = envHelper_default.getDisabledKeys() || "";
  if (DISABLED_KEYS === "")
    return [];
  const allOptions = [
    FINGERPRINT_KEYS.USERAGENT,
    FINGERPRINT_KEYS.SCREEN_PRINT,
    FINGERPRINT_KEYS.PLUGINS,
    FINGERPRINT_KEYS.FONTS,
    FINGERPRINT_KEYS.LOCAL_STORAGE,
    FINGERPRINT_KEYS.SESSION_STORAGE,
    FINGERPRINT_KEYS.TIMEZONE,
    FINGERPRINT_KEYS.LANGUAGE,
    FINGERPRINT_KEYS.SYSTEM_LANGUAGE,
    FINGERPRINT_KEYS.COOKIE,
    FINGERPRINT_KEYS.CANVAS,
    FINGERPRINT_KEYS.HOSTNAME
  ];
  const response = [];
  DISABLED_KEYS.split("|").forEach((key) => {
    if (allOptions.includes(key))
      response.push(key);
  });
  return response;
};

export {
  getSecurePrefix,
  FINGERPRINT_KEYS,
  getDisabledKeys
};
