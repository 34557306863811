import {
  ComponentSVG
} from "./chunk-APR4SZIP.js";

// src/components/EditHeader/index.tsx
import React from "react";
var EditHeader = ({ props, title, isEdit, redirectUrl }) => {
  const { t, history } = props;
  return /* @__PURE__ */ React.createElement("div", { className: "position-relative" }, /* @__PURE__ */ React.createElement("h2", { className: "text-body fw-bold mb-8px d-flex align-items-center" }, /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "p-sm ps-0 cursor-pointer d-flex",
      onClick: () => {
        history.push(redirectUrl);
      }
    },
    /* @__PURE__ */ React.createElement(ComponentSVG, { url: "/assets/images/back.svg", className: "bg-success" })
  ), isEdit ? t("txt_edit") : t("txt_add_new"), " ", title));
};

export {
  EditHeader
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
