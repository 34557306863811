import {
  Helper
} from "./chunk-Q7HFA75J.js";
import {
  FORMAT_DATE,
  FORMAT_TIME
} from "./chunk-7PUECHBX.js";

// src/Utils/Date.ts
import { addMilliseconds, format, fromUnixTime } from "date-fns";
import { getTimezoneOffset } from "date-fns-tz";
import moment from "moment";
var timezone = Helper.getTimezoneDefault();
var formatDate = (date, withTime = false, convert = true) => {
  date = new Date(date);
  if (convert) {
    const offset = getTimezoneOffset(timezone);
    date = addMilliseconds(date, offset);
  }
  const f = `${FORMAT_DATE} ${withTime ? ` ${FORMAT_TIME}` : ""}`;
  return format(date, f);
};
var formatUnix = (timestamp, withTime = false) => {
  return formatDate(fromUnixTime(timestamp), withTime, false);
};
var enumerateDaysBetweenDates = function(startDate, endDate) {
  let dates = [];
  const currDate = moment(startDate).startOf("day")?.subtract(1, "days");
  const lastDate = moment(endDate).endOf("day");
  while (currDate.add(1, "days").diff(lastDate) < 0) {
    dates.push(currDate.clone().format("YYYY-MM-DD"));
  }
  return dates;
};
if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function(pattern, replacement) {
    return this.split(pattern).join(replacement);
  };
}
if (!String.prototype.contains) {
  String.prototype.contains = function(part) {
    return this.indexOf(part) >= 0;
  };
}
if (!Array.prototype.first) {
  Array.prototype.first = function(callback) {
    if (!callback)
      return this.length ? this[0] : null;
    for (var i = 0; i < this.length; i++) {
      var item = this[i];
      if (callback(item)) {
        return item;
      }
    }
    return null;
  };
}
var dateFormatConvert;
((_dateFormatConvert) => {
  let replaceAll, DayOfMonthShort, DayOfMonthLong, DayOfWeekShort, DayOfWeekLong, DayOfYearShort, DayOfYearLong, MonthOfYearShort, MonthOfYearLong, MonthNameShort, MonthNameLong, YearShort, YearLong, Hour24Short, Hour24Long, Hour12Short, Hour12Long, MinuteShort, MinuteLong, SecondShort, SecondLong, FractionalSecond1, FractionalSecond2, FractionalSecond3, TimeZone, UnixTimestamp, MakeLiteral, search, replacement, contains, part, first, callbackfn, thisArg, T, dateFormatConvert2, formatRules, AmPm, literal, ReadEscapedPart, startIndex, escapedPart, value, tokenLocateResult, nextBegin, token;
  function convert(format2, sourceRules, destRules) {
    if (sourceRules == destRules)
      return format2;
    var result = "";
    var index = 0;
    var destTokens = getTokens(destRules);
    var sourceMap = getTokenMap(getTokens(sourceRules));
    while (index < format2.length) {
      var part2 = locateNextToken(sourceRules, format2, index);
      if (part2.literal.length > 0)
        result += destRules.MakeLiteral(part2.literal);
      if (part2.token.length > 0)
        result += destTokens[sourceMap[part2.token]];
      index = part2.nextBegin;
    }
    return result;
  }
  _dateFormatConvert.convert = convert;
  _dateFormatConvert.standard = {
    DayOfMonthShort: "d",
    DayOfMonthLong: "dd",
    DayOfWeekShort: "ddd",
    DayOfWeekLong: "dddd",
    DayOfYearShort: "D",
    DayOfYearLong: "DD",
    MonthOfYearShort: "M",
    MonthOfYearLong: "MM",
    MonthNameShort: "MMM",
    MonthNameLong: "MMMM",
    YearShort: "yy",
    YearLong: "yyyy",
    AmPm: "tt",
    Hour24Short: "H",
    Hour24Long: "HH",
    Hour12Short: "h",
    Hour12Long: "hh",
    MinuteShort: "m",
    MinuteLong: "mm",
    SecondShort: "s",
    SecondLong: "ss",
    FractionalSecond1: "f",
    FractionalSecond2: "ff",
    FractionalSecond3: "fff",
    TimeZone: "Z",
    UnixTimestamp: "X",
    MakeLiteral: function(literal2) {
      var reserved = "dDMytHhmsfZX";
      if (indexOfAny(literal2, reserved) < 0)
        return literal2;
      var result = "";
      for (var i = 0; i < literal2.length; i++) {
        var c = literal2.charAt(i);
        if (reserved.contains(c))
          result += "\\";
        result += c;
      }
      return result;
    },
    ReadEscapedPart: function(format2, startIndex2) {
      var result = "";
      var index = startIndex2;
      while (index < format2.length) {
        var c = format2.charAt(index);
        if (c == "\\") {
          result += index == format2.length - 1 ? "\\" : format2[++index];
          index++;
          continue;
        }
        break;
      }
      return {
        value: result,
        length: index - startIndex2
      };
    }
  };
  _dateFormatConvert.dotNet = {
    DayOfMonthShort: "d",
    DayOfMonthLong: "dd",
    DayOfWeekShort: "ddd",
    DayOfWeekLong: "dddd",
    DayOfYearShort: "",
    DayOfYearLong: "",
    MonthOfYearShort: "M",
    MonthOfYearLong: "MM",
    MonthNameShort: "MMM",
    MonthNameLong: "MMMM",
    YearShort: "yy",
    YearLong: "yyyy",
    AmPm: "tt",
    Hour24Short: "H",
    Hour24Long: "HH",
    Hour12Short: "h",
    Hour12Long: "hh",
    MinuteShort: "m",
    MinuteLong: "mm",
    SecondShort: "s",
    SecondLong: "ss",
    FractionalSecond1: "f",
    FractionalSecond2: "ff",
    FractionalSecond3: "fff",
    TimeZone: "zzz",
    UnixTimestamp: "",
    MakeLiteral: function(literal2) {
      var reserved = `dfFghHKmMstyz'"`;
      if (indexOfAny(literal2, reserved) < 0)
        return literal2;
      var result = "";
      for (var i = 0; i < literal2.length; i++) {
        var c = literal2.charAt(i);
        if (reserved.contains(c))
          result += "\\";
        result += c;
      }
      return result;
    },
    ReadEscapedPart: function(format2, startIndex2) {
      var result = "";
      var index = startIndex2;
      while (index < format2.length) {
        var c = format2.charAt(index);
        if (c == "\\") {
          result += index == format2.length - 1 ? "\\" : format2[++index];
          index++;
          continue;
        }
        if (c == '"') {
          while (++index < format2.length) {
            var cc = format2.charAt(index);
            if (cc == '"')
              break;
            if (cc == "\\") {
              result += index == format2.length - 1 ? "\\" : format2[++index];
            } else {
              result += cc;
            }
          }
          index++;
          continue;
        }
        if (c == "'") {
          while (++index < format2.length) {
            cc = format2.charAt(index);
            if (cc == "'")
              break;
            if (cc == "\\") {
              result += index == format2.length - 1 ? "\\" : format2[++index];
            } else {
              result += cc;
            }
          }
          index++;
          continue;
        }
        break;
      }
      return {
        value: result,
        length: index - startIndex2
      };
    }
  };
  _dateFormatConvert.momentJs = {
    DayOfMonthShort: "D",
    DayOfMonthLong: "dd",
    DayOfWeekShort: "ddd",
    DayOfWeekLong: "dddd",
    DayOfYearShort: "DDD",
    DayOfYearLong: "DDDD",
    MonthOfYearShort: "M",
    MonthOfYearLong: "MM",
    MonthNameShort: "MMM",
    MonthNameLong: "MMMM",
    YearShort: "YY",
    YearLong: "yyyy",
    AmPm: "A",
    Hour24Short: "H",
    Hour24Long: "HH",
    Hour12Short: "h",
    Hour12Long: "hh",
    MinuteShort: "m",
    MinuteLong: "mm",
    SecondShort: "s",
    SecondLong: "ss",
    FractionalSecond1: "S",
    FractionalSecond2: "SS",
    FractionalSecond3: "SSS",
    TimeZone: "Z",
    UnixTimestamp: "X",
    MakeLiteral: function(literal2) {
      var reserved = "MoDdeEwWYgGAaHhmsSzZX";
      literal2 = literal2.replaceAll("[", "(").replaceAll("]", ")");
      if (indexOfAny(literal2, reserved) < 0)
        return literal2;
      return "[" + literal2 + "]";
    },
    ReadEscapedPart: function(format2, startIndex2) {
      if (format2.charAt(startIndex2) != "[")
        return { value: "", length: 0 };
      var result = "";
      var index = startIndex2;
      while (index < format2.length) {
        var c = format2.charAt(index);
        if (c == "]") {
          break;
        }
        result += c;
      }
      return {
        value: result,
        length: index - startIndex2
      };
    }
  };
  _dateFormatConvert.datepicker = {
    DayOfMonthShort: "d",
    DayOfMonthLong: "dd",
    DayOfWeekShort: "D",
    DayOfWeekLong: "DD",
    DayOfYearShort: "o",
    DayOfYearLong: "oo",
    MonthOfYearShort: "m",
    MonthOfYearLong: "mm",
    MonthNameShort: "M",
    MonthNameLong: "MM",
    YearShort: "y",
    YearLong: "yy",
    AmPm: "",
    Hour24Short: "",
    Hour24Long: "",
    Hour12Short: "",
    Hour12Long: "",
    MinuteShort: "",
    MinuteLong: "",
    SecondShort: "",
    SecondLong: "",
    FractionalSecond1: "",
    FractionalSecond2: "",
    FractionalSecond3: "",
    TimeZone: "",
    UnixTimestamp: "@",
    MakeLiteral: function(literal2) {
      var reserved = "dDomMy@'";
      if (indexOfAny(literal2, reserved) < 0)
        return literal2;
      return "'" + literal2.replaceAll("'", "''") + "'";
    },
    ReadEscapedPart: function(format2, startIndex2) {
      if (format2.charAt(startIndex2) != "'")
        return { value: "", length: 0 };
      var result = "";
      var index = startIndex2;
      while (++index < format2.length) {
        var c = format2.charAt(index);
        if (c == "'") {
          index++;
          if (index == format2.length)
            break;
          if (format2[index] == "'") {
            result += c;
          } else {
            break;
          }
        } else {
          result += c;
        }
      }
      return {
        value: result,
        length: index - startIndex2
      };
    }
  };
  _dateFormatConvert.timepicker = {
    DayOfMonthShort: "",
    DayOfMonthLong: "",
    DayOfWeekShort: "",
    DayOfWeekLong: "",
    DayOfYearShort: "",
    DayOfYearLong: "",
    MonthOfYearShort: "",
    MonthOfYearLong: "",
    MonthNameShort: "",
    MonthNameLong: "",
    YearShort: "",
    YearLong: "",
    AmPm: "TT",
    Hour24Short: "H",
    Hour24Long: "HH",
    Hour12Short: "h",
    Hour12Long: "hh",
    MinuteShort: "m",
    MinuteLong: "mm",
    SecondShort: "s",
    SecondLong: "ss",
    FractionalSecond1: "",
    FractionalSecond2: "",
    FractionalSecond3: "l",
    TimeZone: "Z",
    UnixTimestamp: "",
    MakeLiteral: function(literal2) {
      var reserved = "HhmslctTzZ'";
      if (indexOfAny(literal2, reserved) < 0)
        return literal2;
      return "'" + literal2.replaceAll("'", '"') + "'";
    },
    ReadEscapedPart: function(format2, startIndex2) {
      if (format2.charAt(startIndex2) != "'")
        return { value: "", length: 0 };
      var result = "";
      var index = startIndex2;
      while (++index < format2.length) {
        var c = format2.charAt(index);
        if (c == "'") {
          index++;
          if (index == format2.length)
            break;
          if (format2.charAt(index) == "'")
            result += c;
          else
            break;
        } else {
          result += c;
        }
      }
      return {
        value: result,
        length: index - startIndex2
      };
    }
  };
})(dateFormatConvert || (dateFormatConvert = {}));
function locateNextToken(rules, format2, begin) {
  var literal = "";
  var index = begin;
  var sequence = getTokenSequence(getTokenMap(getTokens(rules)));
  while (index < format2.length) {
    var escaped = rules.ReadEscapedPart(format2, index);
    if (escaped.length > 0) {
      literal += escaped.value;
      index += escaped.length;
      continue;
    }
    var token = sequence.first((x) => format2.indexOf(x, index) == index);
    if (!token) {
      literal += format2.charAt(index);
      index++;
      continue;
    }
    return {
      token,
      literal,
      nextBegin: index + token.length
    };
  }
  return {
    token: "",
    literal,
    nextBegin: index
  };
}
function getTokens(rules) {
  return [
    rules.DayOfMonthShort,
    rules.DayOfMonthLong,
    rules.DayOfWeekShort,
    rules.DayOfWeekLong,
    rules.DayOfYearShort,
    rules.DayOfYearLong,
    rules.MonthOfYearShort,
    rules.MonthOfYearLong,
    rules.MonthNameShort,
    rules.MonthNameLong,
    rules.YearShort,
    rules.YearLong,
    rules.AmPm,
    rules.Hour24Short,
    rules.Hour24Long,
    rules.Hour12Short,
    rules.Hour12Long,
    rules.MinuteShort,
    rules.MinuteLong,
    rules.SecondShort,
    rules.SecondLong,
    rules.FractionalSecond1,
    rules.FractionalSecond2,
    rules.FractionalSecond3,
    rules.TimeZone,
    rules.UnixTimestamp
  ].map((x) => x || "");
}
function getTokenMap(tokens) {
  var map = {};
  for (var i = 0; i < tokens.length; i++) {
    var token = tokens[i];
    if (token) {
      map[token] = i;
    }
  }
  return map;
}
function getTokenSequence(map) {
  var tokens = Object.keys(map);
  tokens.sort((a, b) => b.length - a.length);
  return tokens;
}
function indexOfAny(s, chars) {
  for (var i = 0; i < s.length; i++) {
    var c = s.charAt(i);
    for (var j = 0; j < chars.length; j++) {
      if (c === chars.charAt(j))
        return i;
    }
  }
  return -1;
}

export {
  formatDate,
  formatUnix,
  enumerateDaysBetweenDates,
  dateFormatConvert
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
