// src/pages/DigitalAssets/index.tsx
import React from "react";
import { AesirXDam } from "aesirx-dam-app";
function DigitalAssetsPage() {
  return /* @__PURE__ */ React.createElement("div", { className: "py-4 px-3 h-100" }, /* @__PURE__ */ React.createElement(AesirXDam, null));
}

export {
  DigitalAssetsPage
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
