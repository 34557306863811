import {
  ModalComponentExtended
} from "./chunk-RG5IZ5BE.js";

// src/components/ModalDam/index.tsx
import React from "react";
import { AesirXDam } from "aesirx-dam-app";
function ModalDAMComponent({
  show,
  onHide,
  onSelect,
  type = "",
  accept = "*",
  isMulti = false
}) {
  return /* @__PURE__ */ React.createElement(
    ModalComponentExtended,
    {
      dialogClassName: "modal-xl modal_digital_assets",
      show,
      onHide,
      centered: true,
      autoFocus: false,
      body: /* @__PURE__ */ React.createElement(
        AesirXDam,
        {
          onSelect,
          toolbar: false,
          type,
          accept,
          isMulti
        }
      )
    }
  );
}

export {
  ModalDAMComponent
};
