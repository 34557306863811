import {
  CRM_COMPANY_DETAIL_FIELD_KEY
} from "./chunk-Q42BW4RB.js";
import {
  BaseItemModel_default
} from "./chunk-UPAELC5I.js";
import {
  BaseModel_default
} from "./chunk-ITJMNR7M.js";

// src/Crm/CrmCompany/CrmCompanyModel.ts
var CompanyModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    if (entities) {
      this.items = entities._embedded.item.map((element) => {
        return new CompanyItemModel(element);
      });
    }
  }
};
var CompanyItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.id = null;
    this.crm_company_name = null;
    this.crm_company_address = null;
    // crm_company_logo = [];
    this.crm_company_annual_revenue = null;
    this.crm_company_number_employees = null;
    this.crm_company_contact_email = null;
    this.crm_company_tax_number = null;
    this.crm_company_website = null;
    this.crm_company_fax_number = null;
    this.crm_company_phone_number = null;
    this.crm_company_status = null;
    this.crm_company_contacts = null;
    this.created_by = null;
    this.created_time = null;
    this.status = null;
    this.modified_by = null;
    this.modified_time = null;
    this.featured = null;
    this.toObject = () => {
      return {};
    };
    this.toJSON = () => {
      return {
        ...this.baseToJSON(),
        [CRM_COMPANY_DETAIL_FIELD_KEY.ID]: this.id,
        [CRM_COMPANY_DETAIL_FIELD_KEY.NAME]: this.crm_company_name,
        [CRM_COMPANY_DETAIL_FIELD_KEY.ADDRESS]: this.crm_company_address,
        // [CRM_COMPANY_DETAIL_FIELD_KEY.LOGO]: this.crm_company_logo,
        [CRM_COMPANY_DETAIL_FIELD_KEY.ANNUAL_REVENUE]: this.crm_company_annual_revenue,
        [CRM_COMPANY_DETAIL_FIELD_KEY.NUMBER_EMPLOYEES]: this.crm_company_number_employees,
        [CRM_COMPANY_DETAIL_FIELD_KEY.CONTACT_EMAIL]: this.crm_company_contact_email,
        [CRM_COMPANY_DETAIL_FIELD_KEY.TAX_NUMBER]: this.crm_company_tax_number,
        [CRM_COMPANY_DETAIL_FIELD_KEY.WEBSITE]: this.crm_company_website,
        [CRM_COMPANY_DETAIL_FIELD_KEY.FAX_NUMBER]: this.crm_company_fax_number,
        [CRM_COMPANY_DETAIL_FIELD_KEY.PHONE_NUMBER]: this.crm_company_phone_number,
        [CRM_COMPANY_DETAIL_FIELD_KEY.COMPANY_STATUS]: this.crm_company_status,
        [CRM_COMPANY_DETAIL_FIELD_KEY.CONTACTS]: this.crm_company_contacts,
        [CRM_COMPANY_DETAIL_FIELD_KEY.CREATED_TIME]: this.created_time,
        [CRM_COMPANY_DETAIL_FIELD_KEY.CREATED_BY]: this.created_by,
        [CRM_COMPANY_DETAIL_FIELD_KEY.STATUS]: this.status,
        [CRM_COMPANY_DETAIL_FIELD_KEY.MODIFIED_BY]: this.modified_by,
        [CRM_COMPANY_DETAIL_FIELD_KEY.MODIFIED_TIME]: this.modified_time,
        [CRM_COMPANY_DETAIL_FIELD_KEY.FEATURED]: this.featured
      };
    };
    if (entity) {
      this.id = entity[CRM_COMPANY_DETAIL_FIELD_KEY.ID] ?? "";
      this.crm_company_name = entity[CRM_COMPANY_DETAIL_FIELD_KEY.NAME] ?? "";
      this.crm_company_address = entity[CRM_COMPANY_DETAIL_FIELD_KEY.ADDRESS] ?? "";
      this.crm_company_annual_revenue = entity[CRM_COMPANY_DETAIL_FIELD_KEY.ANNUAL_REVENUE] ?? "";
      this.crm_company_number_employees = entity[CRM_COMPANY_DETAIL_FIELD_KEY.NUMBER_EMPLOYEES] ?? "";
      this.crm_company_contact_email = entity[CRM_COMPANY_DETAIL_FIELD_KEY.CONTACT_EMAIL] ?? "";
      this.crm_company_tax_number = entity[CRM_COMPANY_DETAIL_FIELD_KEY.TAX_NUMBER] ?? "";
      this.crm_company_website = entity[CRM_COMPANY_DETAIL_FIELD_KEY.WEBSITE] ?? "";
      this.crm_company_fax_number = entity[CRM_COMPANY_DETAIL_FIELD_KEY.FAX_NUMBER] ?? "";
      this.crm_company_phone_number = entity[CRM_COMPANY_DETAIL_FIELD_KEY.PHONE_NUMBER] ?? "";
      this.crm_company_status = entity[CRM_COMPANY_DETAIL_FIELD_KEY.COMPANY_STATUS] ?? "";
      this.crm_company_contacts = entity[CRM_COMPANY_DETAIL_FIELD_KEY.CONTACTS] ?? "";
      this.created_time = entity[CRM_COMPANY_DETAIL_FIELD_KEY.CREATED_TIME] ?? "";
      this.created_by = entity[CRM_COMPANY_DETAIL_FIELD_KEY.CREATED_BY] ?? "";
      this.status = entity[CRM_COMPANY_DETAIL_FIELD_KEY.STATUS] ?? "";
      this.modified_by = entity[CRM_COMPANY_DETAIL_FIELD_KEY.MODIFIED_BY] ?? "";
      this.modified_time = entity[CRM_COMPANY_DETAIL_FIELD_KEY.MODIFIED_TIME] ?? "";
      this.featured = entity[CRM_COMPANY_DETAIL_FIELD_KEY.FEATURED] ?? "";
    }
  }
};
CompanyItemModel.__transformItemToApiOfCreation = (data) => {
  let formData = new FormData();
  const excluded = [
    CRM_COMPANY_DETAIL_FIELD_KEY.ID,
    CRM_COMPANY_DETAIL_FIELD_KEY.COMPANY_STATUS,
    CRM_COMPANY_DETAIL_FIELD_KEY.CONTACTS
  ];
  Object.keys(CRM_COMPANY_DETAIL_FIELD_KEY).forEach((index) => {
    if (!excluded.includes(CRM_COMPANY_DETAIL_FIELD_KEY[index]) && data[CRM_COMPANY_DETAIL_FIELD_KEY[index]]) {
      formData.append(
        CRM_COMPANY_DETAIL_FIELD_KEY[index],
        data[CRM_COMPANY_DETAIL_FIELD_KEY[index]]
      );
    }
  });
  if (data[CRM_COMPANY_DETAIL_FIELD_KEY.CONTACTS] && data[CRM_COMPANY_DETAIL_FIELD_KEY.CONTACTS].length) {
    data[CRM_COMPANY_DETAIL_FIELD_KEY.CONTACTS].map((item) => {
      return formData.append(CRM_COMPANY_DETAIL_FIELD_KEY.CONTACTS + "[]", item.id);
    });
  }
  if (data[CRM_COMPANY_DETAIL_FIELD_KEY.COMPANY_STATUS]) {
    formData.append(
      CRM_COMPANY_DETAIL_FIELD_KEY.COMPANY_STATUS,
      data[CRM_COMPANY_DETAIL_FIELD_KEY.COMPANY_STATUS]?.id
    );
  }
  return formData;
};
CompanyItemModel.__transformItemToApiOfUpdation = (data) => {
  let formData = {};
  const excluded = [
    CRM_COMPANY_DETAIL_FIELD_KEY.CONTACTS,
    CRM_COMPANY_DETAIL_FIELD_KEY.COMPANY_STATUS
  ];
  Object.keys(CRM_COMPANY_DETAIL_FIELD_KEY).forEach((index) => {
    if (!excluded.includes(CRM_COMPANY_DETAIL_FIELD_KEY[index]) && data[CRM_COMPANY_DETAIL_FIELD_KEY[index]]) {
      formData[CRM_COMPANY_DETAIL_FIELD_KEY[index]] = data[CRM_COMPANY_DETAIL_FIELD_KEY[index]];
    }
  });
  if (data[CRM_COMPANY_DETAIL_FIELD_KEY.CONTACTS]?.length) {
    formData[CRM_COMPANY_DETAIL_FIELD_KEY.CONTACTS] = data[CRM_COMPANY_DETAIL_FIELD_KEY.CONTACTS].map((item) => {
      return item.id;
    });
  } else {
    formData[CRM_COMPANY_DETAIL_FIELD_KEY.CONTACTS + "[]"] = "";
  }
  if (data[CRM_COMPANY_DETAIL_FIELD_KEY.COMPANY_STATUS]) {
    formData[CRM_COMPANY_DETAIL_FIELD_KEY.COMPANY_STATUS] = data[CRM_COMPANY_DETAIL_FIELD_KEY.COMPANY_STATUS]?.id;
  }
  return formData;
};

export {
  CompanyModel,
  CompanyItemModel
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
