// src/lib/envHelper.ts
var getHashKey = () => {
  let value = null;
  try {
    if (typeof process.env != "undefined") {
      value = process.env.SECURE_LOCAL_STORAGE_HASH_KEY || process.env.REACT_APP_SECURE_LOCAL_STORAGE_HASH_KEY || process.env.NEXT_PUBLIC_SECURE_LOCAL_STORAGE_HASH_KEY || process.env.VITE_SECURE_LOCAL_STORAGE_HASH_KEY;
    }
  } catch (ex) {
    return null;
  }
  return value;
};
var getStoragePrefix = () => {
  let value = null;
  try {
    if (typeof process.env != "undefined") {
      value = process.env.SECURE_LOCAL_STORAGE_PREFIX || process.env.REACT_APP_SECURE_LOCAL_STORAGE_PREFIX || process.env.NEXT_PUBLIC_SECURE_LOCAL_STORAGE_PREFIX || process.env.VITE_SECURE_LOCAL_STORAGE_PREFIX;
    }
  } catch (ex) {
    return null;
  }
  return value;
};
var getDisabledKeys = () => {
  let value = null;
  try {
    if (typeof process.env != "undefined") {
      value = process.env.SECURE_LOCAL_STORAGE_DISABLED_KEYS || process.env.REACT_APP_SECURE_LOCAL_STORAGE_DISABLED_KEYS || process.env.NEXT_PUBLIC_SECURE_LOCAL_STORAGE_DISABLED_KEYS || process.env.VITE_SECURE_LOCAL_STORAGE_DISABLED_KEYS;
    }
  } catch (ex) {
    return null;
  }
  return value;
};
var envHelper = {
  getHashKey,
  getStoragePrefix,
  getDisabledKeys
};
var envHelper_default = envHelper;

export {
  envHelper_default
};
