import {
  MemberDetailViewModel_default
} from "./chunk-Z5BP3JLW.js";
import {
  MemberListViewModel_default
} from "./chunk-BDQPST4Q.js";

// src/pages/Members/MemberViewModel/MemberViewModel.ts
var MemberViewModel = class {
  constructor(memberStore) {
    this.memberDetailViewModel = {};
    this.memberListViewModel = {};
    this.getMemberDetailViewModel = () => this.memberDetailViewModel;
    this.getMemberListViewModel = () => this.memberListViewModel;
    if (memberStore) {
      this.memberDetailViewModel = new MemberDetailViewModel_default(memberStore);
      this.memberListViewModel = new MemberListViewModel_default(memberStore);
    }
  }
};
var MemberViewModel_default = MemberViewModel;

export {
  MemberViewModel_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
