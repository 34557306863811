import {
  Image
} from "./chunk-2MXWHUGP.js";

// src/components/Button/index.tsx
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// esbuild-scss-modules-plugin:./index.module.scss
var digest = "1854819db8979f2462f337d8cc64a9f2f16a1ee41e41b34d72580cbcc25cdef4";
var classes = {};
var css = `/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */`;
(function() {
  if (typeof document !== "undefined" && !document.getElementById(digest)) {
    var ele = document.createElement("style");
    ele.id = digest;
    ele.textContent = css;
    document.head.appendChild(ele);
  }
})();
var index_module_default = classes;

// src/components/Button/index.tsx
var Button = ({
  icon,
  text,
  onClick,
  image,
  disabled,
  className,
  loading,
  type = "button"
}) => {
  if (className !== void 0 && index_module_default[className] !== void 0) {
    className = index_module_default[className];
  }
  return /* @__PURE__ */ React.createElement(
    "button",
    {
      type,
      className: `d-flex justify-content-center btn ${className ?? "btn-success"}`,
      onClick,
      disabled: disabled || loading
    },
    icon && /* @__PURE__ */ React.createElement("i", { className: "pe-1" }, /* @__PURE__ */ React.createElement(FontAwesomeIcon, { icon })),
    image && /* @__PURE__ */ React.createElement(Image, { alt: text, src: image, className: "pe-1" }),
    /* @__PURE__ */ React.createElement("span", { className: "text_btn text-nowrap" }, text),
    loading && /* @__PURE__ */ React.createElement("div", { className: "position-relative" }, /* @__PURE__ */ React.createElement(
      "span",
      {
        className: `ms-1 spinner-border text-body`,
        style: { width: "20px", height: "20px" },
        role: "status"
      },
      /* @__PURE__ */ React.createElement("span", { className: "visually-hidden" }, "Loading...")
    ))
  );
};

export {
  Button
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
