import {
  PersonaItemModel
} from "./chunk-DRVGOFIC.js";
import {
  ESI_INVESTER_CONTACT_API_RESPONSE_FIELD_KEY,
  ESI_INVESTER_CONTACT_FIELD_KEY
} from "./chunk-EAGIFGDQ.js";
import {
  BaseItemModel_default
} from "./chunk-UPAELC5I.js";
import {
  BaseModel_default
} from "./chunk-ITJMNR7M.js";

// src/InvesterContact/InvesterContactModel.ts
var InvesterContactModel = class extends BaseModel_default {
  constructor(entities) {
    super(entities);
    if (entities) {
      this.unTransformedItems = entities._embedded.item;
      this.items = entities._embedded.item.map((element) => {
        return new PersonaItemModel(element);
      });
      this.items.pagination = this.getPagination();
    }
  }
};
var InvesterContactItemModel = class extends BaseItemModel_default {
  constructor(entity) {
    super(entity);
    this.organizationName = "";
    this.contactPerson = "";
    this.email = "";
    this.phoneNumber = "";
    if (entity) {
      this.organizationName = entity[ESI_INVESTER_CONTACT_API_RESPONSE_FIELD_KEY.ORGANIZATION_NAME] ?? "";
      this.contactPerson = entity[ESI_INVESTER_CONTACT_API_RESPONSE_FIELD_KEY.CONTACT_PERSON] ?? "";
      this.email = entity[ESI_INVESTER_CONTACT_API_RESPONSE_FIELD_KEY.EMAIL] ?? "";
      this.phoneNumber = entity[ESI_INVESTER_CONTACT_API_RESPONSE_FIELD_KEY.PHONE_NUMBER] ?? "";
    }
  }
};
InvesterContactItemModel.__transformItemToApiOfCreation = (data) => {
  return {
    [ESI_INVESTER_CONTACT_API_RESPONSE_FIELD_KEY.ORGANIZATION_NAME]: data[ESI_INVESTER_CONTACT_FIELD_KEY.ORGANIZATION_NAME] ?? "",
    [ESI_INVESTER_CONTACT_API_RESPONSE_FIELD_KEY.CONTACT_PERSON]: data[ESI_INVESTER_CONTACT_FIELD_KEY.CONTACT_PERSON] ?? "",
    [ESI_INVESTER_CONTACT_API_RESPONSE_FIELD_KEY.EMAIL]: data[ESI_INVESTER_CONTACT_FIELD_KEY.EMAIL] ?? "",
    [ESI_INVESTER_CONTACT_API_RESPONSE_FIELD_KEY.PHONE_NUMBER]: data[ESI_INVESTER_CONTACT_FIELD_KEY.PHONE_NUMBER] ?? ""
  };
};

export {
  InvesterContactModel,
  InvesterContactItemModel
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
