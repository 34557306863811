// src/providers/ThemeContextProvider.tsx
import React, { createContext, useState, useContext, useEffect } from "react";
var listThemes = [
  { name: "light", color: "#0A083B", className: "" },
  { name: "dark", color: "#fff", className: "" }
];
var ThemesContext = createContext({
  theme: "light",
  toggleTheme: () => {
  }
});
var themeDefault = () => {
  if (localStorage.getItem("theme")) {
    return localStorage.getItem("theme");
  } else {
    window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
  }
};
var ThemesContextProvider = ({ children }) => {
  const [theme, setTheme] = useState(themeDefault());
  const toggleTheme = () => {
    const newThemeValue = theme === "dark" ? "light" : "dark";
    setTheme(newThemeValue);
    localStorage.setItem("theme", newThemeValue);
    document.documentElement.setAttribute("data-bs-theme", newThemeValue);
  };
  useEffect(() => {
    localStorage.setItem("theme", theme);
    document.documentElement.setAttribute("data-bs-theme", theme);
  }, [theme]);
  return /* @__PURE__ */ React.createElement(ThemesContext.Provider, { value: { theme, toggleTheme } }, children);
};
var useThemeContext = () => useContext(ThemesContext);
var withThemeContext = (Component) => (props) => {
  return /* @__PURE__ */ React.createElement(Component, { ...props, ...useThemeContext() });
};

export {
  listThemes,
  ThemesContext,
  ThemesContextProvider,
  useThemeContext,
  withThemeContext
};
