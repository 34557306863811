// src/components/Spinner/pulseLoader.tsx
import React from "react";
import PulseLoader from "react-spinners/PulseLoader";
var PulseLoaderComponent = class extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: `${this.props.className} position-absolute top-50 start-50 translate-middle zindex-1 w-100 h-100`
      },
      /* @__PURE__ */ React.createElement(PulseLoader, { color: `#1ab394`, size: `${this.props.size ?? "15px"}` })
    );
  }
};

export {
  PulseLoaderComponent
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
